import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../redux/slices/uiSlice'

import { Col } from '../../generic/View'
import { ModalMenuItem } from '../../generic/Elements'

const memoryRoomSettingsContributor = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }

  return (
    <Col style={{ ...props.style }}>
      {props.modalProps.onboardingAction && (
        <ModalMenuItem
          title={t('general.onboarding.watch_onboarding')}
          onClick={props.modalProps.onboardingAction}
        />
      )}
      <ModalMenuItem
        title={t('memory_room.leave_the_room')}
        onClick={() => onOpenModal('memoryRoomLeave')}
      />
    </Col>
  )
}

export default memoryRoomSettingsContributor
