import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectHeader } from '../../../redux/slices/uiSlice'

import { setToast } from '../../../utils/status'
import ajax from '../../../utils/ajax'
import { handleBackButton } from '../../../utils/generic'

import PreparedHelmet from '../../components/seo/PreparedHelmet'
import PageWithLinks from '../../components/learningRoom/PageWithLinks'

const { REACT_APP_STRAPI_API_URL } = process.env

function Home() {
  const [data, setData] = useState([])
  const [pageInfo, setPageInfo] = useState({})
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const prepareAndSetData = data => {
    let preparedData = data.filter(el => !!el?.topic?.data)
    preparedData = preparedData.map(el => ({ ...el.topic.data }))
    setData(preparedData)
  }

  const fetchPageInfo = async () => {
    try {
      const response = await ajax({
        url: `${REACT_APP_STRAPI_API_URL}/page-config?populate=deep,4`,
        method: 'GET',
        isPublicRequest: true
      })

      if (response?.data?.attributes) {
        setPageInfo(response?.data?.attributes?.general)

        const topics = response?.data?.attributes?.topics
        if (topics) {
          prepareAndSetData(topics)
        }
      } else {
        setToast(t('general.errors.general'), 'error')
      }
    } catch (err) {
      setToast(err, 'error')
    }
  }

  useEffect(() => {
    fetchPageInfo()
  }, [])

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () => navigate(`/`))
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('learning_room.heading'),
    withBackground: true,
    backCustomActionTriggered: false
  }

  const heroData = {
    imgAlt: t('learning_room.heading'),
    imgSrc: pageInfo?.image?.data?.attributes?.url,
    heading: pageInfo?.title,
    description: pageInfo?.description
  }

  const metaTitle = `${t('learning_room.heading')} – ${t(
    'landing.heading_subheading'
  )}`
  const metaDescription = t('learning_room.description')
  return (
    <>
      <PreparedHelmet
        title={metaTitle}
        description={metaDescription}
        titleHasAppName={true}
      />

      <PageWithLinks
        header={header}
        hero={heroData}
        linkItems={data}
        baseLink="learning"
        linkButtonText={t('learning_room.home_page.item_button')}
      />
    </>
  )
}

export default Home
