import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Box, ResponsiveContext } from 'grommet'

import photoBackground from '../../../assets/img/photo_background.svg'
import photoStackAdd from '../../../assets/img/photo_stack_add.svg'
import strings1mobile from '../../../assets/backgrounds/room_strings/mobile/1.svg'
import strings2mobile from '../../../assets/backgrounds/room_strings/mobile/2.svg'
import strings3mobile from '../../../assets/backgrounds/room_strings/mobile/3.svg'
import stringsEven from '../../../assets/backgrounds/room_strings/desktop/even.svg'
import stringsOdd2 from '../../../assets/backgrounds/room_strings/desktop/odd2.svg'
import { ParagraphSmall } from '../generic/Text'
import { ShowMore } from '../generic/ShowMore'
import { Link } from 'react-router-dom'

const memoryCardWrapStyles = css`
  text-align: left;
  text-decoration: none;
  color: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 47px;
  &:before {
    content: '';
    position: absolute;
    top: -50px;
    left: 110px;
    height: 90px;
    width: 85px;
    background: ${`no-repeat url(${strings1mobile})`};
    z-index: -1;
  }

  &:nth-child(3) {
    &:before {
      display: none;
    }
  }
  @media (max-width: ${props =>
      props.theme.global.breakpoints.medium.value}px) {
    &:nth-child(2n) {
      &:before {
        background: ${`no-repeat url(${strings2mobile})`};
      }
    }
    &:nth-child(3n) {
      &:before {
        background: ${`no-repeat url(${strings3mobile})`};
      }
    }
  }

  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    width: 480px;
    margin-bottom: 110px;
    &:nth-child(odd) {
      margin-left: 200px;
      &:before {
        content: '';
        position: absolute;
        top: -215px;
        left: -80px;
        height: 450px;
        width: 480px;
        background: ${`no-repeat url(${stringsOdd2})`};
        z-index: -1;
      }
    }
    &:nth-child(even) {
      margin-right: 200px;
    }
    &:before {
      content: '';
      position: absolute;
      top: -255px;
      left: 120px;
      height: 450px;
      width: 480px;
      background: ${`no-repeat url(${stringsEven})`};
      z-index: -1;
    }
  }
`

const MemoryCardWrapLink = styled(Link)`
  ${memoryCardWrapStyles}
`

const MemoryCardWrapDiv = styled.div`
  ${memoryCardWrapStyles}
`

export const MemoryCardWrap = props => {
  if (props.to) {
    return <MemoryCardWrapLink {...props} />
  }
  return <MemoryCardWrapDiv {...props} />
}

const MemoryImgWrap = styled.div`
  background: ${props =>
    props.add
      ? `no-repeat url(${photoStackAdd})`
      : `no-repeat url(${photoBackground});`};
  width: 320px;
  height: 240px;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -107px;
  margin-left: -10px;
  z-index: 0;
  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    width: 100%;
    height: 320px;
    margin-bottom: -100px;
    margin-left: -20px;
  }
`

const TextOnImg = props => (
  <div
    style={{
      width: props.width ? props.width : '240px',
      position: 'absolute',
      padding: '40px 20px'
    }}>
    <ParagraphSmall>
      <ShowMore str={props.text} nomore />
    </ParagraphSmall>
  </div>
)

export const MemoryImg = props => {
  const size = useContext(ResponsiveContext)

  const parentWidth = size === 'large' ? '342px' : '255px'
  const width = props.width || parentWidth
  const height = size === 'large' ? '220px' : '160px'

  return (
    <MemoryImgWrap add={props.add}>
      <div
        style={{
          background: props.add ? undefined : '#e4eff8',
          width: parentWidth,
          height: height,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <div
          style={{
            width,
            height,
            backgroundSize: props.width ? 'contain' : 'cover',
            backgroundImage: `url(${props.src})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            position: 'relative'
          }}></div>
        {props.children}
      </div>
      {props.text && <TextOnImg text={props.text} />}
    </MemoryImgWrap>
  )
}

export const MemoryCardBox = styled.div`
  background: ${props => props.theme.global.colors.greyBacking};
  padding: 90px 27px 20px 27px;
  border: 5px solid #f6f6f6;
  border-radius: 16px;
  width: 300px;
  min-height: 200px;
  > * {
    margin: 4px;
  }
  ${({ noSidePad }) =>
    noSidePad &&
    `padding: 90px 0px 20px 0px;
  `}
  @media (min-width: ${props =>
    props.theme.global.breakpoints.medium.value + 1}px) {
    width: 390px;
  }
`
export const StyledCircle = styled.div`
  width: 29px;
  height: 29px;
  color: white;
  font-weight: bold;
  border: 2px solid white;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    bottom: 10px;
    right: 10px;
  }
`
export const RoomListItemWrap = styled(Box)`
  font-size: '1.125rem',
  font-weight: 500;
  line-height: 1.5rem;
  width: 320px;
  justify-content: space-around;
`
