import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'

const DIVIDER_SIZE = {
  SMALL: 'small',
  LARGE: 'large'
}

const DividerBlock = styled.div`
  height: ${props => props.height};
  width: 100%;
`

const Divider = ({ component }) => {
  if (!component?.size) return null
  const screenSize = useContext(ResponsiveContext)

  let height
  if (component.size === DIVIDER_SIZE.SMALL) {
    height = screenSize === 'large' ? '16px' : '12px'
  } else {
    height = screenSize === 'large' ? '20px' : '16px'
  }

  return <DividerBlock height={height} />
}

export default Divider
