import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box } from 'grommet'

import { setToast } from '../../../utils/status'
import * as Button from '../generic/Button'
import * as Input from '../generic/Input'
import { Heading2, Paragraph } from '../generic/Text'
import { login } from '../../../redux/slices/userSlice'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function LoginForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(login(formData))

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        const msg = result.payload?.detail?.general || result.payload.title
        setToast(t(msg), 'error')
        setSubmitting(false)
      }
    }
  }

  return (
    <>
      <Heading2>{t('login.heading')}</Heading2>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
          const errors = {}
          if (!values.email.trim()) {
            errors.email = t('general.required_field')
          }
          if (!values.password) {
            errors.password = t('general.required_field')
          }
          return errors
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm narrow="true">
            <Input.StyledField
              type="email"
              name="email"
              testid="input-email"
              placeholder="Email"
              label="Email"
            />
            <Input.StyledErrorMessage name="email" component="div" />
            <Input.StyledField
              type="password"
              name="password"
              testid="input-password"
              placeholder={t('login.placeholder.password')}
              label={t('login.placeholder.password')}
            />
            <Input.StyledErrorMessage name="password" component="div" />
            <Box alignSelf="end">
              <Button.Link to="/forgot-password" data-testid="forgot-password">
                {t('login.forgot_password')}
              </Button.Link>
            </Box>
            <Button.Primary
              type="submit"
              data-testid="input-submit"
              label={t('login.button')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>

      {!isAppSunsettingMode && (
        <>
          <Paragraph>{t('login.not_registered')}</Paragraph>
          <Button.Link to="/create-account" data-testid="not-registered">
            {t('login.register')}
          </Button.Link>
        </>
      )}
    </>
  )
}

export default LoginForm
