import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ResponsiveContext } from 'grommet'

import { openModal } from '../../../../redux/slices/uiSlice'

import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Col, Row } from '../../generic/View'

const memoryRoomChooseAdmin = props => {
  const { memoryRoomUser, user, memoryRoom } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const size = useContext(ResponsiveContext)

  const openUsersModal = () => {
    dispatch(
      openModal({
        type: 'memoryRoomUsers',
        props: { user, memoryRoom, memoryRoomUser, adminSelection: true },
        fullWidth: true
      })
    )
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t('memory_room.cant_leave_choose_another_admin')}
      </Paragraph>

      <Row justify="center">
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-choose"
          label={t('general.choose')}
          onClick={() => openUsersModal()}
          adjustToSize={size}
        />
      </Row>
    </Col>
  )
}

export default memoryRoomChooseAdmin
