import React, { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import viaTheme from '../../../styles/via-theme.json'

import { ParagraphSmall } from './Text'

const StyledTooltip = styled(ReactTooltip)`
  background-color: white !important;
  border: 4px solid ${props => props.theme.global.colors.turqoise} !important;
  border-radius: 10px !important;
  font-size: 1rem !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  line-height: 1.125rem !important;
  max-width: 327px !important;
  opacity: 1 !important;
  text-align: center;
  &:before {
    display: none;
  }
`

export const Tooltip = ({
  children,
  id,
  text,
  showOnRender,
  className,
  style
}) => {
  id = id || 'tooltip'
  let tooltipRef = useRef(null)

  if (showOnRender) {
    setTimeout(() => {
      StyledTooltip.show(tooltipRef)
      setTimeout(() => {
        StyledTooltip.hide(tooltipRef)
      }, 3000)
    }, 100)
  }

  return (
    <>
      <span
        ref={ref => (tooltipRef = ref)}
        data-tip
        data-for={id}
        className={className}
        onMouseEnter={() => StyledTooltip.show(tooltipRef)}
        onMouseLeave={() => StyledTooltip.hide(tooltipRef)}
        onClick={e => e.preventDefault()}
        style={{ cursor: 'default', lineHeight: 0, margin: 0, ...style }}>
        {children}
      </span>

      <StyledTooltip
        arrowColor="transparent"
        effect="solid"
        id={id}
        place="bottom"
        scrollHide={true}
        textColor="black">
        <ParagraphSmall>{text}</ParagraphSmall>
      </StyledTooltip>
    </>
  )
}
