import { Box } from 'grommet'
import React from 'react'
import styled, { keyframes } from 'styled-components'

const AudioWave = props => {
  return (
    <StyledWrapper {...props}>
      <Wave1 />
      <Wave2 />
      <Wave3 />
      <Wave4 />
      <Wave5 />
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)`
  width: 40px;
  height: 30px;
  display: flex;
  flex-direction: row;
  div {
    margin-right: 2px;
    position: relative;
    display: inline-block;
    width: 6px;
    border-radius: 3px;
    background: ${props => props.theme.global.colors.lightGrey};
  }
`

// Create the keyframes
const wave1 = keyframes`
  0% {
    height: 20%;
    top: 40%;
  }
  50% {
    height: 30%;
    top: 30%;
  }
  100% {
    height: 20%;
    top: 40%;
  }
`

const wave2 = keyframes`
  0% {
   height: 20%;
    top: 40%;
  }
  50% {
    height: 64%;
    top: 13%;
  }
  100% {
    height: 20%;
    top: 40%;
  }
`

const wave3 = keyframes`
  0% {
    height: 20%;
    top: 40%;
  }
  50% {
    height: 100%;
    top: 0;
  }
  100% {
    height: 20%;
    top: 40%;
  }
`

const Wave1 = styled.div`
  animation: ${wave1} 1s linear infinite;
`

const Wave2 = styled.div`
  animation: ${wave2} 1s linear infinite;
`

const Wave3 = styled.div`
  animation: ${wave3} 1s linear infinite;
`

const Wave4 = styled.div`
  animation: ${wave2} 1s linear infinite;
`

const Wave5 = styled.div`
  animation: ${wave1} 1s linear infinite;
`

export default AudioWave
