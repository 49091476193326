import React, { useState, useEffect, useRef } from 'react'
import { Box } from 'grommet'

import { setToast } from '../../../../utils/status'
const { isFileValid } = require('../../../../utils/generic')

import { CONTENT_TYPES } from '../../../../constants/contentTypes'
const {
  SUPPORTED_IMAGE_ACCEPT_PROP
} = require('../../../../constants/supportedMediaTypes')

import * as Input from '../../generic/Input'
import * as Button from '../../generic/Button'
import { useTranslation } from 'react-i18next'
import { Circles } from '../Elements'
import iconAngleLeft from '../../../../assets/icons/icon_angle_left.svg'

const FileModal = ({ submitting, form, setCurrentStep, pageSteps }) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = useState(t('general.image_file_limit'))

  const modalTop = useRef(null)
  const scrollToTop = () => {
    modalTop.current.scrollIntoView({ behavior: 'instant' })
  }
  useEffect(scrollToTop, [])

  return (
    <Box>
      <div
        style={{
          width: '20px',
          height: '29px',
          position: 'relative',
          top: '-16px',
          textAlign: 'left',
          background: `no-repeat url(${iconAngleLeft})`,
          cursor: 'pointer'
        }}
        tabIndex="0"
        onKeyDown={e => {
          if (e.code === 'Space' || e.code === 'Enter') {
            e.preventDefault()
            setCurrentStep(pageSteps.TEXT)
          }
        }}
        role="button"
        onClick={() => setCurrentStep(pageSteps.TEXT)}
        ref={modalTop}
      />
      <Input.StyledForm>
        <Input.StyledFileUpload
          name="file"
          accept={SUPPORTED_IMAGE_ACCEPT_PROP}
          label={t('feedback_modal.file_upload')}
          filename={fileName}
          labelType={3}
          onChange={event => {
            const curFile = event.currentTarget.files[0]
            if (curFile) {
              if (isFileValid(curFile, CONTENT_TYPES.IMAGE)) {
                setFileName(curFile?.name)
                form.setFieldValue('file', event.currentTarget.files)
              } else {
                setToast(
                  t('media.image_upload_error') + ' ' + t('media.image_info'),
                  'error'
                )
              }
            }
          }}
        />
        <Circles thirdPage />
        <Button.Primary
          type="submit"
          flex={false}
          data-testid="input-submit"
          label={t('general.send')}
          disabled={!form.values.message || submitting}
          spinnerOnDisabled={submitting}
        />
      </Input.StyledForm>
    </Box>
  )
}

export default FileModal
