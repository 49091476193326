import React, { useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import Markdown from '../../generic/Markdown'
import * as Button from '../../generic/Button'
import * as Input from '../../generic/Input'

const MULTISELECT_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox'
}

const Multichoice = ({
  component,
  showActionButtons,
  setShowActionButtons
}) => {
  const { t } = useTranslation()
  let [isAnsweredCorrectly, setIsAnsweredCorrectly] =
    useState(showActionButtons)

  const possibleAnswers = component?.answers || []
  const question = component?.question
  const correctAnswerText = component?.correctAnswerText

  const correctAnswers = []
  const processedPossibleAnswers = possibleAnswers.map(el => {
    const id = el.id.toString()
    if (el.isCorrectAnswer) correctAnswers.push(id)
    return {
      value: id,
      label: el.text
    }
  })

  let multichoiceType
  if (correctAnswers.length > 1) {
    multichoiceType = MULTISELECT_TYPE.CHECKBOX
  } else {
    multichoiceType = MULTISELECT_TYPE.RADIO
  }

  const handleSubmit = () => {
    setIsAnsweredCorrectly(true)
    setShowActionButtons(true)
  }

  let initValues
  if (isAnsweredCorrectly) {
    initValues =
      multichoiceType === MULTISELECT_TYPE.RADIO
        ? correctAnswers[0]
        : correctAnswers
  }

  return (
    <>
      {question && <Markdown>{question}</Markdown>}

      <Formik
        initialValues={{
          chosenAnswer: initValues
        }}
        onSubmit={handleSubmit}
        validate={values => {
          let errors = {}

          if (multichoiceType === MULTISELECT_TYPE.RADIO) {
            if (!values.chosenAnswer) {
              errors.chosenAnswer = t(
                'learning_room.exercise_page.multiselect.errors.chose_answer'
              )
            } else if (!correctAnswers.includes(values.chosenAnswer)) {
              errors.chosenAnswer = t(
                'learning_room.exercise_page.multiselect.errors.wrong_answer'
              )
            }
          } else if (multichoiceType === MULTISELECT_TYPE.CHECKBOX) {
            // no answeres are chosen
            if (!values.chosenAnswer || values.chosenAnswer.length === 0) {
              errors.chosenAnswer = t(
                'learning_room.exercise_page.multiselect.errors.chose_answers'
              )
            } else {
              let userAnsweredCorrect = 0,
                userAnsweredWrong = 0
              for (const answer of values.chosenAnswer) {
                if (correctAnswers.includes(answer)) {
                  userAnsweredCorrect++
                } else {
                  userAnsweredWrong++
                }
              }

              // user chose only wrong answers
              if (userAnsweredCorrect === 0 && userAnsweredWrong > 0) {
                errors.chosenAnswer = t(
                  'learning_room.exercise_page.multiselect.errors.wrong_answer'
                )
              }

              // user chose correct answers, but not all of them
              if (
                userAnsweredCorrect > 0 &&
                userAnsweredCorrect < correctAnswers.length &&
                userAnsweredWrong === 0
              ) {
                errors.chosenAnswer = t(
                  'learning_room.exercise_page.multiselect.errors.some_correct_chosen'
                )
              }

              // user chose all correct answers, but also some wrong
              if (
                userAnsweredCorrect === correctAnswers.length &&
                userAnsweredWrong > 0
              ) {
                errors.chosenAnswer = t(
                  'learning_room.exercise_page.multiselect.errors.all_correct_some_wrong_chosen'
                )
              }

              // user chose some correct answers and but also some wrong.
              if (
                userAnsweredCorrect > 0 &&
                userAnsweredCorrect < correctAnswers.length &&
                userAnsweredWrong > 0
              ) {
                errors.chosenAnswer = t(
                  'learning_room.exercise_page.multiselect.errors.some_correct_some_wrong_chosen'
                )
              }
            }
          }

          return errors
        }}>
        {({ values, resetForm, setFieldValue }) => (
          <Input.StyledForm>
            {multichoiceType === MULTISELECT_TYPE.CHECKBOX && (
              <Input.StyledCheckBoxGroup
                disabled={isAnsweredCorrectly}
                options={processedPossibleAnswers}
                name="test"
                onChange={event => {
                  resetForm({
                    errors: {}
                  })
                  setFieldValue('chosenAnswer', event.value)
                }}
                value={values.chosenAnswer}
              />
            )}

            {multichoiceType === MULTISELECT_TYPE.RADIO && (
              <Input.StyledRadioButtonGroup
                direction="column"
                disabled={isAnsweredCorrectly}
                name="chosenAnswer"
                value={values.chosenAnswer}
                options={processedPossibleAnswers}
                onChange={event => {
                  resetForm({
                    errors: {}
                  })
                  setFieldValue('chosenAnswer', event.target.value)
                }}
              />
            )}

            <Input.StyledErrorMessage name="chosenAnswer" component="div" />

            {!isAnsweredCorrectly && (
              <Button.Primary
                type="submit"
                flex={false}
                label={t(
                  'learning_room.exercise_page.multiselect.submit_answer'
                )}
              />
            )}

            {isAnsweredCorrectly && <Markdown>{correctAnswerText}</Markdown>}
          </Input.StyledForm>
        )}
      </Formik>
    </>
  )
}

export default Multichoice
