import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

import { CONTENT_TYPES } from '../../../constants/contentTypes'

function MemoryLightBox(props) {
  const imageContent = props.content.filter(
    content => content.type === CONTENT_TYPES.IMAGE
  )
  // extract only images types from content array
  // match currentImageUrl to set index

  let images = []
  for (let content of imageContent) {
    if (
      content.assets &&
      content.assets.length > 0 &&
      content.assets[0].fileUrl
    ) {
      const fileUrl = content.assets[0].fileUrl
      if (
        !props.displayOnlyCurrentImage ||
        (props.displayOnlyCurrentImage && fileUrl === props.currentImageUrl)
      ) {
        images.push(content.assets[0].fileUrl)
      }
    }
  }
  const initialImageIndex = images.indexOf(props.currentImageUrl)
  if (initialImageIndex === -1) {
    console.error(
      'invalid image index',
      initialImageIndex,
      props.currentImageUrl
    )
    props.onClose()
  }

  const [currentImageIndex, setCurrentIndex] = useState(initialImageIndex)

  const gotoPrevious = () => {
    let prevImageIndex = currentImageIndex - 1
    if (prevImageIndex < 0) {
      prevImageIndex = images.length - 1 // allowing looping
    }
    setCurrentIndex(prevImageIndex)
  }

  const gotoNext = () => {
    setCurrentIndex((currentImageIndex + 1) % images.length)
  }

  const nextSrc =
    images?.length > 1
      ? images[(currentImageIndex + 1) % images.length]
      : undefined
  const prevSrc =
    images?.length > 1
      ? images[(currentImageIndex + images.length - 1) % images.length]
      : undefined

  return (
    <Lightbox
      mainSrc={images[currentImageIndex]}
      nextSrc={nextSrc}
      prevSrc={prevSrc}
      onCloseRequest={props.onClose}
      onMovePrevRequest={gotoPrevious}
      onMoveNextRequest={gotoNext}
      enableZoom={false}
    />
  )
}

export default MemoryLightBox
