import React from 'react'

import icon_cross_white_14 from '../../../assets/icons/icon_cross_white_14.svg'
import icon_arrow_left_15 from '../../../assets/icons/icon_arrow_left_15.svg'
import icon_angle_right from '../../../assets/icons/icon_angle_right.svg'
import icon_3_dots_horizontal from '../../../assets/icons/icon_3_dots_horizontal.svg'
import icon_3_dots_vertical from '../../../assets/icons/icon_3_dots_vertical.svg'
import icon_3_dots_in_circle from '../../../assets/icons/icon_3_dots_circle.svg'
import { ReactComponent as IconLock } from '../../../assets/icons/icon_lock.svg'
import { ReactComponent as IconGroup } from '../../../assets/icons/icon_group.svg'
import { ReactComponent as IconMessage } from '../../../assets/icons/icon_message.svg'
import { ReactComponent as IconArrowsUpDown } from '../../../assets/icons/arrows_up_down.svg'
import { ReactComponent as IconMagnifyingGlass } from '../../../assets/icons/magnifying_glass.svg'
import { ReactComponent as IconPlusInSquare } from '../../../assets/icons/plus_in_square.svg'
import { ReactComponent as IconUserPlus } from '../../../assets/icons/user_plus.svg'

export const CrossWhite = () => (
  <img
    alt="icon_cross_white"
    src={icon_cross_white_14}
    width="14"
    height="14"
  />
)

export const ArrowLeft = ({ className }) => (
  <img
    alt="icon_arrow_left"
    src={icon_arrow_left_15}
    width="15"
    height="15"
    className={className}
  />
)

export const AngleRight = () => (
  <img
    alt="icon_angle_right"
    src={icon_angle_right}
    style={{ width: '0.5625rem', height: '1.0625rem' }}
  />
)

export const ThreeDotsHorizontal = props => (
  <img
    alt="icon_3_dots"
    className={props.className}
    src={icon_3_dots_horizontal}
    style={{ width: '22.52px', height: '5.44px', ...props.style }}
    onClick={props.onClick}
  />
)

export const ThreeDotsVertical = props => (
  <img
    alt="icon_3_dots"
    src={icon_3_dots_vertical}
    style={{ height: '22.52px', width: '5.44px', ...props.style }}
    onClick={props.onClick}
  />
)

export const ThreeDotsInCircle = props => (
  <img
    alt="icon_3_dots_in_circle"
    className={props.className}
    onClick={props.onClick}
    src={icon_3_dots_in_circle}
    style={{ ...props.style }}
  />
)

export const Lock = props => {
  const height = props?.style?.height || '28px'
  return <IconLock alt="lock icon" style={{ height, ...props?.style }} />
}

export const Group = props => {
  const height = props?.style?.height || '28px'
  return <IconGroup alt="group icon" style={{ height, ...props?.style }} />
}

export const Message = () => <IconMessage alt="message icon" />

export const ArrowsUpDown = () => (
  <IconArrowsUpDown alt="arrows up and down icon" />
)

export const MagnifyingGlass = () => (
  <IconMagnifyingGlass alt="magnifying glass icon" />
)

export const PlusInSquare = () => <IconPlusInSquare alt="plus in square icon" />

export const UserPlus = () => <IconUserPlus alt="user plus icon" />
