import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectHeader } from '../../../redux/slices/uiSlice'
const { handleBackButton } = require('../../../utils/generic')

import NewMemoryForm from '../../components/forms/NewMemoryForm'
import { Section, Screen, SectionContent } from '../generic/View'
import IllustrationBlock from '../IllustrationBlock'
import manWithADog from '../../../assets/illustrations/photo_of_a_man_with_a_dog2.svg'

function NewMemoryPage(props) {
  const { pathname, search } = useLocation()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname + search, props.backButtonAction)
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: props.pageTitleKey,
    multiColor: true,
    backCustomActionTriggered: true
  }

  return (
    <Screen header={header}>
      <IllustrationBlock background img={manWithADog} />
      <Section>
        <SectionContent>
          <NewMemoryForm {...props} />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default NewMemoryPage
