import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Row, Col } from '../generic/View'
import * as Icon from '../generic/Icon'
import { TextSmall } from '../generic/Text'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function MemoryRoomMenu({
  isMemoryRoomOwner,
  memoryRoom,
  pageSteps,
  setCurrentStepWithScroll
}) {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  let style = {
    background: '#475662E5',
    position: 'fixed',
    zIndex: 2
  }

  if (isDesktop) {
    let width, left
    if (isAppSunsettingMode) {
      width = '300px'
      left = 'calc( 50% - 150px )'
    } else {
      width = isMemoryRoomOwner ? '500px' : '355px'
      left = isMemoryRoomOwner ? 'calc( 50% - 250px )' : 'calc( 50% - 177.5px )'
    }
    style = {
      ...style,
      borderRadius: '20px',
      bottom: '30px',
      left,
      height: '66px',
      width
    }
  } else {
    style = {
      ...style,
      bottom: '0px',
      left: '0',
      height: '60px',
      width: '100%'
    }
  }

  const textFontSize = isDesktop ? undefined : '0.625rem'
  const textLineHeight = isDesktop ? undefined : '12px'
  const buttonWidth = isDesktop ? '93px' : '77px'
  const buttonMargin = isDesktop ? 30 : 10

  return (
    <div style={style} className="onboarding__floating-room-menu">
      <Row align="center" justify="center" height="100%">
        {!isAppSunsettingMode && (
          <>
            {isMemoryRoomOwner && (
              <Link
                to={`/rooms/${memoryRoom.id}/invite-member`}
                style={{ textDecoration: 'none' }}>
                <Col
                  align="center"
                  width={buttonWidth}
                  style={{ marginRight: `${buttonMargin / 2}px` }}>
                  <Icon.UserPlus />
                  <TextSmall
                    align="center"
                    fontSize={textFontSize}
                    lineHeight={textLineHeight}
                    white
                    style={{ margin: '0' }}>
                    {t('memory_room.menu.new_member')}
                  </TextSmall>
                </Col>
              </Link>
            )}
            <Link
              to={`/rooms/${memoryRoom.id}/new-memory`}
              style={{ textDecoration: 'none' }}>
              <Col
                align="center"
                width={buttonWidth}
                style={{ margin: `0 ${buttonMargin / 2}px` }}>
                <Icon.PlusInSquare />
                <TextSmall
                  align="center"
                  fontSize={textFontSize}
                  lineHeight={textLineHeight}
                  white
                  style={{ margin: '0' }}>
                  {t('memory_room.menu.new_memory')}
                </TextSmall>
              </Col>
            </Link>
          </>
        )}
        <Col
          align="center"
          width={buttonWidth}
          style={{ margin: `0 ${buttonMargin / 2}px` }}
          onClick={() => setCurrentStepWithScroll(pageSteps.FILTERING)}>
          <Icon.MagnifyingGlass />
          <TextSmall
            align="center"
            fontSize={textFontSize}
            lineHeight={textLineHeight}
            white
            style={{ margin: '0' }}>
            {t('memory_room.menu.search')}
          </TextSmall>
        </Col>
        <Col
          align="center"
          width={buttonWidth}
          style={{ marginLeft: `${buttonMargin / 2}px` }}
          onClick={() => setCurrentStepWithScroll(pageSteps.SORTING)}>
          <Icon.ArrowsUpDown />
          <TextSmall
            align="center"
            fontSize={textFontSize}
            lineHeight={textLineHeight}
            white
            style={{ margin: '0' }}>
            {t('general.sort.heading')}
          </TextSmall>
        </Col>
      </Row>
    </div>
  )
}

export default MemoryRoomMenu
