const FEEDBACK_TYPES = {
  FEEDBACK_FEATURE: 'feedback_feature',
  GENERAL_FEEDBACK: 'general_feedback',
  TECHNICAL_SUPPORT: 'technical_support'
}

const FEEDBACK_TYPES_VALUES = []
for (const k in FEEDBACK_TYPES) {
  FEEDBACK_TYPES_VALUES.push(FEEDBACK_TYPES[k])
}

module.exports = {
  FEEDBACK_TYPES,
  FEEDBACK_TYPES_VALUES
}
