const ROLES = {
  OWNER: 'owner',
  CONTRIBUTOR: 'contributor',
  VIEWER: 'viewer' // viewer role is only for test purposes atm
}

const ROLE_VALUES = []
for (const k in ROLES) {
  ROLE_VALUES.push(ROLES[k])
}

module.exports = {
  ROLES: ROLES,
  ROLE_VALUES: ROLE_VALUES
}
