import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Formik } from 'formik'

import {
  selectTemporaryData,
  openModal
} from '../../../../redux/slices/uiSlice'

import { CONTENT_TYPES } from '../../../../constants/contentTypes'
const { PRIVACY_SETTINGS } = require('../../../../constants/privacySettings')

import * as Input from '../../generic/Input'
import * as Button from '../../generic/Button'

function ContentTextForm(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [description, setDescription] = useState(false)

  const tempData = useSelector(selectTemporaryData)
  const privacySetting = tempData?.privacy || PRIVACY_SETTINGS.PUBLIC

  // form submit function
  const handleSubmit = formObject => {
    // open privacy setting modal when choose individually was selected previously
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      setDescription(formObject.description)
      dispatch(
        openModal({
          type: 'memoryPrivacySetting',
          withoutCloseButton: true
        })
      )
    } else {
      // submit if privacy setting is set
      submitData(formObject.description)
    }
  }

  // submitData should be triggered after choose individually privacy modal is shown
  useEffect(() => {
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      if (tempData.key && tempData.contentPrivacy && description) {
        submitData(description)
        setDescription(undefined)
      }
    }
  }, [tempData?.key])

  // adding text to content array and changing form step back to content overview
  const submitData = text => {
    if (text) {
      text = text.trim()
      if (text.length > 0) {
        const type = CONTENT_TYPES.TEXT
        const contentPrivacy =
          privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY
            ? tempData.contentPrivacy
            : privacySetting

        let contentIsPrivate = contentPrivacy === PRIVACY_SETTINGS.PRIVATE

        props.setContentCountFunc({
          ...props.contentCountObject,
          [type]: props.contentCountObject[type] + 1
        })

        props.setArrayFunc(arr => {
          return [
            ...arr,
            {
              type: CONTENT_TYPES.TEXT,
              description: text,
              private: contentIsPrivate
            }
          ]
        })
      }
    }

    props.setContentStep()
  }

  return (
    <Formik
      initialValues={{ description: '' }}
      validate={values => {
        const errors = {},
          descriptionLimit = 2000

        if (!values.description.trim()) {
          errors.description = t('general.required_field')
        } else if (values.description.trim().length > descriptionLimit) {
          errors.description = t('general.errors.text_too_long', {
            limit: descriptionLimit,
            length: values.description.trim().length
          })
        }

        return errors
      }}
      onSubmit={handleSubmit}>
      {form => (
        <Input.StyledForm>
          <Input.StyledTextArea
            type="text"
            name="description"
            testid="input-description"
            label={t('memory.content.text.label.description')}
            labelType={3}
            style={{ height: '350px' }}
          />
          <Input.StyledErrorMessage name="description" component="div" />
          <Button.Primary
            type="submit"
            flex={false}
            data-testid="input-submit"
            label={t('general.add')}
            disabled={!form.values.description || form.errors.description}
          />
        </Input.StyledForm>
      )}
    </Formik>
  )
}

export default ContentTextForm
