import React from 'react'
import { useTranslation } from 'react-i18next'

import { CONTENT_TYPES } from '../../constants/contentTypes'
import { prepareDate } from '../../utils/generic'

import { Row } from './generic/View'
import * as Icon from './generic/Icon'
import {
  MemoryCardBox,
  MemoryImg,
  MemoryCardWrap,
  StyledCircle
} from './memoryRoom/memoryRoomElements'
import { Heading1, Paragraph, ParagraphSmall } from '../components/generic/Text'
import { ShowMore } from './generic/ShowMore'
import { Tooltip } from './generic/Tooltip'

import videoImg from '../../assets/img/video_img.svg'
import soundImg from '../../assets/img/sound_img.svg'
import textImg from '../../assets/img/text_img.svg'

const MemoryListItem = ({ memory, memoryRoomId, isFirstItem }) => {
  const { t } = useTranslation()

  let image, imgSize, text, type, content
  if (memory?.memoryContent?.length > 0) {
    content = memory.memoryContent[0]
    text = content.creatorIsDeactivated ? '' : content.description
    type = content.type
  }
  switch (type) {
    case CONTENT_TYPES.IMAGE:
      image = content.creatorIsDeactivated ? textImg : content.assets[0].fileUrl
      break
    case CONTENT_TYPES.VIDEO:
      image = videoImg
      imgSize = '63px'
      break
    case CONTENT_TYPES.AUDIO:
      image = soundImg
      imgSize = '63px'
      break
    default:
      image = textImg
      break
  }

  const memoryDate = prepareDate(
    memory.memoryDate,
    !!memory.memoryMonth,
    !!memory.memoryDay
  )

  const privacy = {
    text: memory.private
      ? t('memory.privacy.memory_is_private')
      : t('memory.privacy.memory_is_public'),
    icon: memory.private ? (
      <Icon.Lock style={{ color: '#02CBC5' }} />
    ) : (
      <Icon.Group style={{ color: '#475662' }} />
    )
  }

  // used for onboarding-tour
  let memoryBlockClass, memoryContentCountClass, memoryPrivacySettingClass
  if (isFirstItem) {
    memoryBlockClass = 'onboarding__first-memory'
    memoryContentCountClass = 'onboarding__first-memory-content-count'
    memoryPrivacySettingClass = 'onboarding__first-memory-privacy-setting'
  }

  let memoryName, memoryDescription
  if (memory.creatorIsDeactivated) {
    memoryName = t('memory.deleted_memory_name_placeholder')
    memoryDescription = t('memory.deleted_memory_description_placeholder')
  } else {
    memoryName = memory.name
    memoryDescription = memory.description
  }

  return (
    <MemoryCardWrap
      className={memoryBlockClass}
      to={`/rooms/${memoryRoomId}/memory/${memory.id}`}>
      <MemoryImg src={image} width={imgSize} text={text}>
        {memory.memoryContent && memory.memoryContent.length > 0 && (
          <StyledCircle className={memoryContentCountClass}>
            {memory.memoryContent.length}
          </StyledCircle>
        )}
      </MemoryImg>
      <MemoryCardBox>
        <Heading1>{memoryName}</Heading1>
        <Paragraph darkBlueGrey>{memoryDate}</Paragraph>
        <ParagraphSmall>
          {' '}
          <ShowMore str={memoryDescription} />{' '}
        </ParagraphSmall>
        <Row justify="end" align="center">
          <Tooltip
            id={`memory-info-${memory.id}`}
            text={privacy.text}
            className={memoryPrivacySettingClass}>
            {privacy.icon}
          </Tooltip>
        </Row>
      </MemoryCardBox>
    </MemoryCardWrap>
  )
}

export default MemoryListItem
