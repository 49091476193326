import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectUser } from '../../redux/slices/userSlice'

import LoginDesktop from '../components/LoginDesktop'
import LoginForm from '../components/forms/LoginForm'
import { Screen, Section, SectionContent } from '../components/generic/View'
import PreparedHelmet from '../components/seo/PreparedHelmet'

const Login = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const { t } = useTranslation()
  const user = useSelector(selectUser)

  if (user) {
    const query = useQuery()
    const invite = query.get('invite')
    // Guided Tour should be recovered when video tour will be implemented
    // consider new invite prop for redirect when onboarding should be shown
    // const onboarding = query.get('showOnboarding')

    // if (onboarding !== null) {
    //   return <Redirect to="/profile/guided-tour" />
    // } else {
    //   return <Redirect to="/home" />
    // }

    if (invite) {
      return <Navigate to={`/accept-invite?value=${invite}`} replace />
    }
    return <Navigate to="/home" replace />
  }

  return (
    <>
      <PreparedHelmet
        title={t('login.heading')}
        description={t('memory_room.landing_text')}
        titleHasAppName={true}
      />

      <Screen>
        <LoginDesktop />
        <Section>
          <SectionContent>
            <LoginForm />
          </SectionContent>
        </Section>
      </Screen>
    </>
  )
}

export default Login
