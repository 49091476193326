import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Box, ResponsiveContext } from 'grommet'

import { closeModal } from '../../../redux/slices/uiSlice'

import { Paragraph } from '../generic/Text'
import * as Button from '../generic/Button'

import { Col } from '../generic/View'

const LINK_ONLINE_CONSULTATION = process.env.REACT_APP_LINK_ONLINE_CONSULTATION

const openOnlineCounseling = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const handleSubmit = () => {
    onCloseModal()
    window.open(LINK_ONLINE_CONSULTATION, '_blank')
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t('grief_counselling.proceed_to_counseling')}
      </Paragraph>

      <Box
        direction={isDesktop ? 'row' : 'column-reverse'}
        justify={isDesktop ? 'between' : 'evenly'}>
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-no"
          label={t('general.no')}
          onClick={() => onCloseModal()}
          adjustToSize={size}
          style={!isDesktop ? { width: '100%', maxWidth: '300px' } : {}}
        />

        <Button.White
          type="button"
          data-testid="button-yes"
          label={t('general.yes')}
          onClick={() => handleSubmit()}
          adjustToSize={size}
          style={
            !isDesktop
              ? {
                  width: '100%',
                  maxWidth: '300px',
                  margin: '0 0 20px 0'
                }
              : {}
          }
        />
      </Box>
    </Col>
  )
}

export default openOnlineCounseling
