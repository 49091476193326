import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'

import { update } from '../../../../redux/slices/memoryRoomSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import * as Input from '../../generic/Input'

import { Col } from '../../generic/View'

const memoryRoomRename = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)

  const { memoryRoom, memoryRoomUser } = props.modalProps

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(
      update({
        memoryRoomUserId: memoryRoomUser.id,
        formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      setToast(t('memory_room.name_successfully_changed'), 'success')
    }
  }

  const initValue = memoryRoom.creatorIsDeactivated
    ? ''
    : memoryRoom.belovedName

  return (
    <Col style={{ ...props.style }}>
      <Paragraph>{t('memory_room.rename_room')}</Paragraph>

      <Formik
        initialValues={{ belovedName: initValue }}
        validate={values => {
          const errors = {},
            belovedNameLimit = 50

          if (!values.belovedName.trim()) {
            errors.belovedName = t('general.required_field')
          } else if (values.belovedName.trim().length > belovedNameLimit) {
            errors.belovedName = t('general.errors.text_too_long', {
              limit: belovedNameLimit,
              length: values.belovedName.trim().length
            })
          }
          return errors
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm>
            <Input.StyledField
              type="text"
              name="belovedName"
              testid="input-name"
            />
            <Input.StyledErrorMessage name="belovedName" component="div" />
            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.save')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
    </Col>
  )
}

export default memoryRoomRename
