import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import { useSelector, useDispatch } from 'react-redux'
import { persistStore } from 'redux-persist'
import { useTranslation } from 'react-i18next'
import { Grommet as UnstyledGrommet } from 'grommet'
import styled from 'styled-components'
import { toast } from 'react-toastify'

import Routes from './routes'
import store from './redux/store'
import { selectUser } from './redux/slices/userSlice'
import { setTemporaryData } from './redux/slices/uiSlice'
import { setToast } from './utils/status'

import viaTheme from './styles/via-theme.json'

import PreparedHelmet from './views/components/seo/PreparedHelmet'
import Header from './views/components/generic/Header'
import Footer from './views/components/generic/Footer'
import { AppContainer } from './views/components/generic/View'
import Modal from './views/components/generic/Modal'
import OfflineInfo from './views/screens/OfflineInfo'
import { Feedback } from './views/components/feedback/Elements'

let persistor = persistStore(store)

// add basic style to grommet component
const Grommet = styled(UnstyledGrommet)`
  display: flex;
  flex-direction: column;
  height: 100%;
  scroll-behavior: auto;
`

function App() {
  const { t } = useTranslation()
  const [isOnline, setIsOnline] = useState(false)
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const setOnlineStatus = () => {
    const status = navigator.onLine
    setIsOnline(status)
    const toastId = t('general.internet_connection_restored')

    if (!status && toast.isActive(toastId)) {
      toast.dismiss(toastId)
    }

    if (status) {
      setToast(t('general.internet_connection_restored'), 'success', toastId)
    }
  }

  // clear redux temp store on tab close
  const handleTabClose = () => {
    dispatch(setTemporaryData(undefined))
  }

  useEffect(() => {
    // set online/offline status
    setIsOnline(navigator.onLine)
    window.addEventListener('online', setOnlineStatus)
    window.addEventListener('offline', setOnlineStatus)

    // set app locale
    // DS 18/10/2022
    // English is disabled until copy is checked
    // let locale
    // if (user?.language) {
    //   locale = user.language
    // } else {
    //   locale = getBrowserLocale()
    // }
    // i18n.changeLanguage(locale)

    // call handleTabClose on tab close
    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('online', setOnlineStatus)
      window.removeEventListener('offline', setOnlineStatus)
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [])

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Grommet
        theme={viaTheme}
        background="lightGrey"
        style={{ height: 'initial', minHeight: '100vh' }}>
        <BrowserRouter>
          <AppContainer>
            <PreparedHelmet title={t('landing.heading_subheading')} />
            {isOnline && (
              <>
                <Feedback />
                <Header />
                <Routes />
                <Footer />
              </>
            )}
            {!isOnline && <OfflineInfo />}
          </AppContainer>
          <ToastContainer
            style={{ width: '100%', maxWidth: viaTheme.global.size.xlarge }}
          />
          <Modal />
        </BrowserRouter>
      </Grommet>
    </PersistGate>
  )
}

export default App
