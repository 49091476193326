import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Box, ResponsiveContext } from 'grommet'

import { update as updateMemory } from '../../../../redux/slices/memorySlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
const { PRIVACY_SETTINGS } = require('../../../../constants/privacySettings')

import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Col } from '../../generic/View'

const memoryChangePrivacySetting = props => {
  const { memoryId, memoryRoomUserId, memory } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const [submitting, setSubmitting] = useState(false)

  const privacySetting =
    memory &&
    (memory.private ? PRIVACY_SETTINGS.PRIVATE : PRIVACY_SETTINGS.PUBLIC)

  let questionTranslationKey, actionTranslationKey, newPrivacySetting
  if (privacySetting === PRIVACY_SETTINGS.PUBLIC) {
    questionTranslationKey = 'memory.make_content_private_instruction'
    actionTranslationKey = 'general.set_to_private'
    newPrivacySetting = PRIVACY_SETTINGS.PRIVATE
  } else {
    questionTranslationKey = 'memory.make_content_public_instruction'
    actionTranslationKey = 'general.set_to_public'
    newPrivacySetting = PRIVACY_SETTINGS.PUBLIC
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  const changeMemoryPrivacy = async () => {
    setSubmitting(true)
    const result = await dispatch(
      updateMemory({
        memoryRoomUserId,
        memoryId,
        data: { private: newPrivacySetting === PRIVACY_SETTINGS.PRIVATE }
      })
    )
    // Display error if rejected
    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        const errorMsg =
          result.payload.title ||
          result.payload?.errors?.general ||
          t('general.something_wrong')
        setToast(errorMsg, 'error')
        setSubmitting(false)
      }
    }

    // display success message and fetch updated memory
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      setToast(t('general.privacy_was_changed'), 'success')
    }
  }

  const isDesktop = size === 'large'

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t(questionTranslationKey)}
      </Paragraph>

      <Box
        direction={isDesktop ? 'row' : 'column-reverse'}
        justify={isDesktop ? 'between' : 'evenly'}>
        <Button.White
          type="button"
          margin="0"
          data-testid="button-cancel"
          label={t('general.cancel')}
          onClick={() => onCloseModal()}
          disabled={submitting}
          spinnerOnDisabled={true}
          adjustToSize={size}
          style={!isDesktop ? { width: '100%', maxWidth: '300px' } : {}}
        />

        <Button.Primary
          type="button"
          data-testid="button-set-privacy"
          label={t(actionTranslationKey)}
          disabled={submitting}
          onClick={changeMemoryPrivacy}
          adjustToSize={size}
          style={!isDesktop ? { width: '100%', maxWidth: '300px' } : {}}
        />
      </Box>
    </Col>
  )
}

export default memoryChangePrivacySetting
