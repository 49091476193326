import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectHeader } from '../../../../redux/slices/uiSlice'
const { handleBackButton } = require('../../../../utils/generic')

import { Screen, Section, SectionContent } from '../../generic/View'
import IllustrationBlock from '../../IllustrationBlock'
import ContentTextForm from './ContentTextForm'

import manWithACat from '../../../../assets/illustrations/man_with_a_cat.svg'

function ContentTextPage(props) {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname + search, props.setContentStep)
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('memory.create_new_memory_header'),
    multiColor: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <IllustrationBlock img={manWithACat} background />
      <Section>
        <SectionContent>
          <ContentTextForm {...props} />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default ContentTextPage
