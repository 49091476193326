import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { closeModal } from '../../../redux/slices/uiSlice'

import { Col } from '../generic/View'
import Markdown from '../generic/Markdown'
import * as Button from '../generic/Button'

const termsAndConditions = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [MDContent, setMDContent] = useState('')

  // Fetch MD file
  useEffect(() => {
    import('../../../markdown/terms_conditions.md')
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setMDContent(res))
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  })

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Markdown>{MDContent}</Markdown>
      <Button.Primary
        type="button"
        label={t('general.close')}
        onClick={() => onCloseModal()}
      />
    </Col>
  )
}

export default termsAndConditions
