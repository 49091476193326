export const CONTENT_TYPES = {
  AUDIO: 'audio',
  CAMERA: 'camera',
  GALLERY: 'gallery',
  INSPIRE_ME: 'inspire_me',
  IMAGE: 'image',
  TEXT: 'text',
  VIDEO: 'video',
  DOCUMENT: 'document'
}

export const CONTENT_TYPES_VALUES_MEDIA = [
  CONTENT_TYPES.AUDIO,
  CONTENT_TYPES.CAMERA,
  CONTENT_TYPES.GALLERY,
  CONTENT_TYPES.DOCUMENT
]

export const FORM_INIT_CONTENT_TYPE = {
  [CONTENT_TYPES.GALLERY]: 0,
  [CONTENT_TYPES.TEXT]: 0,
  [CONTENT_TYPES.CAMERA]: 0,
  [CONTENT_TYPES.INSPIRE_ME]: 0,
  [CONTENT_TYPES.AUDIO]: 0,
  [CONTENT_TYPES.DOCUMENT]: 0
}
