import React from 'react'
import { useTranslation } from 'react-i18next'

import { Row } from '../../../components/generic/View'
import { ParagraphLarge } from '../../../components/generic/Text'

import { ReactComponent as IconClock } from '../../../../assets/icons/icon_clock.svg'
import { ReactComponent as IconPeople } from '../../../../assets/icons/icon_people.svg'
import { ReactComponent as IconTools } from '../../../../assets/icons/icon_tools.svg'

const RecipeIcons = ({ component }) => {
  const { t } = useTranslation()

  const showRecipeIcons =
    component?.timeTaken || component?.itemsNeeded || component?.peopleRequired
  const recipeIconStyle = { marginRight: '10px' }

  if (!showRecipeIcons) return null

  return (
    <>
      <ParagraphLarge fontWeight="700" pad="0 0 16px 0">
        {t('learning_room.exercise_page.recipe_icons.you_need')}
      </ParagraphLarge>
      {component?.timeTaken && (
        <Row align="center" pad={{ bottom: '10px' }}>
          <IconClock style={recipeIconStyle} />
          <ParagraphLarge>{component?.timeTaken}</ParagraphLarge>
        </Row>
      )}
      {component?.itemsNeeded && (
        <Row align="center" pad={{ bottom: '10px' }}>
          <IconTools style={recipeIconStyle} />
          <ParagraphLarge>{component?.itemsNeeded}</ParagraphLarge>
        </Row>
      )}
      {component?.peopleRequired && (
        <Row align="center" pad={{ bottom: '10px' }}>
          <IconPeople style={recipeIconStyle} />
          <ParagraphLarge>
            {t(
              `learning_room.exercise_page.recipe_icons.people_required.${component?.peopleRequired}`
            )}
          </ParagraphLarge>
        </Row>
      )}
    </>
  )
}

export default RecipeIcons
