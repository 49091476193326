import React, { useState } from 'react'
import styled from 'styled-components'
import { Field, Form, ErrorMessage } from 'formik'
import {
  Box,
  RadioButtonGroup,
  CheckBox as GrommetCheckBox,
  CheckBoxGroup
} from 'grommet'

import { Paragraph, ParagraphSmall, Text, TextSmall } from './Text'
import { Col, Row } from './View'
import { Tooltip } from './Tooltip'

import viaTheme from '../../../styles/via-theme.json'

import icon_upload from '../../../assets/icons/icon_upload_33.svg'
import eye_closed from '../../../assets/icons/eye_icon.svg'
import eye_open from '../../../assets/icons/eye_icon_open.svg'
import arrow_down from '../../../assets/icons/icon_arrow_down.svg'

import { ReactComponent as IconInfo } from '../../../assets/icons/icon_info.svg'

const FormikTextArea = props => <Field as="textarea" {...props} />

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => (props.narrow ? '356px' : 'unset')};
`

const InputBox = styled(Box)`
  position: relative;
  margin: 0.1875rem 0 0 0;
  display: inline-block;
  &:focus-within label {
    opacity: 1;
  }
`

const TextInput = styled(Field)`
  width: 100%; // clamp(13rem, 100%, 20rem);
  height: 48px;
  background: ${props => props.theme.global.colors.lightGrey};
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 0 1rem;
  ${props =>
    props.type === 'number' &&
    `
    margin-right: 4px;
  `}
  ${props =>
    props.type === 'date' &&
    `
    -webkit-appearance: textfield;
    -moz-appearance: textfield;  
  `}
  &:focus {
    border: 1px solid #00b3ae;
  }
  &:focus label {
    opacity: 1;
  }
`

export const TextArea = styled(FormikTextArea)`
  width: 100%; // clamp(13rem, 100%, 20rem);
  background: ${props => props.theme.global.colors.lightGrey};
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  box-sizing: border-box;
  padding: 1rem;
  height: 144px;
  resize: none;
  font-family: Montserrat; /*font-family not present in grommet defaultProps*/
  &:focus {
    border: ${props => '1px solid ' + props.theme.global.colors.brand};
  }
  &:focus label {
    opacity: 1;
  }
`

const Label = styled.label`
  opacity: 0;
  color: ${props => props.theme.global.colors.turqoise};
  font-size: 0.75rem;
  font-weight: 500;
`

const ShowPasswordWrap = styled.div`
  position: absolute;
  bottom: 8px;
  right: 13px;
`

export const StyledField = props => {
  const [passwordShown, togglePasswordShown] = useState(false)

  const tooltip = props.tooltipText && (
    <Tooltip
      id={props.tooltipId}
      text={props.tooltipText}
      style={{ margin: '0 10px' }}>
      <IconInfo />
    </Tooltip>
  )

  let label
  if (props.label) {
    switch (props.labelType) {
      case 2:
        label = (
          <Text>
            {props.label}
            {tooltip}
          </Text>
        )
        break
      case 3:
        label = (
          <Paragraph>
            {props.label}
            {tooltip}
          </Paragraph>
        )
        break
      default:
        label = (
          <Label>
            {props.label}
            {tooltip}
          </Label>
        )
    }
  }

  return (
    <InputBox style={props.parentStyle}>
      {props.label && (
        <div
          style={{
            textAlign: 'left',
            marginTop: props.labelType === 3 ? '30px' : 0
          }}>
          {label}
        </div>
      )}
      <TextInput
        type={
          props.type === 'password'
            ? passwordShown
              ? 'text'
              : 'password'
            : props.type
        }
        name={props.name}
        data-testid={props.testid}
        placeholder={props.placeholder}
        basis="100%"
        min={props.min || undefined}
        max={props.max || undefined}
        style={{
          padding: props.type === 'password' ? '0 3rem 0 1rem' : '0 1rem',
          ...props.style
        }}
        disabled={props.disabled}
      />
      {props.type === 'password' && (
        <ShowPasswordWrap onClick={() => togglePasswordShown(!passwordShown)}>
          <img src={passwordShown ? eye_open : eye_closed} />
        </ShowPasswordWrap>
      )}
    </InputBox>
  )
}

export const StyledFileUpload = props => {
  let label
  if (props.label) {
    switch (props.labelType) {
      case 3:
        label = (
          <Paragraph style={{ marginTop: '30px' }}>{props.label}</Paragraph>
        )
        break
      default:
        label = <Text>{props.label}</Text>
    }
  }

  return (
    <Col>
      {props.label && <div style={{ textAlign: 'left' }}>{label}</div>}
      <label htmlFor="file-upload" className="custom-file-upload">
        <Row align="center">
          <Box
            width={{ min: '64px', max: '64px' }}
            height="64px"
            margin={{ right: '10px' }}
            background="lightGrey"
            align="center"
            justify="center"
            round="6px">
            <img height="33px" src={icon_upload} />
          </Box>
          <TextSmall>{props.filename}</TextSmall>
        </Row>
      </label>
      <input
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
        onChange={props.onChange}
        accept={props.accept}
      />
    </Col>
  )
}

export const StyledTextArea = props => {
  let label = null
  if (props.label) {
    switch (props.labelType) {
      case 2:
        label = <Text>{props.label}</Text>
        break
      case 3:
        label = <Paragraph>{props.label}</Paragraph>
        break
      default:
        label = <Label>{props.label}</Label>
        break
    }
  }

  const width = props.width || 'auto'
  const autoFocus = !!props.autoFocus
  return (
    <InputBox width={width}>
      {label && <div style={{ textAlign: 'left' }}>{label}</div>}
      <TextArea
        autoFocus={autoFocus}
        type={props.type}
        name={props.name}
        data-testid={props.testid}
        placeholder={props.placeholder}
        style={props.style}
      />
    </InputBox>
  )
}

export const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  text-align: left;
`

const _StyledSelect = styled(TextInput)`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(${arrow_down});
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 11px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  color: black;
  margin-right: 2rem;
  padding-right: 2rem;
`

export const StyledSelect = props => {
  return (
    <_StyledSelect
      {...props}
      name={props.name}
      component="select"
      style={{ marginTop: '30px' }}>
      {props.options &&
        props.options.map(option => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
    </_StyledSelect>
  )
}

export const StyledRadioButtonGroup = ({
  direction,
  disabled,
  name,
  value,
  options,
  onChange
}) => {
  return (
    <RadioButtonGroup
      gap="none"
      direction={direction || 'column'}
      disabled={disabled}
      name={name}
      options={options}
      value={value}
      onChange={onChange}>
      {(option, { checked, focus, hover }) => {
        let background
        if (checked) background = '#7C92A2'
        else if (hover) background = '#d3e2ee'
        else if (focus) background = '#d3e2ee'
        else background = viaTheme.global.colors.lightBlueGrey

        const Icon = (
          <svg viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="6" fill="#fff"></circle>
          </svg>
        )

        return (
          <Row
            align="center"
            pad={{ right: direction === 'row' ? '30px' : '0' }}>
            <Row
              background={background}
              width="24px"
              height="24px"
              style={{ border: '1px solid #7C92A2', borderRadius: '100%' }}>
              {checked && Icon}
            </Row>
            <ParagraphSmall style={{ paddingLeft: '12px' }}>
              {option.label}
            </ParagraphSmall>
          </Row>
        )
      }}
    </RadioButtonGroup>
  )
}

const CheckBox = ({ checked, label }) => {
  let background
  if (checked) background = viaTheme.global.colors.turqoise
  else background = viaTheme.global.colors.lightBlueGrey

  const Icon = (
    <svg
      viewBox="0 0 24 24"
      style={{
        boxSizing: 'border-box',
        strokeWidth: '3px',
        stroke: '#fff',
        width: '24px',
        height: '24px'
      }}>
      <path fill="none" d="M6,11.3 L10.3,16 L18,6.2"></path>
    </svg>
  )
  return (
    <Row align="center" style={{ paddingRight: '30px' }}>
      <Box
        align="center"
        background={background}
        height="24px"
        width="24px"
        style={{ border: '0.5px solid #7C92A2', borderRadius: '4px' }}>
        {checked && Icon}
      </Box>
      <ParagraphSmall style={{ paddingLeft: '12px' }}>{label}</ParagraphSmall>
    </Row>
  )
}

export const StyledCheckBox = ({ name, value, label, onChange }) => {
  return (
    <GrommetCheckBox name={name} checked={!!value} onChange={onChange}>
      {({ checked }) => <CheckBox checked={checked} label={label} />}
    </GrommetCheckBox>
  )
}

export const StyledCheckBoxGroup = ({
  disabled,
  name,
  value,
  options,
  onChange
}) => {
  return (
    <CheckBoxGroup
      direction="column"
      disabled={disabled}
      gap="none"
      labelKey="should_be_set_to_wrong_value"
      name={name}
      options={options}
      onChange={onChange}
      value={value}>
      {(option, { checked }) => (
        <CheckBox checked={checked} label={option.label} />
      )}
    </CheckBoxGroup>
  )
}
