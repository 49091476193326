import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Paragraph as GrommetParagraph,
  Heading as GrommetHeading,
  ResponsiveContext
} from 'grommet'

import viaTheme from '../../../styles/via-theme.json'

export const Heading1 = props => {
  const size = useContext(ResponsiveContext)
  return (
    <GrommetHeading
      level={1}
      {...props}
      style={{
        fontSize: size !== 'large' ? '1.5rem' : '2.5rem',
        lineHeight:
          props.lineHeight || size === 'large' ? '3.125rem' : '1.75rem',
        fontWeight: props.fontWeight || 700,
        color: props.color ? props.color : props.white ? 'white' : 'blackFont',
        margin: '0',
        wordBreak: 'break-word',
        ...props.style
      }}>
      {props.children}
    </GrommetHeading>
  )
}

export const Heading2 = props => {
  const size = useContext(ResponsiveContext)
  return (
    <GrommetHeading
      level={2}
      {...props}
      style={{
        fontSize: size !== 'large' ? '1.25rem' : '1.5rem',
        fontWeight: 700,
        color: props.white ? ' white' : 'blackFont',
        margin: '0',
        letterSpacing: '1px',
        ...props.style
      }}>
      {props.children}
    </GrommetHeading>
  )
}

export const Heading3 = props => (
  <GrommetHeading
    level={3}
    {...props}
    style={{
      fontSize: '1.375rem',
      fontWeight: 500,
      maxWidth: '536px',
      color: props.white ? 'white' : 'blackFont',
      order: props.textlast ? '2' : '0',
      textAlign: props.textAlign || undefined
    }}>
    {props.children}
  </GrommetHeading>
)

export const Heading4 = props => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  return (
    <GrommetHeading
      level={4}
      {...props}
      style={{
        color: props.color || viaTheme.global.colors.darkBlueGrey,
        fontSize: isDesktop ? '1rem' : '0.75rem',
        fontWeight: '600',
        textTransform: props.textTransform || 'uppercase',
        margin: '8px 0',
        lineHeight: '1.3',
        letterSpacing: '0.0625rem',
        ...props.style
      }}>
      {props.children}
    </GrommetHeading>
  )
}

export const ParagraphLarge = props => {
  const size = useContext(ResponsiveContext)

  return (
    <p
      style={{
        fontSize: size === 'large' ? '1.375rem' : '1.125rem',
        lineHeight: size === 'large' ? '1.7875rem' : '1.5rem',
        fontWeight: props.fontWeight || 500,
        color: props.color || undefined,
        textAlign: props.textAlign || undefined,
        order: props.textlast ? '2' : '0',
        margin: props.margin ? props.margin : '0',
        padding: props.pad || undefined
      }}
      {...props}>
      {props.children}
    </p>
  )
}

export const Paragraph = props => (
  <p
    data-testid={props.testid}
    style={{
      fontSize: '1.125rem',
      fontWeight: props.fontWeight ? props.fontWeight : props.bold ? 700 : 500,
      lineHeight: props.lineHeight || '1.5rem',
      color: props.color
        ? props.color
        : props.white
        ? 'white'
        : props.darkBlueGrey
        ? viaTheme.global.colors.darkBlueGrey
        : '',
      margin: props.margin || undefined,
      textAlign: props.textAlign || undefined,
      width: props.width || undefined,
      whiteSpace: props.whiteSpace || undefined,
      wordBreak: 'break-word',
      ...props.style
    }}>
    {props.children}
  </p>
)

export const ParagraphSmall = props => (
  <p
    style={{
      fontSize: '1rem',
      fontWeight: props.bold ? 700 : props.semiBold ? 600 : 500,
      lineHeight: props.lineHeight || '1.125rem',
      color: props.white
        ? 'white'
        : props.darkBlueGrey
        ? viaTheme.global.colors.darkBlueGrey
        : viaTheme.global.colors.blackFont,
      textAlign: props.textAlign || undefined,
      whiteSpace: props.whiteSpace || undefined,
      wordBreak: 'break-word',
      ...props.style
    }}>
    {props.children}
  </p>
)

export const FileNameParagraph = styled.p`
  margin: 0 0 0 16px;
  flex: 2;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.125rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow line breaks */
`

export const Text = props => (
  <p
    style={{
      fontSize: '0.875rem',
      fontWeight: props.bold ? 700 : props.semiBold ? 600 : 400,
      lineHeight: '1.125rem',
      color: props.color
        ? props.color
        : props.white
        ? 'white'
        : viaTheme.global.colors.blackFont,
      textTransform: props.uppercase ? 'uppercase' : undefined,
      ...props.style
    }}>
    {props.children}
  </p>
)

export const TextSmall = props => (
  <p
    style={{
      fontSize: props.fontSize || '0.75rem',
      fontWeight: props.fontWeight || 500,
      lineHeight: props.lineHeight || '15.6px',
      color: props.color
        ? props.color
        : props.white
        ? 'white'
        : viaTheme.global.colors.blackFont,
      textAlign: props.align || 'left',
      ...props.style
    }}>
    {props.children}
  </p>
)

export const TextSemiBold = props => (
  <p
    style={{
      fontSize: '0.25rem',
      fontWeight: 600,
      lineHeight: '15.6px',
      color: props.white ? 'white' : viaTheme.global.colors.blackFont
    }}>
    {props.children}
  </p>
)

export const StyledLink = styled(Link)`
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  text-decoration: none;
  font-weight: 500;
  color: unset;
  width: ${props => (props?.styles?.width ? props.styles.width : 'auto')};
`

export const StyledLink2 = styled(Link)`
  text-align: left;
  padding: ${props => (props.nopad ? '' : '16px 0')};
  margin: 0;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  color: unset;
  white-space: ${props => props.whiteSpace || 'initial'};
`

export const NavLink = props => (
  <StyledLink to={props.to}>
    <ParagraphLarge>{props.children}</ParagraphLarge>
  </StyledLink>
)

// Via logo small
export const ViaLogoLink = styled(Link)`
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: 1px;
  text-decoration: none;
  color: unset;
`

export const Paragraph2 = props => (
  <GrommetParagraph
    weight={props.bold ? 700 : props.semiBold ? 600 : 400}
    {...props}>
    {props.children}
  </GrommetParagraph>
)

export const ParagraphXXL = props => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  return (
    <p
      style={{
        color: props.color || 'blackFont',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: isDesktop ? '112px' : '96px',
        lineHeight: '130%',
        letterSpacing: '0.025px',
        margin: 0,
        ...props.style
      }}>
      {props.children}
    </p>
  )
}
