import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button as GrommetButton } from 'grommet'

import { setToast } from '../../../utils/status'

import { Row } from '../generic/View'
import * as Button from '../generic/Button'
import { ParagraphSmall } from '../generic/Text'

import ButtonLR from './exerciseComponents/ButtonLR'
import Divider from './exerciseComponents/Divider'
import FlipCards from './exerciseComponents/FlipCards'
import FullScreenImage from './exerciseComponents/FullScreenImage'
import Multichoice from './exerciseComponents/Multichoice'
import SwipeableCarousel from './exerciseComponents/SwipeableCarousel'
import RecipeIcons from './exerciseComponents/RecipeIcons'
import TextBlock from './exerciseComponents/TextBlock'
import Video from './exerciseComponents/Video'

import { ReactComponent as IconShare } from '../../../assets/icons/share.svg'

const getComponent = (component, showActionButtons, setShowActionButtons) => {
  const type = component.__component.split('.')[1]

  const componentsList = {
    button: <ButtonLR component={component} />,
    divider: <Divider component={component} />,
    exercises: <SwipeableCarousel component={component} isExerciseCarousel />,
    'flip-cards': <FlipCards component={component} />,
    'full-screen-image': <FullScreenImage component={component} />,
    multichoice: (
      <Multichoice
        component={component}
        showActionButtons={showActionButtons}
        setShowActionButtons={setShowActionButtons}
      />
    ),
    'swipeable-carousel': <SwipeableCarousel component={component} />,
    'recipe-icons': <RecipeIcons component={component} />,
    'text-block': <TextBlock component={component} />,
    video: <Video component={component} />
  }

  return componentsList[type]
}

const ExercisePageBuilder = ({
  components,
  isFirstPage,
  isLastPage,
  actionToTopics,
  actionNext,
  actionBack
}) => {
  const { t } = useTranslation()
  let [showActionButtons, setShowActionButtons] = useState(() => {
    const multiChoiceItems = components.filter(el =>
      el.__component.endsWith('multichoice')
    )
    return multiChoiceItems.length === 0
  })

  const buttonBackToTopic = (
    <Button.Primary
      adjustToSize
      flex={false}
      label={t('learning_room.exercise_page.back_to_topic')}
      margin="10px"
      onClick={actionToTopics}
      type="button"
    />
  )

  const buttonNextPage = (
    <Button.Primary
      adjustToSize
      flex={false}
      label={t('general.next_page')}
      margin="10px"
      onClick={actionNext}
      type="button"
    />
  )

  const buttonPrevPage = (
    <Button.White
      adjustToSize
      flex={false}
      label={t('general.previous_page')}
      margin="10px"
      onClick={actionBack}
      type="button"
    />
  )

  let pageButton
  if (isFirstPage && isLastPage) {
    pageButton = buttonBackToTopic
  } else if (isFirstPage && !isLastPage) {
    pageButton = pageButton = buttonNextPage
  } else if (!isFirstPage && isLastPage) {
    pageButton = (
      <>
        {buttonPrevPage}
        {buttonBackToTopic}
      </>
    )
  } else {
    pageButton = (
      <>
        {buttonPrevPage}
        {buttonNextPage}
      </>
    )
  }

  const onCopyLink = () => {
    try {
      navigator.clipboard.writeText(window.location.href)
      setToast(t('memory_room.invitation_form.copied'), 'info')
    } catch (err) {
      setToast(t('general.copy_not_supported'), 'error')
    }
  }

  const shareButton = (
    <GrommetButton
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={onCopyLink}>
      <IconShare style={{ marginRight: '7px' }} />
      <ParagraphSmall semiBold style={{ color: '#00B3AE' }}>
        {t('learning_room.exercise_page.share.copy_link')}
      </ParagraphSmall>
    </GrommetButton>
  )

  return (
    <div className="pageBuilder">
      {(isFirstPage || isLastPage) && shareButton}
      {components.map((el, index) => {
        const component = getComponent(
          el,
          showActionButtons,
          setShowActionButtons
        )
        const key = Number(new Date()) + index
        return <Fragment key={key}>{component}</Fragment>
      })}
      {showActionButtons && <Row justify="center">{pageButton}</Row>}
    </div>
  )
}

export default ExercisePageBuilder
