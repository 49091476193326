import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../redux/slices/uiSlice'

import { Screen, SectionContent, Section } from '../generic/View'
import { Heading2, ParagraphSmall } from '../generic/Text'
import * as Button from '../generic/Button'
import IllustrationBlock from '../IllustrationBlock'
import MemoryRoomListItem from './MemoryRoomListItem'
import { Tooltip } from '../generic/Tooltip'

import homeIcon from '../../../assets/illustrations/home.svg'
import { ReactComponent as IconInfo } from '../../../assets/icons/icon_info.svg'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function HomeWithMemoryRooms({ memoryRooms }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const header = {
    type: 'menu',
    multiColor: true
  }

  const openCommentPrivacyModal = () => {
    dispatch(
      openModal({
        type: 'openOnlineCounseling'
      })
    )
  }

  return (
    <Screen header={header}>
      <IllustrationBlock
        paragraph={t('home.heading')}
        img={homeIcon}
        background
      />
      <Section>
        <SectionContent>
          <Heading2 style={{ marginBottom: '35px' }}>
            {t('memory_room.my_rooms')}
          </Heading2>
          {memoryRooms.map(roomData => (
            <MemoryRoomListItem key={roomData.id} roomData={roomData} />
          ))}
          {!isAppSunsettingMode && (
            <Link to="/rooms/new">
              <Button.Primary
                type="button"
                data-testid="home-with-memory-screen-button-room-create"
                label={t('memory_room.create_new_room')}
              />
            </Link>
          )}

          {isAppSunsettingMode && (
            <Tooltip
              id={`registration-tooltip`}
              text={t('memory_room.create_new_room_disabled')}>
              <Button.Primary
                disabled
                type="button"
                data-testid="home-with-memory-screen-button-room-create"
                style={{
                  background: '#C5CFD6',
                  opacity: '1',
                  color: '#7C92A2'
                }}
                label={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px'
                    }}>
                    {t('memory_room.create_new_room')}
                    <IconInfo />
                  </span>
                }
              />
            </Tooltip>
          )}

          <ParagraphSmall>{t('home.online_consultation_text')}</ParagraphSmall>

          <Button.White
            onClick={openCommentPrivacyModal}
            style={{ marginBottom: '50px' }}
            type="button"
            label={t('home.online_consultation_button')}
          />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default HomeWithMemoryRooms
