import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import styled, { css } from 'styled-components'
import { Box, ResponsiveContext } from 'grommet'

import gradientFullscreen from '../../../assets/backgrounds/gradient_fullscreen.svg'
import gradientWavy from '../../../assets/backgrounds/gradient_wavy.svg'
import gradientWavySmall from '../../../assets/backgrounds/gradient_wavy_small.svg'
import gradientWavySmallMobile from '../../../assets/backgrounds/gradient_wavy_small_mobile.svg'

import { setHeader } from '../../../redux/slices/uiSlice'

export const Gradient = props => (
  <Box
    direction="column"
    width="100%"
    align="center"
    justify="around"
    background={`url(${gradientFullscreen})`}
    pad="medium"
    height={{ min: props.heightFull ? '100vh' : 'calc(100% - 106px)' }}
    {...props}
  />
)

export const TextAlignCenterGradient = styled(Gradient)`
  text-align: center;
`

export const NavBar = props => (
  <Box
    direction="row"
    height="60px"
    width="100%"
    align="center"
    justify="evenly"
    overflow="hidden"
    flex="false" // prevent being be outgrown
    style={{ position: 'absolute', zIndex: 2 }}
    {...props}
  />
)

export const NavContent = props => (
  <Box
    direction="row"
    basis="480px"
    align="center"
    justifyContent="around"
    overflow="hidden"
    {...props}
  />
)

export const Row = props => <Box direction="row" {...props} />
export const Col = props => <Box direction="column" {...props} />

export const HideOnMobile = styled.div`
  @media (max-width: ${props =>
      props.theme.global.breakpoints.medium.value}px) {
    display: none;
  }
  text-align: center;
`

// generic illustration component for possible later styling and animation
export const Illustration = props => {
  const size = useContext(ResponsiveContext)
  return (
    <img
      style={{
        maxWidth: props.maxwidth || undefined,
        maxHeight: size === 'large' ? '400px' : '200px'
      }}
      {...props}
    />
  )
}

// ------------ NEW LAYOUT COMPONENTS --------------------- //

export const AppContainer = props => {
  return (
    <Col
      width={{ max: '1366px' }}
      height={{ min: '100vh' }}
      alignSelf="center"
      background="white"
      style={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        zIndex: 2
      }} // to fit absolute header inside. default width 100%
      {...props}
    />
  )
}

// <Screen> default screen switches between 2-column and single column layout
// <Screen column> column prop for single column layout
export const Screen = ({ header, children, column, ...props }) => {
  const dispatch = useDispatch()
  // each time a screen is mounted, we set the header
  // account for missing props
  const headerData = {
    id: new Date().valueOf(),
    type: header?.type || undefined,
    title: header?.title || undefined,
    multiColor: !!(header && header.multiColor === true),
    withBackground: !!(header && header.withBackground === true),
    withoutMenu: !!(header && header.withoutMenu === true),
    logoLinkLeadsToIndex: !!header?.logoLinkLeadsToIndex,
    // When backCustomActionTriggered is set to boolean value,
    // custom pages will wait for this prop to change to run a custom back function.
    backCustomActionTriggered:
      header && header.backCustomActionTriggered !== undefined
        ? header.backCustomActionTriggered
        : undefined
  }
  useEffect(() => {
    dispatch(setHeader(headerData))
  }, [headerData.title])
  const size = useContext(ResponsiveContext)

  // add header offset to Sections
  const childrenWithProps = React.Children.map(children, (child, index) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // we clone and edit the Sections inside the Screen here
      // IF Header is active AND Section overlaps header, offset the section
      const headerIsActive = !!headerData.type
      const sectionOverlapsHeader = index === 0 || size === 'large'
      return React.cloneElement(
        child,
        headerIsActive && sectionOverlapsHeader
          ? { pad: { top: '59px' }, margin: { top: '40px' } }
          : null
      )
    }
    return child
  })

  return (
    <Box
      as="main"
      direction={column ? 'column' : size !== 'large' ? 'column' : 'row'} // col on small screen, row on big screen
      align="stretch" // stretch items perpendicular to main axis
      justify={column ? 'start' : size !== 'large' ? 'start' : 'center'} // prevent white top bar on mobile
      flex="grow"
      {...props}>
      {childrenWithProps}
    </Box>
  )
}

//layout element that switch from column to row on desktop
export const Section = props => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  let background = 'transparent'
  switch (props.backgroundType) {
    case 'gradient':
      background = {
        image: `url(${gradientFullscreen})`, // gradient as background.color not working
        size: 'cover',
        dark: 'true' //switches the font color to white
      }
      break
    case 'gradientDesktop':
      background = isDesktop
        ? {
            image: `url(${gradientFullscreen})`,
            size: 'cover',
            dark: 'true'
          }
        : 'transparent'
      break
    case 'gradientWavy':
      background = isDesktop
        ? {
            image: `url(${gradientWavySmall})`,
            size: '100% 99px',
            position: 'top',
            repeat: 'no-repeat'
          }
        : {
            image: `url(${gradientWavySmallMobile})`,
            size: '100% 80px',
            position: 'top',
            repeat: 'no-repeat'
          }
      break
    case 'gradientDynamic':
      background = isDesktop
        ? {
            image: `url(${gradientFullscreen})`,
            size: 'cover',
            dark: 'true'
          }
        : {
            dark: 'true',
            image: `url(${gradientWavy})`,
            size: '100% 70%',
            position: 'top',
            repeat: 'no-repeat'
          }
      break
    default:
      break
  }

  // stretch content on mobile, center on desktop
  let justify
  if (size !== 'large') {
    justify = 'stretch'
  } else {
    justify = props.justify || 'center'
  }

  return (
    <Box
      as="section"
      basis={props.columnItem ? 'unset' : size !== 'large' ? 'unset' : '50%'} // basis destroys layout in column layout.
      width={{ max: '100%' }}
      justify={justify}
      background={background}
      style={{
        textAlign: props.textAlign ? props.textAlign : 'center',
        height:
          props.height === 'full' && isDesktop
            ? 'calc(100% - 40px)'
            : props.height === 'full'
            ? '100%'
            : undefined
      }}
      {...props}
    />
  )
}

// MainContent fits inside Main to provide equal positioning of content elements
export const SectionContent = props => {
  const size = useContext(ResponsiveContext)
  const pad = props.padding || {
    horizontal: `${size !== 'large' ? 'medium' : 'large'}`,
    vertical: 'small'
  }

  return (
    <Col
      pad={pad}
      width={{ max: props.onecol ? '984px' : '670px' }}
      align="center" // if this has a maxWidth, it should be centered
      {...props}
    />
  )
}

export const OverlayWithTransition = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex || '5'};
  width: 100%;
  margin: 0;
  background-color: ${props =>
    props.isDisplayed
      ? props.theme.global.colors['overlay-background']
      : 'rgba(255,255,255,0)'};
  height: ${props => (props.isDisplayed ? '100%' : '0px')};
  backdrop-filter: ${props => (props.isDisplayed ? 'blur(4px);' : 'blur(0px)')};
  transition: ${props =>
    props.isDisplayed
      ? `ease-in-out, background ${props.transitionDuration} ease-in-out`
      : `ease-in-out, background ${props.transitionDuration} ease-in-out, height 0s ${props.transitionDuration}`};
`

// ------- Modal Views ---------- //
const OverlayBox = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  //mix-blend-mode: multiply; // this disables the blur
  backdrop-filter: blur(4px);
`

export const Overlay = props => {
  // when overlay is shown, disable scroll on root element
  useEffect(() => {
    document.querySelector('#root').style.overflowY = 'hidden'

    return () => {
      document.querySelector('#root').style.overflowY = 'auto'
    }
  }, [])

  return <OverlayBox {...props} />
}

export const ModalContainer = styled(Box)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: row;
`

const UnstyledLayer = props => {
  const size = useContext(ResponsiveContext)
  return (
    <Col
      {...props}
      fill="horizontal"
      margin={
        size === 'large' ? 'large' : size === 'medium' ? 'xlarge' : 'medium'
      }
      width={{ max: props.narrow ? '485px' : '1240px' }}
      height={{ max: '90%' }}
      pad={{
        horizontal: 'large',
        bottom: props.padNone ? '' : '92px',
        top: props.padNone ? '30px' : '92px'
      }}
    />
  )
}

export const Layer = styled(UnstyledLayer)`
  z-index: 2;
  overflow-y: auto; // only show scrollbar if needed
  border-radius: 32px;
  position: relative; // required to position the close-button absolutely
`

// ------------- Header Views ------------- //
export const Header = props => {
  const size = useContext(ResponsiveContext)
  return (
    <Section
      direction="row"
      height={size === 'large' ? '99px' : '80px'}
      pad={{ bottom: '19px' }}
      width="100%"
      align="center"
      justify="between"
      overflow="show"
      style={{ position: 'absolute', zIndex: 3, backgroundSize: '100% 100%' }}
      {...props}
    />
  )
}

export const HeaderContent = props => (
  <Box
    direction="row"
    basis="480px"
    align="center"
    justifyContent="around"
    overflow="hidden"
    {...props}
  />
)

export const HeaderSection = props => {
  const size = useContext(ResponsiveContext)
  const lightBg = props.multiColor === true && size === 'large'
  return (
    <Row
      direction="row"
      pad={{ horizontal: 'medium' }}
      background={{
        color: !lightBg ? 'black' : undefined,
        opacity: 0, // we don't really want a bgColor. we want to set grommet context
        dark: !lightBg ? 'true' : 'false'
      }}
      align="center"
      {...props}
    />
  )
}

export const InformationBox = styled(Box)`
  padding: 28px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  width: 100%;

  @media (max-width: 567px) {
    padding: 14px;
  }

  ${props =>
    props.maxWidth &&
    css`
      @media (min-width: ${Number(props.maxWidth) + 14}px) {
        width: ${props.maxWidth}px;
      }
    `}
`
