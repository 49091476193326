import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import ajax, { ajaxWithFiles } from '../../utils/ajax'

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH

// tool to normalize data {ids, entities}
const memoryRoomAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt) // sort date
})
// create initial state (ids and entities created by default)
const initialState = memoryRoomAdapter.getInitialState({
  memoryRooms: [],
  ids: [],
  submitting: false,
  error: null
})

// Async Actions
export const create = createAsyncThunk(
  'memoryRoom/create',
  async ({ userId, formData }, thunkAPI) => {
    try {
      const result = await ajaxWithFiles({
        url: `${API_BASE_PATH}/users/${userId}/rooms`,
        method: 'POST',
        data: formData
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const fetchForUser = createAsyncThunk(
  'memoryRoom/fetchForUser',
  async (userId, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/users/${userId}/rooms`,
        method: 'GET'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const fetchRoom = createAsyncThunk(
  'memoryRoom/fetchRoom',
  async memoryRoomUserId => {
    const result = await ajax({
      url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}`,
      method: 'GET'
    })
    return result
  }
)

export const invite = createAsyncThunk(
  'memoryRoom/invite',
  async (
    { memoryRoomUserId, email, message, ignoreExistingInvite },
    thunkAPI
  ) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/invites`,
        method: 'POST',
        data: {
          email,
          message,
          ignoreExistingInvite
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const publicInvite = createAsyncThunk(
  'memoryRoom/publicInvite',
  async ({ memoryRoomUserId }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/invites/public`,
        method: 'POST',
        data: {}
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const updateMemoryRoomUserRole = createAsyncThunk(
  'memoryRoomUser/updateRole',
  async ({ modifiedRoomUserId, roomOwnerId, role }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${roomOwnerId}/role`,
        method: 'PUT',
        data: {
          modifiedRoomUserId: modifiedRoomUserId,
          role: role
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const removeMemoryRoomUser = createAsyncThunk(
  'memoryRoomUser/removeMemoryRoomUser',
  async ({ modifiedRoomUserId, roomOwnerId, creatorId }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${roomOwnerId}/remove`,
        method: 'PUT',
        data: {
          modifiedRoomUserId: modifiedRoomUserId,
          creatorId
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const leaveRoom = createAsyncThunk(
  'memoryRoomUser/leaveRoom',
  async ({ memoryRoomUserId }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/leave-room`,
        method: 'PUT'
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const updateMemoryRoomUserNotificationState = createAsyncThunk(
  'memoryRoomUser/updateNotificationState',
  async ({ memoryRoomUserId, getNotifications }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/notifications`,
        method: 'PUT',
        data: {
          getNotifications: getNotifications
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const setRoomImage = createAsyncThunk(
  'memoryRoom/setRoomImage',
  async ({ memoryRoomUserId, formData }, thunkAPI) => {
    try {
      const result = await ajaxWithFiles({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/room-image`,
        method: 'POST',
        data: formData
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const update = createAsyncThunk(
  'memoryRoom/update',
  async ({ memoryRoomUserId, formData }, thunkAPI) => {
    try {
      const payload = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/update`,
        method: 'PUT',
        data: formData
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const memoryRoomSlice = createSlice({
  name: 'memoryRoom',
  initialState,
  reducers: {},
  extraReducers: {
    // create memory room
    [create.pending]: state => {
      state.submitting = true
    },
    [create.fulfilled]: (state, action) => {
      memoryRoomAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [create.rejected]: state => {
      state.submitting = false
    },
    // update memory room
    [update.pending]: state => {
      state.submitting = true
    },
    [update.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [update.rejected]: state => {
      state.submitting = false
    },

    [fetchForUser.pending]: state => {
      state.submitting = true
    },
    [fetchForUser.fulfilled]: (state, action) => {
      memoryRoomAdapter.setAll(state, action.payload)
      state.submitting = false
    },
    [fetchForUser.rejected]: state => {
      state.submitting = false
    },
    [fetchRoom.pending]: state => {
      state.submitting = true
    },
    [fetchRoom.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [fetchRoom.rejected]: state => {
      state.submitting = false
    },
    [updateMemoryRoomUserRole.pending]: state => {
      state.submitting = true
    },
    [updateMemoryRoomUserRole.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [updateMemoryRoomUserRole.rejected]: state => {
      state.submitting = false
    },
    [updateMemoryRoomUserNotificationState.pending]: state => {
      state.submitting = true
    },
    [updateMemoryRoomUserNotificationState.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [updateMemoryRoomUserNotificationState.rejected]: state => {
      state.submitting = false
    },
    [setRoomImage.pending]: state => {
      state.submitting = true
    },
    [setRoomImage.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [setRoomImage.rejected]: state => {
      state.submitting = false
    },
    [removeMemoryRoomUser.pending]: state => {
      state.submitting = true
    },
    [removeMemoryRoomUser.fulfilled]: (state, action) => {
      memoryRoomAdapter.setOne(state, action.payload)
      state.submitting = false
    },
    [removeMemoryRoomUser.rejected]: state => {
      state.submitting = false
    },
    [leaveRoom.pending]: state => {
      state.submitting = true
    },
    [leaveRoom.fulfilled]: state => {
      state.submitting = false
    },
    [leaveRoom.rejected]: state => {
      state.submitting = false
    }
  }
})

// Selectors
export const {
  selectAll: selectAllMemoryRooms,
  selectById: selectMemoryRoomById,
  selectIds: selectMemoryRoomIds
} = memoryRoomAdapter.getSelectors(state => state.memoryRoom)

export default memoryRoomSlice.reducer
