import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { updateMemoryRoomUserRole } from '../../../../redux/slices/memoryRoomSlice'
import iconAngleLeft from '../../../../assets/icons/icon_angle_left.svg'

import { closeModal, openModal } from '../../../../redux/slices/uiSlice'
const ROLES = require('../../../../constants/memoryRoomUserRoles').ROLES

import { setToast } from '../../../../utils/status'
import viaTheme from '../../../../styles/via-theme.json'
import { ParagraphSmall, Text } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Col, Row } from '../../generic/View'

const memoryRoomUserDetailed = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)

  const {
    roomOwnerId,
    activeUser,
    userAvatar,
    userAvatarStyle,
    adminSelection,
    creatorId,
    memoryRoomUser,
    user,
    memoryRoom
  } = props.modalProps

  const isAdmin = activeUser.role === ROLES.OWNER
  const isRemovalEnabled = creatorId !== activeUser.user.id

  const openUsersModal = () => {
    dispatch(
      openModal({
        type: 'memoryRoomUsers',
        props: { user, memoryRoom, memoryRoomUser, adminSelection },
        fullWidth: true
      })
    )
  }

  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }
  const handleUserRoleChange = async () => {
    setSubmitting(true)
    const result = await dispatch(
      updateMemoryRoomUserRole({
        modifiedRoomUserId: activeUser.id,
        roomOwnerId: roomOwnerId,
        role: ROLES.OWNER
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        dispatch(closeModal())
        setToast(result.payload.title, 'error')
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      setToast(t('roles.member_was_appointed_as_admin'), 'success')
      if (adminSelection) {
        onOpenModal('memoryRoomLeave')
      } else {
        dispatch(closeModal())
      }
    }
  }

  return (
    <Col style={{ ...props.style }}>
      <div
        style={{
          width: '20px',
          height: '29px',
          position: 'relative',
          top: '-60px',
          textAlign: 'left',
          background: `no-repeat url(${iconAngleLeft})`,
          cursor: 'pointer'
        }}
        tabIndex="0"
        onKeyDown={e => {
          if (e.code === 'Space' || e.code === 'Enter') {
            e.preventDefault()
            openUsersModal()
          }
        }}
        role="button"
        onClick={() => openUsersModal()}
      />
      <Row align="center" height="72px" margin={{ bottom: 'medium' }}>
        <img
          src={userAvatar}
          alt="user avatar"
          height={52}
          width={52}
          style={{
            borderRadius: '100%',
            objectFit: 'cover',
            marginRight: '15px',
            ...userAvatarStyle
          }}
        />
        <Col>
          <ParagraphSmall style={{ margin: '2px 0' }}>
            {activeUser.user.displayName}
          </ParagraphSmall>
          <Text
            uppercase
            semiBold
            color={viaTheme.global.colors.darkBlueGrey}
            style={{ margin: '2px 0' }}>
            {t(`roles.${activeUser.role}`)}
          </Text>
        </Col>
      </Row>
      {!adminSelection && isRemovalEnabled && (
        <Button.LinkButton
          type="button"
          color="#f07b7b"
          label={t('memory_room.remove_user')}
          onClick={() => onOpenModal('memoryRoomUserRemove')}
          disabled={submitting}
        />
      )}
      {!isAdmin && (
        <Button.White
          type="button"
          label={t('roles.make_admin')}
          onClick={() => handleUserRoleChange()}
          disabled={submitting}
        />
      )}
    </Col>
  )
}

export default memoryRoomUserDetailed
