import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const More = styled.span`
  color: ${props => props.theme.global.colors.turqoise};
  cursor: pointer;
`

export const ShowMore = ({ str, nomore }) => {
  const [show, toggleShow] = useState(false)
  const { t } = useTranslation()

  if (!str) return null

  if (str.length > 110 && show === false) {
    return (
      <>
        {str.substring(0, 110)}...{' '}
        {!nomore && (
          <More onClick={() => toggleShow(true)}>
            {' '}
            {t('memory_room.show_more')}
          </More>
        )}
      </>
    )
  } else if (str.length <= 110) {
    return str
  } else if (str.length > 110 && show === true) {
    return (
      <>
        <span style={{ whiteSpace: 'pre-line' }}>{str}</span>{' '}
        <More onClick={() => toggleShow(false)}>
          {' '}
          {t('memory_room.show_less')}
        </More>{' '}
      </>
    )
  }
}
