import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, ResponsiveContext } from 'grommet'

import { closeModal } from '../../../../redux/slices/uiSlice'
import {
  selectTemporaryData,
  setTemporaryData
} from '../../../../redux/slices/uiSlice'

const { PRIVACY_SETTINGS } = require('../../../../constants/privacySettings')

import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Col } from '../../generic/View'
import * as Icon from '../../generic/Icon'

const memoryPrivacySetting = props => {
  const { memoryRoomId, memoryId } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const size = useContext(ResponsiveContext)
  const tempData = useSelector(selectTemporaryData)

  const selectPrivacySetting = privacy => {
    if (memoryRoomId) {
      dispatch(setTemporaryData({ ...tempData, privacy }))
      if (memoryId) {
        navigate(`/rooms/${memoryRoomId}/memory/${memoryId}/new-content`)
      } else {
        navigate(`/rooms/${memoryRoomId}/new-memory`)
      }
    } else {
      dispatch(
        setTemporaryData({
          ...tempData,
          privacy: PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY,
          contentPrivacy: privacy,
          key: new Date().valueOf()
        })
      )
    }
    dispatch(closeModal())
  }

  const questionTranslationKey = memoryRoomId
    ? 'memory.privacy.do_you_want_to_share_memory'
    : 'memory.privacy.do_you_want_to_share_that'

  const privateLabel = (
    <>
      {t('memory.privacy.keep_private')}
      <Icon.Lock style={{ marginLeft: '8px' }} />
    </>
  )

  const publicLabel = (
    <>
      {t('memory.privacy.keep_public')}
      <Icon.Group style={{ marginLeft: '8px' }} />
    </>
  )

  const isDesktop = size === 'large'

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t(questionTranslationKey)}
      </Paragraph>

      <Box
        direction={isDesktop ? 'row' : 'column-reverse'}
        justify={isDesktop ? 'between' : 'evenly'}
        style={{ margin: '30px 0 20px 0' }}>
        <Button.White
          type="button"
          flex
          pad="0 1rem"
          label={privateLabel}
          onClick={() => selectPrivacySetting(PRIVACY_SETTINGS.PRIVATE)}
          spinnerOnDisabled={true}
          adjustToSize={size}
          style={!isDesktop ? { width: '100%', maxWidth: '300px' } : {}}
        />

        <Button.White
          type="button"
          flex
          pad="0 1rem"
          label={publicLabel}
          onClick={() => selectPrivacySetting(PRIVACY_SETTINGS.PUBLIC)}
          adjustToSize={size}
          data-testid="button-keep-public"
          style={
            !isDesktop
              ? { width: '100%', maxWidth: '300px', marginBottom: '20px' }
              : {}
          }
        />
      </Box>

      {memoryRoomId && (
        <Button.LinkButton
          type="button"
          label={t('memory.privacy.choose_individually')}
          style={{ textDecoration: 'none', textAlign: 'center' }}
          onClick={() =>
            selectPrivacySetting(PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY)
          }
        />
      )}
    </Col>
  )
}

export default memoryPrivacySetting
