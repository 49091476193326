import React from 'react'
import { ParagraphSmall, StyledLink2 } from '../generic/Text'
import { Row } from '../generic/View'
import * as Icon from '../../components/generic/Icon'

const NavItem = props => {
  return (
    <StyledLink2 to={props.to}>
      <Row justify="between" align="center">
        <ParagraphSmall darkBlueGrey>{props.label}</ParagraphSmall>
        <Icon.AngleRight />
      </Row>
    </StyledLink2>
  )
}

export default NavItem
