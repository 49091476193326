import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../redux/slices/uiSlice'

import { Col } from '../../generic/View'
import { ModalMenuItem } from '../../generic/Elements'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const memoryRoomSettingsOwner = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }

  return (
    <Col style={{ ...props.style }}>
      <ModalMenuItem
        title={t('memory_room.members')}
        onClick={() => onOpenModal('memoryRoomUsers', true)}
      />
      {!isAppSunsettingMode && (
        <>
          <ModalMenuItem
            title={t('memory_room.rename_room')}
            onClick={() => onOpenModal('memoryRoomRename')}
          />
          <ModalMenuItem
            title={t('memory_room.change_description')}
            onClick={() => onOpenModal('memoryRoomChangeDescription', true)}
          />
          <ModalMenuItem
            title={t('memory_room.change_image')}
            onClick={() => onOpenModal('memoryRoomChangeImage')}
          />
        </>
      )}
      <ModalMenuItem
        title={t('general.onboarding.watch_onboarding')}
        onClick={props.modalProps.onboardingAction}
      />
      <ModalMenuItem
        title={t('memory_room.leave_the_room')}
        onClick={() => onOpenModal('memoryRoomLeave')}
      />
    </Col>
  )
}

export default memoryRoomSettingsOwner
