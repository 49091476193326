import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { stringify } from 'qs'

import { selectHeader } from '../../../redux/slices/uiSlice'

import { setToast } from '../../../utils/status'
import ajax from '../../../utils/ajax'
import { handleBackButton, useQuery } from '../../../utils/generic'
import { scrollWindow } from '../../../utils/browser'

import PreparedHelmet from '../../components/seo/PreparedHelmet'
import { Screen, Section, InformationBox } from '../../components/generic/View'
import ExercisePageBuilder from '../../components/learningRoom/ExercisePageBuilder'

const { REACT_APP_STRAPI_API_URL } = process.env

function Exercise() {
  let { topicId, exerciseId } = useParams()

  const [data, setData] = useState([])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const headerRedux = useSelector(selectHeader)
  let [currentPage, setCurrentPage] = useState(1)
  const { t } = useTranslation()
  const query = useQuery()

  const isPreview = query.has('preview')

  topicId = parseInt(topicId, 10)
  exerciseId = parseInt(exerciseId, 10)

  const navigateBackPath = isPreview ? '/learning/' : `/learning/${topicId}/`

  const fetchParams = stringify(
    {
      fields: ['title', 'shortDescription'],
      populate: 'deep,5'
    },
    {
      encodeValuesOnly: true
    }
  )

  const fetchData = async () => {
    try {
      setData([])
      setCurrentPage(1)

      const response = await ajax({
        url: `${REACT_APP_STRAPI_API_URL}/exercises/${exerciseId}?${fetchParams}`,
        method: 'GET',
        isPublicRequest: true
      })

      if (response?.data?.attributes) {
        setData(response?.data?.attributes)
      } else {
        setToast(t('general.errors.general'), 'error')
      }
    } catch (err) {
      setToast(err, 'error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [topicId, exerciseId])

  // handle action on back button
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () => navigate(navigateBackPath))
  }, [headerRedux.backCustomActionTriggered])

  const exerciseHeading = data?.title || ''

  const header = {
    type: 'actionBar',
    title: exerciseHeading,
    withBackground: true,
    backCustomActionTriggered: false
  }

  // prepare pages array
  let pages = []
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      if (key.startsWith('page') && Array.isArray(value) && value.length > 0) {
        pages.push(value)
      }
    }
  }

  const totalPages = pages.length

  const setCurrentPageAction = (toNextPage = true) => {
    scrollWindow()
    setCurrentPage(prevValue => (toNextPage ? ++prevValue : --prevValue))
  }

  const metaTitle = `${exerciseHeading} – ${t('landing.heading_subheading')}`
  const metaDescription = data?.shortDescription || t('landing.text')

  return (
    <>
      <PreparedHelmet
        title={metaTitle}
        description={metaDescription}
        titleHasAppName={true}
      />

      <Screen header={header}>
        <Section columnItem textAlign="left" justify="start">
          <InformationBox maxWidth="928">
            {totalPages > 0 && (
              <ExercisePageBuilder
                key={Number(new Date())}
                components={pages[currentPage - 1]}
                isFirstPage={currentPage === 1}
                isLastPage={currentPage === totalPages}
                actionToTopics={() => navigate(navigateBackPath)}
                actionNext={() => setCurrentPageAction()}
                actionBack={() => setCurrentPageAction(false)}
              />
            )}
          </InformationBox>
        </Section>
      </Screen>
    </>
  )
}

export default Exercise
