// Most supported audio, image and video media types

const SUPPORTED_AUDIO_TYPES = [
  'audio/aac',
  'audio/flac',
  'audio/m4a',
  'audio/x-m4a',
  'audio/mp4',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm'
]

const SUPPORTED_IMAGE_TYPES = [
  'image/avif',
  'image/bmp',
  'image/gif',
  'image/heic',
  'image/heif',
  'image/jpeg',
  'image/png',
  'image/webp'
]

const SUPPORTED_VIDEO_TYPES = [
  'video/3gpp',
  'video/mp4',
  'video/ogg',
  'video/quicktime',
  'video/webm'
]

const SUPPORTED_DOCUMENT_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.oasis.opendocument.text-template',
  'text/plain'
]

const SUPPORTED_MEDIA_TYPES = [
  ...SUPPORTED_AUDIO_TYPES,
  ...SUPPORTED_IMAGE_TYPES,
  ...SUPPORTED_VIDEO_TYPES
]

const SUPPORTED_AUDIO_ACCEPT_PROP = 'audio/*'
const SUPPORTED_IMAGE_ACCEPT_PROP = 'image/*,image/heif,image/heic'
const SUPPORTED_VIDEO_ACCEPT_PROP = 'video/*,video/3gpp'
const SUPPORTED_DOCUMENT_ACCEPT_PROP = '.pdf, .doc, .docx, .txt, .ott'

module.exports = {
  SUPPORTED_AUDIO_TYPES,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_VIDEO_TYPES,
  SUPPORTED_DOCUMENT_TYPES,
  SUPPORTED_MEDIA_TYPES,
  SUPPORTED_AUDIO_ACCEPT_PROP,
  SUPPORTED_IMAGE_ACCEPT_PROP,
  SUPPORTED_VIDEO_ACCEPT_PROP,
  SUPPORTED_DOCUMENT_ACCEPT_PROP
}
