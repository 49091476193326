const { detect } = require('detect-browser')
const browser = detect()

const {
  APP_LANGUAGES,
  APP_LANGUAGES_VALUES
} = require('../constants/appLanguages')

export const getBrowserName = () => {
  if (browser) {
    return browser.name + ' ' + browser.version
  } else {
    return 'NA'
  }
}

export const getOSandCPU = () => {
  let sUsrAg = navigator.userAgent
  let regExp = /\(([^)]+)\)/
  let os = regExp.exec(sUsrAg)
  if (os) {
    return os[1]
  } else {
    return 'NA'
  }
}

export const getDeviceOrientation = () => {
  let orientation =
    screen.orientation?.type ||
    screen.mozOrientation ||
    screen.msOrientation ||
    ''
  if (orientation) {
    return orientation
  } else {
    return 'NA'
  }
}

export function getBrowserLocale() {
  let locale
  if (navigator.language) {
    locale = navigator.language.slice(0, 2).toLowerCase()
  }

  if (!locale || !APP_LANGUAGES_VALUES.includes(locale)) {
    locale = APP_LANGUAGES.de.value
  }

  return locale
}

export function getBrowserData() {
  return {
    navInfo: {
      browser: getBrowserName(),
      os: getOSandCPU()
    },
    screenInfo: {
      size: {
        width: window.screen.width,
        height: window.screen.height
      },
      orientation: getDeviceOrientation()
    }
  }
}

export function scrollWindow(toTop = true, instant = true) {
  const scrollObject = {
    left: 0,
    top: toTop ? 0 : 999999999,
    behavior: instant ? 'instant' : 'smooth'
  }
  // we need to scroll on root and window, as sometimes one of them is not working.
  const root = document.querySelector('#root')
  root.scrollTo(scrollObject)
  window.scrollTo(scrollObject)
}

export const parseJWT = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
