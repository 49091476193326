import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Screen,
  Section,
  InformationBox,
  Row
} from '../components/generic/View'
import { ThematicBreak } from '../components/generic/Elements'
import Markdown from '../components/generic/Markdown'
import PreparedHelmet from '../components/seo/PreparedHelmet'

import logo from '../../assets/img/malteser_logo.png'

const DataPrivacy = () => {
  const { t } = useTranslation()
  const [MDContent, setMDContent] = useState('')

  // Fetch MD file
  useEffect(() => {
    import('../../markdown/data_privacy.md')
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setMDContent(res))
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
  })

  const header = {
    type: 'menu',
    withBackground: true,
    withoutMenu: true,
    logoLinkLeadsToIndex: true
  }

  return (
    <>
      <PreparedHelmet
        title={t('general.privacy_policy')}
        description={t('memory_room.landing_text')}
        titleHasAppName={true}
      />

      <Screen header={header}>
        <Section columnItem textAlign="left">
          <InformationBox>
            <Row justify="end">
              <img
                src={logo}
                alt="Malteser Logo"
                style={{ height: '66px', width: 'auto' }}
              />
            </Row>
            <ThematicBreak />
            <Markdown>{MDContent}</Markdown>
          </InformationBox>
        </Section>
      </Screen>
    </>
  )
}

export default DataPrivacy
