import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'

import { update } from '../../../../redux/slices/memoryRoomSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
import { Col } from '../../generic/View'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import * as Input from '../../generic/Input'

const memoryRoomChangeDescription = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)

  const { memoryRoom, memoryRoomUser } = props.modalProps

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(
      update({
        memoryRoomUserId: memoryRoomUser.id,
        formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      setToast(t('memory_room.description_successfully_changed'), 'success')
    }
  }

  const initValue = memoryRoom.creatorIsDeactivated
    ? ''
    : memoryRoom.belovedDescription

  return (
    <Col style={{ ...props.style }}>
      <Paragraph>{t('memory_room.change_description')}</Paragraph>

      <Formik
        initialValues={{ belovedDescription: initValue }}
        validate={values => {
          const errors = {},
            belovedDescriptionLimit = 2000

          if (!values.belovedDescription.trim()) {
            errors.belovedDescription = t('general.required_field')
          } else if (
            values.belovedDescription.trim().length > belovedDescriptionLimit
          ) {
            errors.belovedDescription = t('general.errors.text_too_long', {
              limit: belovedDescriptionLimit,
              length: values.belovedDescription.trim().length
            })
          }

          return errors
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm>
            <Input.StyledTextArea
              type="text"
              name="belovedDescription"
              testid="input-description"
            />
            <Input.StyledErrorMessage
              name="belovedDescription"
              component="div"
            />
            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.save')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
    </Col>
  )
}

export default memoryRoomChangeDescription
