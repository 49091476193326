import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import * as Input from '../../components/generic/Input'
import * as Button from '../../components/generic/Button'

import { create } from '../../../redux/slices/memoryRoomSlice'
import { selectUser } from '../../../redux/slices/userSlice'
import { setToast } from '../../../utils/status'
import { CONTENT_TYPES } from '../../../constants/contentTypes'
const {
  SUPPORTED_IMAGE_ACCEPT_PROP
} = require('../../../constants/supportedMediaTypes')
const { isFileValid } = require('../../../utils/generic')

function MemoryRoomForm() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  const { t } = useTranslation()

  const [fileName, setFileName] = useState(t('general.image_file_limit'))
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async fd => {
    setSubmitting(true)

    const formData = new FormData()
    formData.append('belovedName', fd.belovedName)
    formData.append('belovedDescription', fd.belovedDescription)

    if (fd.file && fd.file.length > 0) {
      formData.append('file', fd.file[0])
    }

    const result = await dispatch(
      create({
        userId: user.id,
        formData: formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      navigate(`/rooms/${result.payload.id}`)
      setToast(t('memory_room.room_successfully_created'), 'success')
    }
  }

  return (
    <Formik
      initialValues={{ belovedName: '', belovedDescription: '', file: '' }}
      validate={values => {
        const errors = {},
          belovedNameLimit = 50,
          belovedDescriptionLimit = 2000

        // belovedName validation
        if (!values.belovedName.trim()) {
          errors.belovedName = t('general.required_field')
        } else if (
          values.belovedName &&
          values.belovedName.trim().length > belovedNameLimit
        ) {
          errors.belovedName = t('general.errors.text_too_long', {
            limit: belovedNameLimit,
            length: values.belovedName.trim().length
          })
        }

        // belovedDescription validation
        if (
          values.belovedDescription &&
          values.belovedDescription.trim().length > belovedDescriptionLimit
        ) {
          errors.belovedDescription = t('general.errors.text_too_long', {
            limit: belovedDescriptionLimit,
            length: values.belovedDescription.trim().length
          })
        }

        return errors
      }}
      onSubmit={handleSubmit}>
      {props => (
        <Input.StyledForm>
          <Input.StyledFileUpload
            name="file"
            accept={SUPPORTED_IMAGE_ACCEPT_PROP}
            label={t('memory_room.label.upload_image')}
            labelType={3}
            filename={fileName}
            onChange={event => {
              const curFile = event.currentTarget.files[0]
              if (curFile) {
                if (isFileValid(curFile, CONTENT_TYPES.IMAGE)) {
                  setFileName(curFile?.name)
                  props.setFieldValue('file', event.currentTarget.files)
                } else {
                  setToast(
                    t('media.image_upload_error') + ' ' + t('media.image_info'),
                    'error'
                  )
                }
              }
            }}
          />
          <Input.StyledField
            type="text"
            name="belovedName"
            testid="input-beloved-name"
            label={t('memory_room.label.beloved_name')}
            labelType={3}
          />
          <Input.StyledErrorMessage name="belovedName" component="div" />
          <Input.StyledTextArea
            type="text"
            name="belovedDescription"
            testid="input-description"
            label={t('memory_room.label.description')}
            labelType={3}
          />
          <Input.StyledErrorMessage name="belovedDescription" component="div" />
          <Button.Primary
            type="submit"
            flex={false}
            data-testid="input-submit"
            label={t('general.save')}
            disabled={submitting}
            spinnerOnDisabled={true}
          />
        </Input.StyledForm>
      )}
    </Formik>
  )
}

export default MemoryRoomForm
