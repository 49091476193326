import React, { Fragment, useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { selectHeader, setHeader } from '../../../redux/slices/uiSlice'
import hamburgerMenu from '../../../assets/icons/hamburger_menu.svg'
import * as View from './View'
import { Paragraph, ViaLogoLink } from './Text'
import * as Icon from './Icon'
import * as Button from './Button'

const LINK_ONLINE_CONSULTATION = process.env.REACT_APP_LINK_ONLINE_CONSULTATION
const DISPLAY_LEARNING_ROOM_CONTENT =
  process.env.REACT_APP_DISPLAY_LEARNING_ROOM_CONTENT === 'TRUE'

function MenuIcon(props) {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  return (
    <View.Row onClick={props.openMenuAction}>
      <img
        height={38}
        width={38}
        src={props.imageSrc}
        alt="menu"
        style={{
          filter:
            props.multiColor && isDesktop && props.imageSrc === hamburgerMenu
              ? 'invert(1)'
              : '',
          objectFit: 'cover',
          borderRadius: '50%'
        }}
      />
    </View.Row>
  )
}

const MenuWrapper = styled.div`
  align-items: end;
  background: #fff;
  border-left: 1px solid #475662;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 280px;
  overflow-y: auto;
  padding: 32px 24px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  @media (min-width: 1366px) {
    right: calc(50% - 685px);
  }
`

function MenuItem({ to, externalLink, title, currentLocation }) {
  const style = {
    display: 'block',
    margin: 0,
    padding: '16px 12px',
    textAlign: 'right',
    textDecoration: 'none',
    width: '100%'
  }

  title = (
    <Paragraph color="#475662" margin="0">
      {title}
    </Paragraph>
  )

  if (externalLink) {
    return (
      <a href={to} target="_blank" style={style} rel="noreferrer">
        {title}
      </a>
    )
  }

  return (
    <Link
      to={{ pathname: to, state: { prevPath: currentLocation } }}
      style={style}>
      {title}
    </Link>
  )
}

function Menu(props) {
  const { t } = useTranslation()
  const location = useLocation()
  const [menuIsOpened, setMenuIsOpened] = useState(false)

  return (
    <Fragment>
      <View.HeaderSection>
        <ViaLogoLink to={props.logoLinkLeadsToIndex ? '/' : '/home'}>
          Via.
        </ViaLogoLink>
      </View.HeaderSection>
      <View.HeaderSection multiColor={props.multiColor}>
        {!props.withoutMenu && (
          <MenuIcon
            imageSrc={hamburgerMenu}
            multiColor={props.multiColor}
            openMenuAction={() => setMenuIsOpened(true)}
          />
        )}
      </View.HeaderSection>
      {!props.withoutMenu && menuIsOpened && (
        <>
          <MenuWrapper>
            <Button.Close
              onClick={() => setMenuIsOpened(false)}
              style={{ marginBottom: '35px' }}
            />
            {DISPLAY_LEARNING_ROOM_CONTENT && (
              <MenuItem
                to="/learning"
                title={t('learning_room.heading')}
                currentLocation={location.pathname}
              />
            )}
            <MenuItem
              to={LINK_ONLINE_CONSULTATION}
              title={t('grief_counselling.heading')}
              currentLocation={location.pathname}
              externalLink
            />
            <MenuItem
              to="/profile"
              title={t('profile.heading')}
              currentLocation={location.pathname}
            />
          </MenuWrapper>
          <View.Overlay
            align="center"
            background="overlay-background"
            style={{ position: 'fixed', zIndex: 9 }}
            onClick={() => setMenuIsOpened(false)}
          />
        </>
      )}
    </Fragment>
  )
}

function ActionBar(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()

  const onClickBack = () => {
    if (props.header.backCustomActionTriggered === undefined) {
      navigate(-1)
    } else {
      let header = { ...props.header }
      header.backCustomActionTriggered = {
        triggered: true,
        pathname: pathname + search
      }
      dispatch(setHeader(header))
    }
  }
  return (
    <Fragment>
      <View.HeaderSection onClick={onClickBack}>
        <Icon.ArrowLeft />
      </View.HeaderSection>
      <View.HeaderSection multiColor={props.header.multiColor}>
        <Paragraph>{props.header.title}</Paragraph>
      </View.HeaderSection>
    </Fragment>
  )
}

function Header() {
  const header = useSelector(selectHeader)
  if (header && header.type !== 'menu' && header.type !== 'actionBar')
    return null

  return (
    <View.Header
      key={header.id}
      backgroundType={header?.withBackground ? 'gradientWavy' : undefined}>
      {header?.type === 'actionBar' ? (
        <ActionBar header={header} />
      ) : (
        <Menu
          multiColor={header?.multiColor}
          withoutMenu={header?.withoutMenu}
          logoLinkLeadsToIndex={header?.logoLinkLeadsToIndex}
        />
      )}
    </View.Header>
  )
}

export default Header
