// DS 18/10/2022
// English is disabled until copy is checked
const APP_LANGUAGES = {
  de: {
    id: 'de',
    value: 'de',
    label: 'Deutsch'
  }
  /*
  en: {
    id: 'en',
    value: 'en',
    label: 'English'
  }
  */
}

const APP_LANGUAGES_VALUES = []
for (const k in APP_LANGUAGES) {
  APP_LANGUAGES_VALUES.push(k)
}

const APP_LANGUAGES_OPTIONS = []
for (const k in APP_LANGUAGES) {
  APP_LANGUAGES_OPTIONS.push(APP_LANGUAGES[k])
}

module.exports = {
  APP_LANGUAGES: APP_LANGUAGES,
  APP_LANGUAGES_VALUES: APP_LANGUAGES_VALUES,
  APP_LANGUAGES_OPTIONS: APP_LANGUAGES_OPTIONS
}
