import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectHeader } from '../../../../redux/slices/uiSlice'
const { handleBackButton } = require('../../../../utils/generic')

import { Screen, Section, SectionContent } from '../../generic/View'
import IllustrationBlock from '../../IllustrationBlock'
import SelectContentForm from './SelectContentForm'
import manWithADog from '../../../../assets/illustrations/photo_of_a_man_with_a_dog.svg'

function SelectContentPage(props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, props.backButtonAction)
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('memory.create_new_memory_header'),
    withBackground: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <IllustrationBlock img={manWithADog} />
      <Section>
        <SectionContent>
          <SelectContentForm {...props} />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default SelectContentPage
