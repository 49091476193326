import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../../redux/slices/uiSlice'

import { Col } from '../../../generic/View'
import { ModalMenuItem } from '../../../generic/Elements'

const commentSettings = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { comment, memoryRoomUserId } = props.modalProps
  const isCommentOwner = comment.memoryRoomUserId === memoryRoomUserId

  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }

  return (
    <Col style={{ ...props.style }}>
      {isCommentOwner && (
        <ModalMenuItem
          title={t('general.edit_privacy')}
          onClick={() => onOpenModal('commentPrivacySetting')}
        />
      )}
      <ModalMenuItem
        title={t('general.delete')}
        onClick={() => onOpenModal('commentDelete')}
      />
    </Col>
  )
}

export default commentSettings
