import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError && process.env.NODE_ENV === 'production') {
      this.props.onError()
      this.setState({ hasError: false })
    }

    return this.props.children
  }
}
