import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'

import { setRoomImage } from '../../../../redux/slices/memoryRoomSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'
import { CONTENT_TYPES } from '../../../../constants/contentTypes'
const {
  SUPPORTED_IMAGE_ACCEPT_PROP
} = require('../../../../constants/supportedMediaTypes')

const { isFileValid } = require('../../../../utils/generic')

import { setToast } from '../../../../utils/status'
import { Col } from '../../generic/View'
import * as Button from '../../generic/Button'
import * as Input from '../../generic/Input'

const memoryRoomChangeImage = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { memoryRoomUser } = props.modalProps
  const [fileName, setFileName] = useState(t('general.image_file_limit'))
  const [submitting, setSubmitting] = useState(false)

  const updateRoomImage = async fd => {
    if (fd.file && fd.file.length > 0) {
      setSubmitting(true)
      const formData = new FormData()
      formData.append('file', fd.file[0])

      const result = await dispatch(
        setRoomImage({
          memoryRoomUserId: memoryRoomUser.id,
          formData
        })
      )

      if (result.meta?.requestStatus === 'rejected') {
        if (result.payload !== null) {
          setToast(result.payload.title, 'error')
          setSubmitting(false)
        }
      }
      if (result.meta?.requestStatus === 'fulfilled') {
        dispatch(closeModal())
        setToast(t('memory_room.image_successfully_changed'), 'success')
      }
    } else {
      dispatch(closeModal())
    }
  }

  return (
    <Col style={{ ...props.style }}>
      <Formik
        initialValues={{ file: '' }}
        validate={values => {
          const errors = {}
          if (!values.file) {
            errors.file = t('general.required_field')
          }
          return errors
        }}
        onSubmit={updateRoomImage}>
        {props => (
          <Input.StyledForm>
            <Input.StyledFileUpload
              name="file"
              accept={SUPPORTED_IMAGE_ACCEPT_PROP}
              label={t('memory_room.label.upload_image')}
              filename={fileName}
              labelType={3}
              onChange={event => {
                const curFile = event.currentTarget.files[0]
                if (curFile) {
                  if (isFileValid(curFile, CONTENT_TYPES.IMAGE)) {
                    setFileName(curFile?.name)
                    props.setFieldValue('file', event.currentTarget.files)
                  } else {
                    setToast(
                      t('media.image_upload_error') +
                        ' ' +
                        t('media.image_info'),
                      'error'
                    )
                  }
                }
              }}
            />
            <Input.StyledErrorMessage name="file" component="div" />
            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.save')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
    </Col>
  )
}

export default memoryRoomChangeImage
