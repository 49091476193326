import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import { selectUser } from '../../../redux/slices/userSlice'

import { Row, InformationBox } from './View'
import * as Button from './Button'

function Footer() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const user = useSelector(selectUser)
  const size = useContext(ResponsiveContext)

  // display footer on unauth pages or on pages listed in the array below
  const pagesWithFooter = ['/', '/learning', '/imprint', '/privacy']
  let displayFooter = !user || pagesWithFooter.indexOf(pathname) >= 0

  if (!displayFooter) return null

  const isDesktop = size === 'large'
  return (
    <footer>
      <InformationBox>
        <Row pad={isDesktop ? '' : '0 0 30px 0'}>
          <Button.Link to="/imprint" style={{ marginRight: '30px' }}>
            {t('general.imprint')}
          </Button.Link>
          <Button.Link to="/privacy">{t('general.privacy_policy')}</Button.Link>
        </Row>
      </InformationBox>
    </footer>
  )
}

export default Footer
