import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { stringify } from 'qs'

import { selectHeader } from '../../../redux/slices/uiSlice'

import { setToast } from '../../../utils/status'
import ajax from '../../../utils/ajax'
import { handleBackButton, useQuery } from '../../../utils/generic'

import PreparedHelmet from '../../components/seo/PreparedHelmet'
import PageWithLinks from '../../components/learningRoom/PageWithLinks'

const { REACT_APP_STRAPI_API_URL } = process.env

function Topic() {
  let { topicId } = useParams()

  const [data, setData] = useState([])
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const headerRedux = useSelector(selectHeader)
  const query = useQuery()

  const isPreview = query.has('preview')

  topicId = parseInt(topicId, 10)

  const filters = isPreview
    ? undefined
    : {
        publishedAt: {
          $null: false
        }
      }

  const fetchParams = stringify(
    {
      publicationState: 'live',
      fields: ['title', 'longDescription', 'shortDescription'],
      populate: {
        heroImage: {
          fields: ['url']
        },
        exercises: {
          publicationState: 'live',
          fields: ['title', 'shortDescription'],
          populate: {
            previewImage: {
              fields: ['url']
            }
          },
          filters
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  const fetchData = async () => {
    try {
      const response = await ajax({
        url: `${REACT_APP_STRAPI_API_URL}/topics/${topicId}?${fetchParams}`,
        method: 'GET',
        isPublicRequest: true
      })

      if (response?.data?.attributes) {
        setData(response?.data?.attributes)
      } else {
        setToast(t('general.errors.general'), 'error')
      }
    } catch (err) {
      setToast(err, 'error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  // handle action on back button
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () => navigate(`/learning`))
  }, [headerRedux.backCustomActionTriggered])

  const topicHeading = data?.title || ''
  const header = {
    type: 'actionBar',
    title: topicHeading,
    withBackground: true,
    backCustomActionTriggered: false
  }

  const heroData = {
    imgAlt: topicHeading,
    imgSrc: data?.heroImage?.data?.attributes?.url,
    heading: topicHeading,
    description: data?.longDescription
  }

  const metaTitle = `${topicHeading} – ${t('landing.heading_subheading')}`
  const metaDescription = data?.shortDescription || t('landing.text')

  return (
    <>
      <PreparedHelmet
        title={metaTitle}
        description={metaDescription}
        titleHasAppName={true}
      />

      <PageWithLinks
        header={header}
        hero={heroData}
        linkItems={data?.exercises?.data}
        baseLink={`learning/${topicId}/exercise`}
        linkButtonText={t('learning_room.topic_page.item_button')}
        isLinkImageSmall={true}
      />
    </>
  )
}

export default Topic
