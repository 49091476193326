import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'

import viaTheme from '../../../../styles/via-theme.json'

import { Text, ParagraphSmall } from '../../generic/Text'

const CarouselText = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 16px;
  overflow: hidden;
  width: fit-content;
`

const prepareExerciseData = data => {
  return data.map(el => {
    const exerciseId = el.exercise.data.id
    const topicId =
      el.exercise.data.attributes?.topics?.data?.length > 0
        ? el.exercise.data.attributes.topics.data[0].id
        : undefined

    let link
    if (topicId) {
      link = `/learning/${topicId}/exercise/${exerciseId}`
    }
    return {
      id: exerciseId,
      heading: el.exercise.data.attributes.title,
      image: el.exercise.data.attributes.image,
      link
    }
  })
}

const SlideWrapper = ({ link, children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    height: '100%',
    padding: '16px',
    width: '100%'
  }

  if (link) {
    const internalLink = /^\/learning/.test(link)
    const externalLink = !internalLink

    if (internalLink) {
      return (
        <Link style={style} to={link}>
          {children}
        </Link>
      )
    }

    if (externalLink) {
      return (
        <a style={style} href={link} target="_self">
          {children}
        </a>
      )
    }
  }

  return <div style={style}>{children}</div>
}

const SwipeableCarousel = ({ component, isExerciseCarousel }) => {
  const size = useContext(ResponsiveContext)

  let data
  if (isExerciseCarousel) {
    data = component?.links || []
    data = prepareExerciseData(data)
  } else {
    data = component?.cards || []
  }

  const slideWidth = size === 'large' ? '426px' : '336px'
  const slideHeight = size === 'large' ? '200px' : '144px'
  const swiperPadding = size === 'small' ? 8 : 16

  return (
    <>
      <Swiper
        key={component.id}
        preventClicks={true}
        slidesPerView="auto"
        spaceBetween={swiperPadding}
        style={{
          overflow: 'visible',
          paddingBottom: swiperPadding,
          width: '100%'
        }}>
        {data.map(slide => {
          let backgroundProps

          // get image
          const image = slide?.image
          let imageSrc
          if (image) {
            imageSrc = image?.data?.attributes?.url
          }

          if (imageSrc) {
            backgroundProps = {
              backgroundColor: '#ECEEFA',
              backgroundImage: `url(${imageSrc})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
            }
          } else {
            backgroundProps = {
              backgroundColor: viaTheme.global.colors.lightBlueGrey
            }
          }

          const hasText = slide.heading || slide.description
          const hasBothTexts = slide.heading && slide.description

          let heading
          if (slide.heading) {
            if (isExerciseCarousel) {
              heading = (
                <ParagraphSmall bold style={{ margin: 0 }}>
                  {slide.heading}
                </ParagraphSmall>
              )
            } else {
              heading = (
                <Text
                  semiBold
                  style={{ margin: hasBothTexts ? '0 0 4px 0' : 0 }}>
                  {slide.heading}
                </Text>
              )
            }
          }

          return (
            <SwiperSlide
              key={slide.id}
              style={{
                ...backgroundProps,
                borderRadius: '8px',
                height: slideHeight,
                width: slideWidth
              }}>
              {hasText && (
                <SlideWrapper link={slide.link}>
                  <CarouselText>
                    {heading}
                    {slide.description && (
                      <Text style={{ margin: 0 }}>{slide.description}</Text>
                    )}
                  </CarouselText>
                </SlideWrapper>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default SwipeableCarousel
