import { Box, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { openModal } from '../../../redux/slices/uiSlice'
import { ParagraphSmall } from '../generic/Text'

export const Feedback = () => {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const { pathname } = useLocation()

  const isDesktop = size === 'large'

  // On some pages we should disable feedback button
  const regExpDisabledPages = /^\/(imprint|privacy)$/
  let disableFeedback = regExpDisabledPages.test(pathname)
  if (disableFeedback) return null

  // On memory room page, we should position feedback button
  // on the right side on mobile devices
  const regExpRoom = /^\/rooms\/(\d+)$/
  const rightOnMobile = regExpRoom.test(pathname)

  const dispatch = useDispatch()
  const onOpenFeedbackModal = () => {
    dispatch(
      openModal({
        type: 'feedback',
        props: {}
      })
    )
  }

  return (
    <FeedbackButton
      onClick={() => onOpenFeedbackModal()}
      mobile={!isDesktop}
      rightOnMobile={rightOnMobile}>
      <ParagraphSmall white style={{ margin: '10px' }}>
        {' '}
        {t('general.feedback')}
      </ParagraphSmall>
    </FeedbackButton>
  )
}

const FeedbackButton = styled.div`
  z-index: 2;
  width: ${props => (props.mobile ? '100px' : '138px')};
  height: ${props => (props.mobile ? '34px' : '46.8px')};
  transform: ${props =>
    props.mobile && !props.rightOnMobile ? 'rotate(0deg)' : 'rotate(270deg)'};
  position: fixed;
  background-color: #f07b7b;
  top: ${props => (props.mobile && !props.rightOnMobile ? '' : '140px')};
  margin-left: ${props => (props.mobile ? '' : '-46px')};
  bottom: ${props => (props.mobile ? '0px' : '')};
  right: ${props => {
    if (props.rightOnMobile && props.mobile) return '-33px'
    if (props.mobile) return '5px'
    return ''
  }};
  padding: ${props => (props.mobile ? '' : '4px 0 0 0')};
  text-align: center;
  border-radius: ${props => (props.mobile ? '10px 10px 0 0' : '0 0 10px 10px')};
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
`

const StyledCircle = styled.div`
  height: 10px;
  width: 10px;
  margin: 10px 5px -25px 5px;
  border-radius: 50%;
  border: 2px solid #c5cfd6;
  box-sizing: border-box;
  background-color: ${props => (props.hasColor ? '#c5cfd6' : '#fff')};
`
export const Circles = props => {
  return (
    <Box justify="center" align="center" direction="row">
      <StyledCircle hasColor={props.firstPage} />
      <StyledCircle hasColor={props.secondPage} />
      <StyledCircle hasColor={props.thirdPage} />
    </Box>
  )
}
