import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const ROOT_URL = process.env.REACT_APP_ROOT_URL

function PreparedHelmet({ title, titleHasAppName, description }) {
  const { t } = useTranslation()

  description = description || t('landing.text')

  if (titleHasAppName) {
    const appHeading = t('landing.heading_subheading')
    title = `${title} – ${appHeading}`
  }

  const metaImage = `${ROOT_URL}/images/logo-social.jpg`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Meta tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={metaImage} />

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@n3xtcoder" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  )
}

export default PreparedHelmet
