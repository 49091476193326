import React, { useEffect, useRef } from 'react'
import { Box } from 'grommet'

import * as Input from '../../generic/Input'
import * as Button from '../../generic/Button'
import { useTranslation } from 'react-i18next'
import { Circles } from '../Elements'
import iconAngleLeft from '../../../../assets/icons/icon_angle_left.svg'

const TextModal = ({ form, setCurrentStep, pageSteps }) => {
  const { t } = useTranslation()

  const modalTop = useRef(null)
  const scrollToTop = () => {
    modalTop.current.scrollIntoView({ behavior: 'instant' })
  }
  useEffect(scrollToTop, [])

  return (
    <Box>
      <div
        style={{
          width: '20px',
          height: '29px',
          position: 'relative',
          top: '-16px',
          textAlign: 'left',
          background: `no-repeat url(${iconAngleLeft})`,
          cursor: 'pointer'
        }}
        tabIndex="0"
        onKeyDown={e => {
          if (e.code === 'Space' || e.code === 'Enter') {
            e.preventDefault()
            setCurrentStep(pageSteps.FEELING)
          }
        }}
        role="button"
        onClick={() => setCurrentStep(pageSteps.FEELING)}
        ref={modalTop}
      />
      <Input.StyledForm>
        <Input.StyledTextArea
          type="text"
          name="message"
          testid="input-message"
          label={t('feedback_modal.tell_us')}
          labelType={3}
        />
        <Input.StyledErrorMessage name="message" component="div" />
        <Circles secondPage />
        <Button.Primary
          type="submit"
          flex={false}
          data-testid="input-submit"
          label={t('general.next')}
          disabled={!form.values.message || form.errors.message}
          onClick={() => setCurrentStep(pageSteps.FILE_UPLOAD)}
        />
      </Input.StyledForm>
    </Box>
  )
}

export default TextModal
