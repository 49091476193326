import React from 'react'
import styled from 'styled-components'
import { Button, Box } from 'grommet'
import { Link as ReactLink } from 'react-router-dom'

import * as Icon from './Icon'
import loader from '../../../assets/icons/loader.svg'

const BasicStyle = styled(Button)`
  ${({ adjustToSize }) =>
    adjustToSize && adjustToSize !== 'large'
      ? `min-width: 100px; 
      `
      : ` min-width: 180px; 
  `}
  min-height: 50px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.125rem;
  box-shadow: 0px 4px 8px 0px #00000029;
  border-radius: 100px;
  position: relative;
  letter-spacing: 1px;
  margin: 0;

  ${({ secondary }) =>
    secondary &&
    ` font-size:0.875rem;
      box-shadow: none;
      text-decoration: underline;
      min-width: 0;
  `}
  ${({ filter }) =>
    filter &&
    ` padding: 0.5rem 1rem;
      min-width: 0px;
  `}
  ${({ set }) =>
    set &&
    ` background:#FFAA00 ;
      color: white;
  `} /* styling for all buttons can be applied here */
`
// set styling as much as possible on Grommet component, NOT on styled(Component)
// so the defaults can easily be overruled
const Basic = props => <BasicStyle alignSelf="center" {...props} />

export const Primary = props => (
  <Basic
    primary
    {...props}
    size="medium"
    style={{
      padding: props.pad ? props.pad : '1rem 1.5rem',
      margin: props.margin ? props.margin : '2.5rem 1rem 2rem 1rem',
      ...props.style
    }}
    label={
      props.disabled && props.spinnerOnDisabled ? (
        <>
          {props.label}
          <img
            src={loader}
            style={{
              position: 'absolute',
              bottom: '50%',
              right: '50%',
              transform: 'translate(50%, 50%)'
            }}
          />
        </>
      ) : (
        props.label
      )
    }
  />
)

export const LinkButton = props => {
  return (
    <Box
      alignSelf={props.alignSelf ? props.alignSelf : 'center'}
      onClick={props.onClick}>
      <Basic
        {...props}
        size="medium"
        plain
        color={props.color || 'turqoise'}
        secondary
      />
    </Box>
  )
}

export const Link = styled(ReactLink)`
  color: ${props =>
    props.color
      ? props.theme.global.colors[props.color]
      : props.theme.global.colors.turqoise};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 11px 0;
`

export const WhiteStyle = styled(Basic)`
  background: ${props => props.theme.global.colors.white};
  color: ${props =>
    props.grey
      ? props.theme.global.colors.darkBlueGrey
      : props.theme.global.colors.turqoise};
  fill: ${props => props.theme.global.colors.black};
  stroke: none;
  padding: ${props => {
    if (props.pad) return props.pad
    if (props.small) return '0.5rem 1rem'
    return '1rem 1.5rem'
  }};
  font-size: ${props => (props.small ? '0.75rem' : '1rem')};
  margin: ${props => props.margin || 'auto'};
  min-height: ${props => (props.small ? '40px' : '50px')};
  border-width: 2px;
  border-style: solid;
  border-color: ${props =>
    props.grey ? '#7C92A2;' : props.theme.global.colors.turqoise};
  display: ${props => (props.flex ? 'flex' : 'inline-block')};
  align-items: center;
  justify-content: center;
}
`

export const White = props => <WhiteStyle {...props} />

export const MemoryRoom = styled(Basic)`
  background: ${props => props.theme.global.colors.lightBlueGrey};
  stroke: none;
  backdrop-filter: blur(53px);
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem;
  min-width: 100%;
  display: flex;
  align-items: center;
`

const CloseContainer = styled(Box)`
  border-radius: 50%;
`
export const Close = props => (
  <CloseContainer
    align="center"
    justify="center"
    background="brand"
    width="40px"
    height="40px"
    {...props}>
    <Icon.CrossWhite />
  </CloseContainer>
)

export const Filter = props => (
  <Basic
    //options: brightPink, darkBlueGrey, sapphire
    color={props.background ? props.background : 'orangeYellow'}
    filter="true"
    style={{ margin: '0', padding: '0.25rem 1.125rem', minHeight: '32px' }}
    {...props}
  />
)
