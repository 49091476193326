import React from 'react'

import * as Button from '../../generic/Button'
import { Row } from '../../generic/View'

const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
}

const ButtonLR = ({ component }) => {
  return (
    <Row justify="center">
      <a href={component.link}>
        {component.type === BUTTON_TYPE.PRIMARY && (
          <Button.Primary
            adjustToSize
            flex={false}
            label={component.label}
            margin="0 0 10px 0"
            type="button"
          />
        )}

        {component.type === BUTTON_TYPE.SECONDARY && (
          <Button.White
            adjustToSize
            style={{ marginBottom: '10px' }}
            type="button"
            label={component.label}
          />
        )}
      </a>
    </Row>
  )
}

export default ButtonLR
