const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
require('dayjs/locale/de')
require('dayjs/locale/en')
dayjs.extend(relativeTime)

import { setToast } from '../utils/status'
import i18n from '../i18n'
import { CONTENT_TYPES } from '../constants/contentTypes'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
const {
  SUPPORTED_AUDIO_TYPES,
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_VIDEO_TYPES,
  SUPPORTED_DOCUMENT_TYPES
} = require('../constants/supportedMediaTypes')

export function isPasswordValid(password) {
  // min 7 chars, at least: 1 special + 1 number
  const regExp =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,^()_=+\-"'/])[A-Za-z\d@$!%*#?&.,^()_=+\-"'/]{7,}$/

  return regExp.test(password)
}

// prepare time for displaying in UI. How many minutes/hours/days ago
export function prepareDateTimeAgo(dateStr) {
  const language = i18n.language
  let timeTo = dayjs().locale(language).to(dayjs(dateStr))
  return timeTo
}

export function prepareDate(fullDateStr, displayMonth, displayDay) {
  let options = { year: 'numeric' }
  if (displayMonth) {
    options.month = 'long'
  }
  if (displayDay) {
    options.day = 'numeric'
  }

  return new Date(fullDateStr).toLocaleDateString(i18n.language, options)
}

export function isFileValid(file, type) {
  let fileError = !file
  let typeError = false
  let sizeError = false

  // file type validation
  if (!fileError) {
    let supportedTypes
    switch (type) {
      case CONTENT_TYPES.AUDIO:
        supportedTypes = SUPPORTED_AUDIO_TYPES
        break
      case CONTENT_TYPES.CAMERA:
      case CONTENT_TYPES.GALLERY:
        supportedTypes = [
          ...SUPPORTED_IMAGE_TYPES,
          ...SUPPORTED_VIDEO_TYPES,
          ...SUPPORTED_AUDIO_TYPES
        ]
        break
      case CONTENT_TYPES.IMAGE:
        supportedTypes = SUPPORTED_IMAGE_TYPES
        break
      case CONTENT_TYPES.DOCUMENT:
        supportedTypes = SUPPORTED_DOCUMENT_TYPES
        break
      default:
        typeError = true
    }

    if (!typeError && !supportedTypes.includes(file.type)) {
      typeError = true
    }

    if (!typeError) {
      // file size validation
      const fileMaxSize = 157286400 // 150mb
      const imageMaxSize = 10485760 // 10mb
      const documentMaxSize = 10485760 // 10mb

      const exactType = getExactFileType(file, type)

      if (exactType === CONTENT_TYPES.IMAGE) {
        if (file.size > imageMaxSize) {
          sizeError = true
        }
      } else if (exactType === CONTENT_TYPES.DOCUMENT) {
        if (file.size > documentMaxSize) {
          sizeError = true
        }
      } else {
        if (file.size > fileMaxSize) {
          sizeError = true
        }
      }
    }
  }

  return !fileError && !typeError && !sizeError
}

function getExactFileType(file, type) {
  if (type === CONTENT_TYPES.CAMERA || type === CONTENT_TYPES.GALLERY) {
    if (SUPPORTED_IMAGE_TYPES.includes(file.type)) {
      return CONTENT_TYPES.IMAGE
    } else if (SUPPORTED_VIDEO_TYPES.includes(file.type)) {
      return CONTENT_TYPES.VIDEO
    }
  }

  return type
}

export function getAllowedFiles(files, type) {
  let allowedFiles = []
  let failedFiles = []

  for (let file of files) {
    if (isFileValid(file, type)) {
      allowedFiles.push(file)
    } else {
      failedFiles.push(file.name)
    }
  }

  const failedFilesCount = failedFiles.length
  if (failedFilesCount > 0) {
    // show error toast with files that are not uploaded
    const failedFileNames = failedFiles.join(', ')
    setToast(
      i18n.t('media.files_not_uploaded', {
        files: failedFileNames,
        count: failedFilesCount
      }),
      'error'
    )

    // show information toast for current file type
    let infoMsg
    if (type === CONTENT_TYPES.AUDIO) {
      infoMsg = i18n.t('media.audio_info')
    } else if (type === CONTENT_TYPES.CAMERA) {
      infoMsg = i18n.t('media.image_info') + i18n.t('media.video_info')
    } else if (type === CONTENT_TYPES.GALLERY) {
      infoMsg =
        i18n.t('media.image_info') +
        i18n.t('media.video_info') +
        i18n.t('media.audio_info')
    } else if (type === CONTENT_TYPES.DOCUMENT) {
      infoMsg = i18n.t('media.document_info')
    } else {
      infoMsg = i18n.t('general.something_wrong')
    }
    setToast(infoMsg, 'info')
  }

  return allowedFiles
}

/**
 * @param {Object} queryParamsObject of type { queryParamKey: queryParamValue } - queryParamValue can be undefined
 * @returns encoded query string including initial '?'
 */
export const createQueryString = queryParamsObject => {
  const queryParams = new URLSearchParams()

  const filteredQueryParamsArray = Object.entries(queryParamsObject).filter(
    ([, value]) => value
  )

  filteredQueryParamsArray.forEach(([key, value]) =>
    queryParams.append(key, value)
  )

  if (filteredQueryParamsArray.length === 0) {
    return ''
  }

  return `?${queryParams.toString()}`
}

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const handleBackButton = (headerRedux, currentPath, action) => {
  if (headerRedux.backCustomActionTriggered) {
    const { triggered, pathname: actionPathname } =
      headerRedux.backCustomActionTriggered

    if (triggered && currentPath === actionPathname) {
      action()
    }
  }
}

export const getMemoryDate = ({ memoryYear, memoryMonth, memoryDay }) => {
  let month = memoryMonth || '01'
  month = month.toString()
  if (month.length === 1) {
    month = `0${month}`
  }

  let day = memoryDay || '01'
  day = day.toString()
  if (day.length === 1) {
    day = `0${day}`
  }

  const date = `${memoryYear}-${month}-${day}`
  return new Date(date)
}
