import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import { Screen, Section, InformationBox } from '../components/generic/View'
import { Heading1, ParagraphXXL } from '../components/generic/Text'

import { ReactComponent as Illustration404 } from '../../assets/illustrations/man_woman_lost.svg'

const Login = () => {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isMobile = size === 'small'

  const header = {
    type: 'menu',
    withBackground: true,
    withoutMenu: true,
    logoLinkLeadsToIndex: true
  }

  let illustrationStyles
  if (isMobile) {
    illustrationStyles = {
      height: '100%',
      margin: '23px 0 64px 0',
      maxHeight: '227px',
      width: 'auto'
    }
  }

  if (!isMobile) {
    illustrationStyles = {
      height: '315px',
      margin: '28px 0 64px 0',
      width: 'auto'
    }
  }

  return (
    <Screen header={header}>
      <Section columnItem textAlign="left">
        <InformationBox maxWidth="928" style={{ textAlign: 'center' }}>
          <ParagraphXXL>404</ParagraphXXL>
          <Illustration404 style={illustrationStyles} />
          <Heading1>{t('404.cant_find')}</Heading1>
          <Heading1>{t('404.try_again')}</Heading1>
        </InformationBox>
      </Section>
    </Screen>
  )
}

export default Login
