import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import {
  setQuestionId,
  selectQuestionId,
  selectTemporaryData,
  openModal
} from '../../../../redux/slices/uiSlice'

import { CONTENT_TYPES } from '../../../../constants/contentTypes'
const { PRIVACY_SETTINGS } = require('../../../../constants/privacySettings')

import {
  QUESTIONS,
  QUESTION_IDS
} from '../../../../constants/inspireMeQuestions'

import * as Input from '../../generic/Input'
import * as Button from '../../generic/Button'
import { Paragraph } from '../../generic/Text'

function ContentInspireMeForm(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [description, setDescription] = useState(false)

  const tempData = useSelector(selectTemporaryData)
  const privacySetting = tempData?.privacy || PRIVACY_SETTINGS.PUBLIC

  // we store the current questionId in redux, so it's stored
  // even after the user leaves this page
  const questionId = useSelector(selectQuestionId)
  const handleQuestionToggle = () => {
    if (QUESTION_IDS.includes((questionId + 1).toString())) {
      dispatch(setQuestionId(questionId + 1))
    } else {
      dispatch(setQuestionId(0))
    }
  }

  // form submit function
  const handleSubmit = formObject => {
    // open privacy setting modal when choose individually was selected previously
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      setDescription(formObject.description)
      dispatch(
        openModal({
          type: 'memoryPrivacySetting',
          withoutCloseButton: true
        })
      )
    } else {
      // submit if privacy setting is set
      submitData(formObject.description)
    }
  }

  // submitData should be triggered after choose individually privacy modal is shown
  useEffect(() => {
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      if (tempData.key && tempData.contentPrivacy && description) {
        submitData(description)
        setDescription(undefined)
      }
    }
  }, [tempData?.key])

  // adding text to content array and changing form step back to content overview
  const submitData = text => {
    if (text) {
      text = text.trim()
      if (text.length > 0) {
        const type = CONTENT_TYPES.INSPIRE_ME
        const contentPrivacy =
          privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY
            ? tempData.contentPrivacy
            : privacySetting

        let contentIsPrivate = contentPrivacy === PRIVACY_SETTINGS.PRIVATE

        props.setContentCountFunc({
          ...props.contentCountObject,
          [type]: props.contentCountObject[type] + 1
        })

        props.setArrayFunc(arr => {
          return [
            ...arr,
            {
              type: CONTENT_TYPES.INSPIRE_ME,
              description: text,
              questionId: questionId,
              private: contentIsPrivate
            }
          ]
        })
      }
    }

    props.setContentStep()
  }

  return (
    <Formik
      initialValues={{ description: '' }}
      validate={values => {
        const errors = {},
          descriptionLimit = 2000

        if (!values.description.trim()) {
          errors.description = t('general.required_field')
        } else if (values.description.trim().length > descriptionLimit) {
          errors.description = t('general.errors.text_too_long', {
            limit: descriptionLimit,
            length: values.description.trim().length
          })
        }

        return errors
      }}
      onSubmit={handleSubmit}>
      {form => {
        return (
          <Input.StyledForm narrow="true">
            <Paragraph textAlign="left">
              {t(QUESTIONS[questionId]?.text, {
                belovedName: props.memoryRoom.belovedName
              })}
            </Paragraph>
            <Button.White
              alignSelf="end"
              style={{ marginBottom: '30px' }}
              small
              onClick={() => {
                form.values.description = ''
                handleQuestionToggle()
              }}>
              {t('inspire_me.next_question')}
            </Button.White>
            <Input.StyledTextArea
              type="text"
              name="description"
              testid="input-description"
              style={{ height: '144px' }}
            />
            <Input.StyledErrorMessage name="description" component="div" />

            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.add')}
              disabled={!form.values.description || form.errors.description}
            />
          </Input.StyledForm>
        )
      }}
    </Formik>
  )
}

export default ContentInspireMeForm
