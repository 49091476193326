import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { setToast } from '../../../utils/status'
import { isFileValid } from '../../../utils/generic'

import { selectUser, sendMessageToAdmin } from '../../../redux/slices/userSlice'

import { FEEDBACK_TYPES } from '../../../constants/feedbackType'
import { SUPPORTED_IMAGE_ACCEPT_PROP } from '../../../constants/supportedMediaTypes'
import { CONTENT_TYPES } from '../../../constants/contentTypes'

import * as Input from '../generic/Input'
import * as Button from '../generic/Button'

const ContactUsForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelector(selectUser)

  const [submitting, setSubmitting] = useState(false)
  const [fileName, setFileName] = useState(t('general.image_file_limit'))

  const handleSubmit = async fd => {
    setSubmitting(true)

    const formData = new FormData()
    formData.append('type', FEEDBACK_TYPES.TECHNICAL_SUPPORT)
    formData.append('message', fd.message)

    if (fd.file && fd.file.length > 0) {
      formData.append('file', fd.file[0])
    }

    const result = await dispatch(
      sendMessageToAdmin({
        userId: user.id,
        formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      navigate(`/profile`)
      setToast(t('contact_us.successfully_sent'), 'success')
    }
  }

  return (
    <Formik
      initialValues={{ message: '', file: '' }}
      validate={values => {
        const errors = {},
          messageLimit = 60_000

        if (!values.message.trim()) {
          errors.message = t('general.required_field')
        } else if (values.message.trim().length > messageLimit) {
          errors.message = t('general.errors.text_too_long', {
            limit: messageLimit,
            length: values.message.trim().length
          })
        }

        return errors
      }}
      onSubmit={handleSubmit}>
      {props => (
        <Input.StyledForm>
          <Input.StyledTextArea
            type="text"
            name="message"
            testid="input-message"
            label={t('contact_us.label')}
            labelType={3}
          />
          <Input.StyledErrorMessage name="message" component="div" />
          <Input.StyledFileUpload
            name="file"
            accept={SUPPORTED_IMAGE_ACCEPT_PROP}
            label={t('feedback_modal.file_upload')}
            labelType={3}
            filename={fileName}
            onChange={event => {
              const curFile = event.currentTarget.files[0]
              if (curFile) {
                if (isFileValid(curFile, CONTENT_TYPES.IMAGE)) {
                  setFileName(curFile?.name)
                  props.setFieldValue('file', event.currentTarget.files)
                } else {
                  setToast(
                    t('media.image_upload_error') + ' ' + t('media.image_info'),
                    'error'
                  )
                }
              }
            }}
          />
          <Button.Primary
            type="submit"
            flex={false}
            data-testid="input-submit"
            label={t('general.send')}
            disabled={submitting}
            spinnerOnDisabled={true}
          />
        </Input.StyledForm>
      )}
    </Formik>
  )
}

export default ContactUsForm
