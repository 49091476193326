import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectMemoryById } from '../../redux/slices/memorySlice'
import { update } from '../../redux/slices/memorySlice'
import { selectMemoryRoomById } from '../../redux/slices/memoryRoomSlice'
import { selectUser } from '../../redux/slices/userSlice'

import { setToast } from '../../utils/status'
import NewMemoryPage from '../components/memory/NewMemoryPage'

const { ROLES } = require('../../constants/memoryRoomUserRoles')

function MemoryEdit() {
  let { roomId, memoryId } = useParams()
  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )
  if (!memoryRoom) {
    return <Navigate to="/home" replace />
  }

  memoryId = parseInt(memoryId, 10)
  const memory = useSelector(state => selectMemoryById(state, memoryId))

  const roomLink = `/rooms/${memoryRoomId}`
  if (!memory) {
    return <Navigate to={roomLink} replace />
  }

  const user = useSelector(selectUser)
  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )
  const isMemoryRoomOwner = memoryRoomUser.role === ROLES.OWNER
  const isMemoryOwner = memory.memoryRoomUserId === memoryRoomUser.id

  if (!isMemoryRoomOwner && !isMemoryOwner) {
    return <Navigate to={roomLink} replace />
  }

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  const editMemory = async formObject => {
    setSubmitting(true)

    // removing private prop as it should not be updated here
    delete formObject.private

    const result = await dispatch(
      update({
        memoryId,
        memoryRoomUserId: memoryRoomUser.id,
        data: formObject
      })
    )
    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }

    if (result.meta?.requestStatus === 'fulfilled') {
      navigate(`/rooms/${memoryRoomId}/memory/${memoryId}`)
      setToast(t('memory.memory_was_edited'), 'success')
    }
  }

  return (
    <NewMemoryPage
      submitAction={editMemory}
      initObject={memory}
      isEdit={true}
      pageTitleKey={t('memory.edit_memory_header')}
      submitting={submitting}
      backButtonAction={() =>
        navigate(`/rooms/${memoryRoomId}/memory/${memoryId}`)
      }
    />
  )
}

export default MemoryEdit
