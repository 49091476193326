import React, { useContext, useState } from 'react'
import ReactCardFlip from 'react-card-flip'
import styled from 'styled-components'
import { Box, Button, ResponsiveContext } from 'grommet'

import { Heading2 } from '../../generic/Text'
import Markdown from '../../generic/Markdown'

import { ReactComponent as IconArrows } from '../../../../assets/icons/icon_arrows_circle.svg'

const CardContainer = styled.div`
  background-color: ${props => (props.isFrontSide ? '#ffffff' : '#ECEEFA')};
  box-shadow: 0px 2px 6px -2px rgba(0, 26, 25, 0.05),
    0px 10px 15px -3px rgba(0, 26, 25, 0.1);
  border-radius: 8px;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  display: flex;
  flex-direction: column;
  height: 400px;
  margin: ${props => {
    if (props.size === 'large') {
      return props.isEven ? '0 10px 20px 0' : '0 0px 20px 10px'
    } else {
      return '0 0 16px 0'
    }
  }};
  overflow: hidden;
  padding: ${props => {
    if (props.isFrontSide) return '0'
    return props.size === 'large' ? '32px' : '24px'
  }};
  position: relative;
`

const CardImage = styled.img`
  height: 313px;
  object-fit: cover;
  width: 100%;
`

const CardButton = styled(Button)`
  align-items: center;
  align-self: initial;
  background: #00b3ae;
  box-shadow: 0px 2px 6px -2px rgba(0, 26, 25, 0.05),
    0px 10px 15px -3px rgba(0, 26, 25, 0.1);
  border-radius: 100px;
  display: flex;
  height: 40px;
  justify-content: center;
  left: calc(50% - 40px);
  min-height: 40px;
  position: absolute;
  top: 293px;
  width: 80px;
`
const FlipCard = ({ card, isEven }) => {
  const size = useContext(ResponsiveContext)
  const [isFlipped, setIsFlipped] = useState(false)

  const buttonClick = () => {
    setIsFlipped(!isFlipped)
  }

  const parentWidht = size === 'large' ? '50%' : '100%'
  const parentStyle = { flex: parentWidht, maxWidth: parentWidht }

  return (
    <ReactCardFlip isFlipped={isFlipped} containerStyle={parentStyle}>
      {/* Front Side */}
      <CardContainer isFrontSide size={size} isEven={isEven}>
        <CardImage
          alt={card.heading}
          size={size}
          src={card?.image?.data?.attributes?.url}
        />
        <Box align="center" justify="center" flex="grow">
          {card.heading && <Heading2>{card.heading}</Heading2>}
        </Box>
        <CardButton onClick={buttonClick} size={size}>
          <IconArrows />
        </CardButton>
      </CardContainer>

      {/* Back Side */}
      <CardContainer
        size={size}
        isEven={isEven}
        onClick={buttonClick}
        clickable={true}>
        {card.heading2 && (
          <Heading2 style={{ textAlign: 'center', marginBottom: '16px' }}>
            {card.heading2}
          </Heading2>
        )}
        <div style={{ margin: 0, flex: 1 }} className="flipcard">
          <Markdown>{card.description}</Markdown>
        </div>
      </CardContainer>
    </ReactCardFlip>
  )
}
const FlipCards = ({ component }) => {
  const cards = component?.flipCards
  if (!cards) return null

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {cards.map((card, index) => (
        <FlipCard key={index} card={card} isEven={index % 2 === 0} />
      ))}
    </div>
  )
}

export default FlipCards
