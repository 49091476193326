import React from 'react'

import Markdown from '../../generic/Markdown'

const TextBlock = ({ component }) => {
  if (!component?.text) return null

  return (
    <Markdown isCentered={component?.isCentered}>{component.text}</Markdown>
  )
}

export default TextBlock
