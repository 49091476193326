import React, { useContext } from 'react'
import styled from 'styled-components'
import { Box, Button, ResponsiveContext } from 'grommet'
import { useTranslation } from 'react-i18next'

import { prepareDateTimeAgo } from '../../../utils/generic'
import viaTheme from '../../../styles/via-theme.json'
import defaultAvatar from '../../../assets/img/default_avatar.svg'

import { Col, Row, SectionContent } from './View'
import { Paragraph, ParagraphSmall, Text, TextSmall } from './Text'
import * as Icon from './Icon'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

// Thematic break component like <hr /> tag in HTML.
export const ThematicBreak = styled.div`
  width: 100%;
  height: 0.25rem;
  background-color: ${viaTheme.global.colors.lightGrey};
  margin: 1.25rem 0;
`

// InfoBlock is used for displaying memory content, later might be used for comments.
export const InfoBlock = props => (
  <Col
    background="white"
    width="100%"
    pad={{
      horizontal: 'none',
      vertical: 'none'
    }}
    border={[
      {
        color: '#C5CFD6',
        size: '0.75rem',
        side: 'bottom'
      }
    ]}
    {...props}
  />
)

export const UserIcon = ({ user, height }) => {
  let userAvatar,
    userAvatarStyle = {},
    userAvatarSize

  if (user?.assets?.length > 0) {
    userAvatar = user.assets[0].fileUrl
    userAvatarSize = height || 48
  } else {
    userAvatar = defaultAvatar
    userAvatarStyle['filter'] = 'invert(1)'
    userAvatarSize = 37
  }

  return (
    <img
      src={userAvatar}
      alt="user avatar"
      height={userAvatarSize}
      width={userAvatarSize}
      style={{
        borderRadius: '100%',
        objectFit: 'cover',
        marginRight: '15px',
        ...userAvatarStyle
      }}
    />
  )
}

// AuthorInfo is used for displaying author of a memory content, later might be used for comments and notifications.
export const AuthorInfo = ({
  user,
  date,
  displayMenu,
  onMenuClick,
  menuClassName
}) => {
  const { t } = useTranslation()

  const size = useContext(ResponsiveContext)

  const timeAgo = prepareDateTimeAgo(date)

  return (
    <Row
      height={{
        min: '64px'
      }}
      align="center"
      pad={{
        horizontal:
          size === 'small' ? '14px' : size === 'medium' ? '28px' : '25px',
        vertical: '11px'
      }}
      background={viaTheme.global.colors.lightGrey}>
      <UserIcon user={user} />
      <Col>
        <ParagraphSmall style={{ margin: '0' }} lineHeight="1.5rem" bold>
          {user?.displayName || t('memory.deleted_user')}
        </ParagraphSmall>
        <Text style={{ margin: '0' }} color="#475662">
          {timeAgo}
        </Text>
      </Col>
      {!isAppSunsettingMode && displayMenu && (
        <Button
          onClick={onMenuClick}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <Icon.ThreeDotsHorizontal className={menuClassName} />
        </Button>
      )}
    </Row>
  )
}

// ButtonWithIconAndCount is used in memory content adding page
export const ButtonWithIconAndCount = props => {
  let icon = React.cloneElement(props.icon, {
    style: {
      color:
        props.count > 0
          ? viaTheme.global.colors.turqoise
          : viaTheme.global.colors.sapphire,
      maxHeigh: '3.725rem',
      maxWidht: '3.563rem'
    }
  })

  return (
    <Col
      data-testid={props.testid}
      width="7.3125rem"
      align="center"
      justify="center"
      style={{ margin: '0 1.2rem 0.938rem 1.2rem' }}>
      <label htmlFor={props.name} style={{ width: '100%', cursor: 'pointer' }}>
        <Row align="center" style={{ position: 'relative' }}>
          <Box
            height="7.625rem"
            width="100%"
            background="lightGrey"
            align="center"
            justify="center"
            basis="100%"
            round="0.375rem"
            border={
              props.count > 0
                ? { color: viaTheme.global.colors.turqoise, size: 'small' }
                : undefined
            }>
            {icon}
          </Box>
          <SpanCount
            style={{
              display: props.count === 0 ? 'none' : 'inline-block'
            }}>
            {props.count}
          </SpanCount>
        </Row>
        <input style={{ display: 'none' }} id={props.name} {...props} />
        {props.label && <TextSmall align="center">{props.label}</TextSmall>}
      </label>
    </Col>
  )
}

const SpanCount = styled.span`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: ${viaTheme.global.colors.turqoise};
  height: 1.438rem;
  width: 1.438rem;
  border-radius: 100%;
  font-size: 1rem;
  color: ${viaTheme.global.colors.white};
  line-height: 1.438rem;
`

// SectionContentMemory is used as SectionContent for Memory page.
export const SectionContentMemory = styled(SectionContent)`
  max-width: 780px;
  align-items: start;
  margin: 0 auto;
  text-align: left;
  position: relative;
  width: 100%;
`

export const ModalMenuItem = props => (
  <Row justify="between" align="center" onClick={props.onClick}>
    <Paragraph>{props.title}</Paragraph>
    <Icon.AngleRight />
  </Row>
)

export const GreyBox = props => (
  <Box
    width="100%"
    background="lightGrey"
    align="center"
    justify="center"
    basis="100%"
    round="0.375rem"
    pad={{ vertical: 'medium', horizontal: 'medium' }}
    {...props}
  />
)
