import React, { useContext, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { ResponsiveContext } from 'grommet'

import {
  Row,
  Screen,
  Section,
  SectionContent
} from '../components/generic/View'
import { ParagraphSmall, StyledLink2 } from '../components/generic/Text'
import * as Button from '../components/generic/Button'
import IllustrationBlock from '../components/IllustrationBlock'
import ProfileImg from '../components/profile/ProfileImg'
import NavItem from '../components/profile/NavItem'

import { selectHeader } from '../../redux/slices/uiSlice'
const { isFileValid, handleBackButton } = require('../../utils/generic')
import { setToast } from '../../utils/status'

import { openModal } from '../../redux/slices/uiSlice'
import {
  selectUser,
  setProfilePhoto,
  logout
} from '../../redux/slices/userSlice'

import { CONTENT_TYPES } from '../../constants/contentTypes'

import profileIllustration from '../../assets/illustrations/profile.svg'

function Profile() {
  const dispatch = useDispatch()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useSelector(selectUser)

  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const [submitting, setSubmitting] = useState(false)

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    const backPath = state?.prevPath || '/home'
    handleBackButton(headerRedux, pathname, () => navigate(backPath))
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    multiColor: true,
    title: t('profile.heading'),
    backCustomActionTriggered: false
  }

  const updateProfilePhoto = async event => {
    const file = event.target.files[0]
    if (file) {
      setSubmitting(true)
      if (isFileValid(file, CONTENT_TYPES.IMAGE)) {
        const formData = new FormData()
        formData.append('file', file)

        const result = await dispatch(
          setProfilePhoto({
            userId: user.id,
            formData
          })
        )

        if (result.meta?.requestStatus === 'rejected') {
          if (result.payload !== null) {
            setToast(result.payload.title, 'error')
            setSubmitting(false)
          }
        }
        if (result.meta?.requestStatus === 'fulfilled') {
          setToast(t('profile.image_successfully_changed'), 'success')
          setSubmitting(false)
        }
      } else {
        setToast(
          t('media.image_upload_error') + ' ' + t('media.image_info'),
          'error'
        )
        setSubmitting(false)
      }
    }
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const onOpenProfileChangeNameModal = () => {
    dispatch(openModal({ type: 'profileChangeName', props: { user: user } }))
  }

  const onOpenProfileChangeLanguageModal = () => {
    dispatch(
      openModal({ type: 'profileChangeLanguage', props: { user: user } })
    )
  }

  const extraComponent = (
    <ProfileImg
      user={user}
      updateProfilePhoto={updateProfilePhoto}
      submitting={submitting}
    />
  )

  return (
    <Screen header={header}>
      <IllustrationBlock
        background
        heading2={t('profile.title')}
        img={isDesktop ? profileIllustration : ''}
        extraComponent={!isDesktop && extraComponent}
      />
      <Section textAlign="left">
        <SectionContent>
          <div style={{ width: '100%' }}>
            {isDesktop && (
              <ProfileImg
                user={user}
                updateProfilePhoto={updateProfilePhoto}
                submitting={submitting}
              />
            )}

            {/* User display name */}
            <ParagraphSmall
              semiBold
              darkBlueGrey
              style={{ marginTop: isDesktop ? '0' : '-25px' }}>
              {t('profile.display_name')}
            </ParagraphSmall>
            <Row
              justify="between"
              align="center"
              margin={{ bottom: isDesktop ? 'medium' : 'large' }}>
              <ParagraphSmall darkBlueGrey style={{ margin: '0' }}>
                {user.displayName}
              </ParagraphSmall>
              <ParagraphSmall darkBlueGrey style={{ margin: '0' }}>
                <StyledLink2
                  to="#"
                  underline="true"
                  nopad="true"
                  whiteSpace="nowrap"
                  onClick={() => onOpenProfileChangeNameModal()}>
                  {t('profile.edit')}
                </StyledLink2>
              </ParagraphSmall>
            </Row>

            {/* 
              DS 18/10/2022
              English is disabled until copy is checked 
            */}
            {/* User Language */}
            {/*
              <ParagraphSmall semiBold darkBlueGrey>
                {t('general.language')}
              </ParagraphSmall>
              <Row
                justify="between"
                align="center"
                margin={{ bottom: isDesktop ? 'medium' : 'large' }}>
                <ParagraphSmall darkBlueGrey style={{ margin: '0' }}>
                  {APP_LANGUAGES[user?.language || APP_LANGUAGES.de.value].label}
                </ParagraphSmall>
                <ParagraphSmall darkBlueGrey style={{ margin: '0' }}>
                  <StyledLink2
                    to="#"
                    underline="true"
                    nopad="true"
                    whiteSpace="nowrap"
                    onClick={() => onOpenProfileChangeLanguageModal()}>
                    {t('profile.edit')}
                  </StyledLink2>
                </ParagraphSmall>
              </Row>
             */}

            {/* User Email */}
            <ParagraphSmall darkBlueGrey semiBold>
              {t('general.email')}
            </ParagraphSmall>
            <ParagraphSmall darkBlueGrey style={{ marginBottom: '40px' }}>
              {user.username}
            </ParagraphSmall>

            {/* Action Points */}
            <NavItem
              to="/profile/change-password"
              label={t('profile.edit_password')}
            />
            {/* <NavItem to="/feedback" label={t('profile.feedback')} /> */}
            <NavItem to="/profile/contact-us" label={t('profile.contact_us')} />
            {/* Guided Tour nav item should be recovered when video tour will be implemented */}
            {/* <NavItem to="/profile/guided-tour" label={t('profile.watch_guided_tour')} /> */}
          </div>
          <Button.Primary
            onClick={handleLogout}
            type="button"
            data-testid="button-room-create"
            label={t('logout.heading')}
          />
          <Row pad="0 0 30px 0">
            <Button.Link to="/imprint" style={{ marginRight: '30px' }}>
              {t('general.imprint')}
            </Button.Link>
            <Button.Link to="/privacy">
              {t('general.privacy_policy')}
            </Button.Link>
          </Row>
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default Profile
