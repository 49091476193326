import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function Markdown({ children, isCentered }) {
  const extraClassName = isCentered ? 'alignCenter' : ''
  return (
    <div className={`markdown ${extraClassName}`}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>
    </div>
  )
}

export default Markdown
