import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HomeWithoutMemoryRooms from '../components/home/HomeWithoutMemoryRooms'
import HomeWithMemoryRooms from '../components/home/HomeWithMemoryRooms'

import { selectUser } from '../../redux/slices/userSlice'
import {
  fetchForUser,
  selectAllMemoryRooms
} from '../../redux/slices/memoryRoomSlice'

function Home() {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const memoryRooms = useSelector(selectAllMemoryRooms).filter(
    room => room !== undefined
  )

  useEffect(() => {
    dispatch(fetchForUser(user.id))
  }, [])

  return memoryRooms.length === 0 ? (
    <HomeWithoutMemoryRooms />
  ) : (
    <HomeWithMemoryRooms memoryRooms={memoryRooms} />
  )
}

export default Home
