import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, ResponsiveContext } from 'grommet'
import search_lens from '../../../assets/icons/search_lens.svg'
import viaTheme from '../../../styles/via-theme.json'

import {
  selectTemporaryData,
  setTemporaryData
} from '../../../redux/slices/uiSlice'

import { Col, Row, Screen, Section } from '../../components/generic/View'
import * as Button from '../../components/generic/Button'
import * as Input from '../../components/generic/Input'
import { Heading2, ParagraphSmall } from '../generic/Text'

const FilteringSettings = ({
  memoryRoomId,
  initData,
  data,
  setData,
  setMemoryStep
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tempData = useSelector(selectTemporaryData)
  const size = useContext(ResponsiveContext)

  const isDesktop = size === 'large'

  const handleSubmit = formData => {
    // set new room filter setting
    const roomViewSettings = {
      roomId: memoryRoomId,
      memoryFilter: formData
    }

    // keep sorting setting if it was set
    if (
      tempData?.roomViewSettings?.roomId === memoryRoomId &&
      tempData?.roomViewSettings?.memorySorting
    ) {
      roomViewSettings['memorySorting'] =
        tempData.roomViewSettings.memorySorting
    }

    dispatch(
      setTemporaryData({
        ...tempData,
        roomViewSettings
      })
    )

    const newFormData = { ...formData, search: formData.search.trim() }

    setData(newFormData)
    setMemoryStep()
  }

  const sectionPadding = {
    horizontal: !isDesktop ? 'medium' : '0',
    vertical: 'small'
  }

  return (
    <Screen>
      <Section columnItem textAlign="left">
        <Col
          pad={sectionPadding}
          width={isDesktop ? '460px' : '100%'}
          background="white"
          style={{ zIndex: 3 }}>
          <Button.Close
            onClick={() => setMemoryStep()}
            alignSelf="end"
            style={{ marginBottom: '32px' }}
          />
          <Heading2 style={{ paddingBottom: '20px' }}>
            {t('memory_room.filter_memories')}
          </Heading2>
          <div>
            <Formik
              initialValues={data}
              onSubmit={handleSubmit}
              validate={values => {
                let errors = {},
                  today = new Date()

                if (values.dateFrom || values.dateTo) {
                  // end date should not be bigger than today
                  if (values.dateTo && new Date(values.dateTo) > today) {
                    errors.dateFrom = t('general.errors.date_before_today')
                  }

                  // start date can not be bigger than today
                  if (values.dateFrom && new Date(values.dateFrom) > today) {
                    errors.dateFrom = t('general.errors.date_before_today')
                  }

                  // start date can not be bigger than end date
                  if (
                    values.dateFrom &&
                    values.dateTo &&
                    new Date(values.dateFrom) > new Date(values.dateTo)
                  ) {
                    errors.dateFrom = t('general.errors.date_end_after_start')
                  }

                  // start and end date should not be the same
                  if (values.dateFrom === values.dateTo) {
                    errors.dateFrom = t(
                      'general.errors.date_start_end_should_not_be_same'
                    )
                  }
                }
                return errors
              }}>
              {({ values, setFieldValue }) => (
                <Input.StyledForm>
                  <Box style={{ marginBottom: '25px' }}>
                    <Input.StyledField
                      type="text"
                      name="search"
                      placeholder={t('memory_room.filter_form.search')}
                      parentStyle={{
                        marginTop: '0'
                      }}
                      style={{
                        paddingLeft: '44px',
                        background: `no-repeat left 19px center url(${search_lens}), ${viaTheme.global.colors.lightGrey}`
                      }}
                    />
                  </Box>
                  <Col>
                    <Box
                      align={isDesktop ? 'center' : 'start'}
                      direction={isDesktop ? 'row' : 'column'}
                      justify={isDesktop ? 'between' : 'start'}>
                      <Row justify={'between'} style={{ width: '100%' }}>
                        <ParagraphSmall style={{ width: '50px' }}>
                          {t('general.from')}
                        </ParagraphSmall>
                        <Input.StyledField
                          type="date"
                          name="dateFrom"
                          parentStyle={{ width: '80%' }}
                        />
                      </Row>
                      <Col width="50px"></Col>
                      <Row justify={'between'} style={{ width: '100%' }}>
                        <ParagraphSmall style={{ width: '50px' }}>
                          {t('general.to')}
                        </ParagraphSmall>
                        <Input.StyledField
                          type="date"
                          name="dateTo"
                          parentStyle={{ width: '80%' }}
                        />
                      </Row>
                    </Box>
                    <Input.StyledErrorMessage name="dateFrom" component="div" />
                  </Col>

                  <Col height="40px"></Col>

                  <ParagraphSmall lineHeight="0.5rem">
                    {t('general.privacy')}
                  </ParagraphSmall>
                  <Input.StyledRadioButtonGroup
                    direction={isDesktop ? 'row' : 'column'}
                    name="privacy"
                    value={values.privacy}
                    options={[
                      {
                        value: 'all',
                        label: t('general.all')
                      },
                      {
                        value: 'private',
                        label: t('general.private')
                      },
                      {
                        value: 'public',
                        label: t('general.public')
                      }
                    ]}
                    onChange={event =>
                      setFieldValue('privacy', event.target.value)
                    }
                  />

                  <Col height="25px"></Col>

                  <ParagraphSmall lineHeight="0.5rem">
                    {t('general.includes')}
                  </ParagraphSmall>

                  <Box
                    align={isDesktop ? 'center' : 'start'}
                    direction={isDesktop ? 'row' : 'column'}
                    justify={isDesktop ? 'between' : 'start'}>
                    <Input.StyledCheckBox
                      name="hasImage"
                      label={t('memory.content.types.image')}
                      value={values.hasImage}
                      onChange={() =>
                        setFieldValue('hasImage', !values.hasImage)
                      }
                    />

                    <Input.StyledCheckBox
                      name="hasVideo"
                      label={t('memory.content.types.video')}
                      value={values.hasVideo}
                      onChange={() =>
                        setFieldValue('hasVideo', !values.hasVideo)
                      }
                    />

                    <Input.StyledCheckBox
                      name="hasAudio"
                      label={t('memory.content.types.audio')}
                      value={values.hasAudio}
                      onChange={() =>
                        setFieldValue('hasAudio', !values.hasAudio)
                      }
                    />

                    <Input.StyledCheckBox
                      name="hasText"
                      label={t('memory.content.types.text')}
                      value={values.hasText}
                      onChange={() => setFieldValue('hasText', !values.hasText)}
                    />
                  </Box>

                  <Button.Primary
                    type="submit"
                    flex={false}
                    label={t('general.show_results')}
                  />
                  <Button.LinkButton
                    type="button"
                    label={t('general.clear_all')}
                    onClick={() => handleSubmit(initData)}
                  />
                </Input.StyledForm>
              )}
            </Formik>
          </div>
        </Col>
      </Section>
    </Screen>
  )
}

export default FilteringSettings
