import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'

import { Illustration, Section, SectionContent } from './generic/View'
import { Heading1, Heading2, ParagraphLarge } from './generic/Text'

const IllustrationBlock = ({
  heading,
  heading2,
  paragraph,
  img,
  background,
  textlast,
  pad,
  extraComponent,
  paragraphBlackOnMobile
}) => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  let paragraphColor
  if (paragraph) {
    if (!paragraphBlackOnMobile) {
      paragraphColor = background ? 'white' : 'black'
    } else {
      paragraphColor = isDesktop ? 'white' : 'black'
    }
  }

  return (
    <StyledSection
      backgroundType={background ? 'gradientDynamic' : ''}
      pad={pad}>
      <StyledSectionContent>
        {heading && <Heading1 white="true">{heading}</Heading1>}
        {heading2 && <Heading2 white="true">{heading2}</Heading2>}
        {paragraph && (
          <ParagraphLarge
            textlast={textlast}
            color={paragraphColor}
            margin="10px 0 0 0">
            {paragraph}
          </ParagraphLarge>
        )}
        {img && <Illustration src={img} />}
        {extraComponent}
      </StyledSectionContent>
    </StyledSection>
  )
}

const StyledSectionContent = styled(SectionContent)`
  left: 0;
  @media (min-width: 1367px) {
    left: calc(50% - 683px);
  }
  @media (min-width: 768px) and (min-height: 600px) {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
  }
`

const StyledSection = styled(Section)`
  @media (min-width: 768px) and (max-height: 600px) {
    justify-content: center;
  }
  min-height: initial;
`

export default IllustrationBlock
