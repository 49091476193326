import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ResponsiveContext } from 'grommet'
import { useNavigate } from 'react-router-dom'

import { invite } from '../../../../redux/slices/memoryRoomSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'

import { Col, Row } from '../../generic/View'

const memoryRoomReinvite = props => {
  const { memoryRoomUser, email, message } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const size = useContext(ResponsiveContext)

  const handleSubmit = async () => {
    setSubmitting(true)
    const result = await dispatch(
      invite({
        memoryRoomUserId: memoryRoomUser.id,
        email: email,
        message: message,
        ignoreExistingInvite: true
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      navigate(`/rooms/${memoryRoomUser.memoryRoomId}`)
      setToast(t('memory_room.invite_successfully_sent'), 'success')
    }
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t('memory_room.invitation_form.user_is_already_invited', { email })}
      </Paragraph>

      <Row justify={size === 'large' ? 'between' : 'evenly'}>
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-no"
          label={t('general.no')}
          onClick={() => onCloseModal()}
          disabled={submitting}
          spinnerOnDisabled={true}
          adjustToSize={size}
        />

        <Button.White
          type="button"
          data-testid="button-yes"
          label={t('general.yes')}
          onClick={() => handleSubmit()}
          disabled={submitting}
          adjustToSize={size}
        />
      </Row>
    </Col>
  )
}

export default memoryRoomReinvite
