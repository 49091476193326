import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, ResponsiveContext } from 'grommet'

import {
  leaveRoom,
  fetchForUser
} from '../../../../redux/slices/memoryRoomSlice'
import { closeModal, openModal } from '../../../../redux/slices/uiSlice'
import { selectUser } from '../../../../redux/slices/userSlice'

import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'

import { Col } from '../../generic/View'

const memoryRoomLeave = props => {
  const { memoryRoomUser, memoryRoom } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = useState(false)
  const size = useContext(ResponsiveContext)
  const user = useSelector(selectUser)

  const handleUserRemoval = async () => {
    setSubmitting(true)
    const result = await dispatch(
      leaveRoom({
        memoryRoomUserId: memoryRoomUser.id
      })
    )
    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        if (
          result.payload.detail.general ===
          'memory_room.cant_leave_choose_another_admin'
        ) {
          dispatch(
            openModal({
              type: 'memoryRoomChooseAdmin',
              props: { memoryRoomUser, user, memoryRoom }
            })
          )
        } else {
          dispatch(closeModal())
          setToast(result.payload.title, 'error')
        }
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      navigate(`/home`)
      dispatch(fetchForUser(user.id))
      setToast(t('memory_room.you_left_the_room'), 'success')
    }
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t('memory_room.do_you_want_to_leave_the_room')}
      </Paragraph>

      <Box
        direction={size === 'large' ? 'row' : 'column'}
        justify={size === 'large' ? 'between' : 'evenly'}>
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-no"
          label={t('general.no')}
          onClick={() => onCloseModal()}
          disabled={submitting}
          spinnerOnDisabled={true}
          adjustToSize={size}
          style={
            size !== 'large'
              ? { width: '100%', maxWidth: '300px', marginBottom: '20px' }
              : {}
          }
        />

        <Button.White
          type="button"
          data-testid="button-yes"
          label={t('general.yes')}
          onClick={() => handleUserRemoval()}
          disabled={submitting}
          adjustToSize={size}
          style={size !== 'large' ? { width: '100%', maxWidth: '300px' } : {}}
        />
      </Box>
    </Col>
  )
}

export default memoryRoomLeave
