import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import {
  selectTemporaryData,
  setTemporaryData
} from '../../../redux/slices/uiSlice'

import { Col, Screen, Section } from '../../components/generic/View'
import * as Button from '../../components/generic/Button'
import * as Input from '../../components/generic/Input'
import { Heading2 } from '../generic/Text'

const SortingSettings = ({
  memoryRoomId,
  initData,
  data,
  setData,
  setMemoryStep
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tempData = useSelector(selectTemporaryData)
  const size = useContext(ResponsiveContext)

  const isDesktop = size === 'large'

  const handleSubmit = formData => {
    // set new room sorting setting
    const roomViewSettings = {
      roomId: memoryRoomId,
      memorySorting: formData
    }

    // keep filter setting if it was set
    if (
      tempData?.roomViewSettings?.roomId === memoryRoomId &&
      tempData?.roomViewSettings?.memoryFilter
    ) {
      roomViewSettings['memoryFilter'] = tempData.roomViewSettings.memoryFilter
    }

    dispatch(
      setTemporaryData({
        ...tempData,
        roomViewSettings
      })
    )

    setData(formData)
    setMemoryStep()
  }

  const sectionPadding = {
    horizontal: !isDesktop ? 'medium' : '0',
    vertical: 'small'
  }

  return (
    <Screen>
      <Section columnItem textAlign="left">
        <Col
          pad={sectionPadding}
          width={isDesktop ? '460px' : '100%'}
          background="white"
          style={{ zIndex: 3 }}>
          <Button.Close
            onClick={() => setMemoryStep()}
            alignSelf="end"
            style={{ marginBottom: '32px' }}
          />
          <Heading2 style={{ paddingBottom: '10px' }}>
            {t('general.sort.sort_by')}:
          </Heading2>
          <Formik initialValues={data} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Input.StyledForm>
                <Input.StyledRadioButtonGroup
                  name="sortBy"
                  value={values.sortBy}
                  options={[
                    { value: 'memoryDate', label: t('memory.date_of_memory') },
                    {
                      value: 'createdAt',
                      label: t('general.date_of_publishing')
                    }
                  ]}
                  onChange={event =>
                    setFieldValue('sortBy', event.target.value)
                  }
                />

                <Col height="40px"></Col>

                <Input.StyledRadioButtonGroup
                  name="order"
                  value={values.order}
                  options={[
                    { value: 'asc', label: t('general.sort.new_to_old') },
                    { value: 'desc', label: t('general.sort.old_to_new') }
                  ]}
                  onChange={event => setFieldValue('order', event.target.value)}
                />

                <Button.Primary
                  type="submit"
                  flex={false}
                  label={t('general.sort.heading')}
                />
                <Button.LinkButton
                  type="button"
                  label={t('general.clear_all')}
                  onClick={() => handleSubmit(initData)}
                />
              </Input.StyledForm>
            )}
          </Formik>
        </Col>
      </Section>
    </Screen>
  )
}

export default SortingSettings
