import React from 'react'

const {
  SUPPORTED_IMAGE_ACCEPT_PROP
} = require('../../../constants/supportedMediaTypes')
import camera from '../../../assets/icons/icon_small_camera.svg'
import loader from '../../../assets/icons/loader.gif'
import defaultProfile from '../../../assets/img/default_profile.svg'

const ProfileImg = ({ user, updateProfilePhoto, submitting }) => {
  const userAvatar =
    user?.assets?.length > 0 ? user.assets[0].fileUrl : defaultProfile

  return (
    <div style={{ height: '140px' }}>
      <img
        src={userAvatar}
        alt="profile image"
        style={{
          height: '86px',
          width: '86px',
          borderRadius: '50%',
          objectFit: 'cover'
        }}
      />

      <label htmlFor="image" style={{ position: 'relative' }}>
        {submitting ? (
          <img
            src={loader}
            style={{
              width: '30px',
              position: 'absolute',
              bottom: '-5px',
              right: '-7px',
              borderRadius: '50%'
            }}
          />
        ) : (
          <img
            src={camera}
            style={{
              position: 'absolute',
              bottom: '-5px',
              right: '-7px',
              cursor: 'pointer'
            }}
          />
        )}
        <input
          id="image"
          type="file"
          accept={SUPPORTED_IMAGE_ACCEPT_PROP}
          onChange={updateProfilePhoto}
          style={{ display: 'none' }}
          disabled={submitting}
        />
      </label>
    </div>
  )
}

export default ProfileImg
