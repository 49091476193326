import React, { useContext } from 'react'
import Joyride from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

const OnboardingTour = ({
  steps,
  run = false,
  callback = null,
  allowClose = true
}) => {
  const size = useContext(ResponsiveContext)
  const { t } = useTranslation()

  if (!steps) return null

  // allowClose is set to true at this moment,
  // might be configurable again in the future
  allowClose = true

  return (
    <Joyride
      callback={callback}
      continuous={true}
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      hideCloseButton={true}
      locale={{
        back: t('general.onboarding.buttons.back'),
        last: t('general.onboarding.buttons.last'),
        next: t('general.onboarding.buttons.next'),
        skip: t('general.onboarding.buttons.skip')
      }}
      run={run}
      showProgress={false}
      showSkipButton={allowClose}
      steps={steps}
      debug={false}
      floaterProps={{
        disableAnimation: true
      }}
      styles={{
        tooltip: {
          borderRadius:
            size === 'small' ? '12px' : size === 'medium' ? '20px' : '32px'
        },
        tooltipContainer: {
          fontFamily: 'Montserrat',
          textAlign: 'left'
        },
        spotlight: {
          borderRadius: '32px'
        },
        buttonNext: {
          backgroundColor: '#00B3AE',
          fontFamily: 'Montserrat'
        },
        buttonSkip: {
          fontFamily: 'Montserrat'
        },
        buttonBack: {
          marginRight: 10,
          fontFamily: 'Montserrat'
        }
      }}
    />
  )
}

export default OnboardingTour
