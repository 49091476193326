import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ResponsiveContext } from 'grommet'

import { updateMemoryRoomUserNotificationState } from '../../../../redux/slices/memoryRoomSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'

import { Col, Row } from '../../generic/View'

const memoryRoomUserUnfollow = props => {
  const { memoryRoomUser } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const size = useContext(ResponsiveContext)

  const handleSubmit = async () => {
    setSubmitting(true)
    const result = await dispatch(
      updateMemoryRoomUserNotificationState({
        memoryRoomUserId: memoryRoomUser.id,
        getNotifications: !memoryRoomUser.getNotifications
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        dispatch(closeModal())
        setToast(result.payload.title, 'error')
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      setToast(t('memory_room.user_stopped_following'), 'success')
    }
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph
        textAlign="left"
        darkBlueGrey
        style={{ whiteSpace: 'pre-line' }}>
        {t('memory_room.unfollow_instruction')}
      </Paragraph>

      <Row justify={size === 'large' ? 'between' : 'evenly'}>
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-no"
          label={t('general.no')}
          onClick={() => onCloseModal()}
          adjustToSize={size}
          disabled={submitting}
          spinnerOnDisabled={true}
        />

        <Button.White
          type="button"
          data-testid="button-yes"
          label={t('general.yes')}
          onClick={() => handleSubmit()}
          disabled={submitting}
          adjustToSize={size}
        />
      </Row>
    </Col>
  )
}

export default memoryRoomUserUnfollow
