import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { openModal } from '../../../../redux/slices/uiSlice'

import { Col } from '../../generic/View'
import { ModalMenuItem } from '../../generic/Elements'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const memorySettings = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  let { memoryRoomId, memoryId, isMemoryRoomOwner, isMemoryOwner } =
    props.modalProps

  memoryRoomId = parseInt(memoryRoomId, 10)

  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }

  return (
    <Col style={{ ...props.style }}>
      {!isAppSunsettingMode && (
        <>
          {(isMemoryOwner || isMemoryRoomOwner) && (
            <ModalMenuItem
              title={t('general.edit')}
              onClick={() => {
                navigate(`/rooms/${memoryRoomId}/memory/${memoryId}/edit`)
              }}
            />
          )}
          {isMemoryOwner && (
            <ModalMenuItem
              title={t('general.edit_privacy')}
              onClick={() => onOpenModal('memoryChangePrivacySetting')}
            />
          )}
          {isMemoryRoomOwner && (
            <ModalMenuItem
              title={t('memory_room.invite_member')}
              onClick={() =>
                navigate(
                  `/rooms/${props.modalProps.memoryRoomId}/invite-member`
                )
              }
            />
          )}
        </>
      )}
      <ModalMenuItem
        title={t('general.onboarding.watch_onboarding')}
        onClick={props.modalProps.onboardingAction}
      />
      {!isAppSunsettingMode && (
        <>
          {(isMemoryOwner || isMemoryRoomOwner) && (
            <ModalMenuItem
              title={t('general.delete_all')}
              onClick={() => onOpenModal('memoryDeleteAll')}
            />
          )}
        </>
      )}
    </Col>
  )
}

export default memorySettings
