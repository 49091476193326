import React from 'react'
import { Box } from 'grommet'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'

import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Circles } from '../Elements'

import { FEEDBACK_EMOTIONS } from '../../../../constants/feedbackEmotions'

import iconAngry from '../../../../assets/icons/emoji/icon_angry.svg'
import iconConfused from '../../../../assets/icons/emoji/icon_confused.svg'
import iconContent from '../../../../assets/icons/emoji/icon_content.svg'
import iconFrustrated from '../../../../assets/icons/emoji/icon_frustrated.svg'
import iconHappy from '../../../../assets/icons/emoji/icon_happy.svg'
import iconShocked from '../../../../assets/icons/emoji/icon_shocked.svg'

const EmojiModal = ({ setCurrentStep, pageSteps, form }) => {
  const { t } = useTranslation()

  const emojies = [
    {
      value: FEEDBACK_EMOTIONS.CONTENT,
      icon: iconContent,
      text: t('feedback_modal.content')
    },
    {
      value: FEEDBACK_EMOTIONS.HAPPY,
      icon: iconHappy,
      text: t('feedback_modal.happy')
    },
    {
      value: FEEDBACK_EMOTIONS.SHOCKED,
      icon: iconShocked,
      text: t('feedback_modal.shocked')
    },
    {
      value: FEEDBACK_EMOTIONS.FRUSTRATED,
      icon: iconFrustrated,
      text: t('feedback_modal.frustrated')
    },
    {
      value: FEEDBACK_EMOTIONS.ANGRY,
      icon: iconAngry,
      text: t('feedback_modal.angry')
    },
    {
      value: FEEDBACK_EMOTIONS.CONFUSED,
      icon: iconConfused,
      text: t('feedback_modal.confused')
    }
  ]
  return (
    <>
      <Paragraph textAlign="left">
        {t('feedback_modal.how_do_you_feel')}
      </Paragraph>
      <Box wrap direction="row" align="center" justify="between">
        {emojies.map(emoji => {
          return (
            <label key={emoji.value} style={{ cursor: 'pointer' }}>
              <Field
                type="radio"
                name="feeling"
                value={emoji.value}
                style={{ display: 'none' }}
              />
              <Box justify="center" align="center" width="100px" pad="20px">
                <img src={emoji.icon} />
                <Paragraph
                  bold={form.values.feeling === emoji.value}
                  color={form.values.feeling === emoji.value ? '#00B3AE' : ''}
                  style={{ wordBreak: 'normal' }}>
                  {emoji.text}
                </Paragraph>
              </Box>
            </label>
          )
        })}
      </Box>
      <Circles firstPage />
      <Button.Primary
        type="button"
        flex={false}
        data-testid="input-next"
        label={t('general.next')}
        disabled={!form.values.feeling}
        onClick={() => setCurrentStep(pageSteps.TEXT)}
      />
    </>
  )
}

export default EmojiModal
