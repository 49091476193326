import React, { useContext, useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext, Button } from 'grommet'
import { Formik } from 'formik'

import {
  selectMemoryById,
  fetchMemoryForMemoryRoomUser
} from '../../redux/slices/memorySlice'
import { selectMemoryRoomById } from '../../redux/slices/memoryRoomSlice'
import { selectUser, update as updateUser } from '../../redux/slices/userSlice'
import {
  openModal,
  selectTemporaryData,
  selectHeader
} from '../../redux/slices/uiSlice'
import { addContentComment } from '../../redux/slices/memorySlice'

import { Screen, Section, Col, Row } from '../components/generic/View'
import { SectionContentMemory, UserIcon } from '../components/generic/Elements'
import { Paragraph, TextSmall } from '../components/generic/Text'
import { Tooltip } from '../components/generic/Tooltip'
import * as Input from '../components/generic/Input'
import MemoryContentItem from '../components/memory/MemoryContentItem'
import MemoryContentComment from '../components/memory/MemoryContentComment'
import MemoryLightBox from '../components/memory/MemoryLightBox'
import * as Icon from '../components/generic/Icon'

import { setToast } from '../../utils/status'
import { scrollWindow } from '../../utils/browser'
import { handleBackButton } from '../../utils/generic'

import viaTheme from '../../styles/via-theme.json'

const memoryRoomUserConstants = require('../../constants/memoryRoomUserRoles')
const { OWNER } = memoryRoomUserConstants.ROLES
const { PRIVACY_SETTINGS } = require('../../constants/privacySettings')

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function MemoryContent() {
  let { roomId, memoryId, memoryContentId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [submitting, setSubmitting] = useState(false)
  const [commentIsActive, setCommentIsActive] = useState(false)

  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )

  if (!memoryRoom) {
    return <Navigate to="/home" replace />
  }

  memoryId = parseInt(memoryId, 10)
  const memory = useSelector(state => selectMemoryById(state, memoryId))

  const roomLink = `/rooms/${memoryRoomId}`
  if (!memory) {
    return <Navigate to={roomLink} replace />
  }

  const navigate = useNavigate()
  const user = useSelector(selectUser)

  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )
  const isMemoryRoomOwner = memoryRoomUser.role === OWNER
  const isMemoryOwner = memory.memoryRoomUserId === memoryRoomUser.id

  useEffect(() => {
    dispatch(
      fetchMemoryForMemoryRoomUser({
        memoryId,
        memoryRoomUserId: memoryRoomUser.id
      })
    )
  }, [])

  const size = useContext(ResponsiveContext)
  const isMobile = size !== 'large'

  const tempData = useSelector(selectTemporaryData)

  // LightBox state
  const [currentImageUrl, setCurrentImageUrl] = useState()
  const [showLightBox, setShowLightBox] = useState()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () =>
      navigate(`/rooms/${memoryRoomId}/memory/${memoryId}`)
    )
  }, [headerRedux.backCustomActionTriggered])

  memoryContentId = parseInt(memoryContentId, 10)
  let content = memory.memoryContent.find(
    memoryContent => memoryContent.id === memoryContentId
  )

  if (!content) {
    return <Navigate to={`${roomLink}/memory/${memoryId}`} replace />
  }

  const header = {
    type: 'actionBar',
    title: t('memory.comments.heading'),
    withBackground: true,
    backCustomActionTriggered: false
  }

  let sortedComments = []
  if (content?.comments?.length > 0) {
    const comments = [...content.comments]
    sortedComments = comments.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
    })
  }

  const isContentOwner = content.memoryRoomUserId === memoryRoomUser.id

  const displayContentMenu =
    isMemoryRoomOwner || isMemoryOwner || isContentOwner

  let commentIsPrivate
  if (content.private) {
    commentIsPrivate = true
  } else {
    const privacySetting = tempData?.privacy || PRIVACY_SETTINGS.PUBLIC
    commentIsPrivate = privacySetting !== PRIVACY_SETTINGS.PUBLIC
  }

  const initialValues = { comment: '' }

  if (commentIsActive && !isMobile) {
    scrollWindow(false)
  }

  const openCommentPrivacyModal = () => {
    dispatch(
      openModal({
        type: 'commentPrivacySetting',
        props: { memoryRoomId, memoryId }
      })
    )
  }

  const onOpenLightBox = currentImageUrl => {
    setCurrentImageUrl(currentImageUrl)
    setShowLightBox(true)
  }

  const handleSubmit = async formData => {
    formData = {
      private: commentIsPrivate,
      ...formData
    }
    setSubmitting(true)
    const result = await dispatch(
      addContentComment({
        memoryRoomUserId: memoryRoomUser.id,
        memoryId,
        memoryContentId,
        data: formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      setToast(t('memory.comments.comment_was_added'), 'success')
      setSubmitting(false)
      scrollWindow(false)
    }
  }

  const openCommentSection = () => {
    setCommentIsActive(true)
    // update user after a timeout, so the tooltip on comment can stay active
    if (!user.onboardedToContent && !content.private) {
      setTimeout(() => {
        dispatch(
          updateUser({
            userId: user.id,
            formData: { onboardedToContent: true }
          })
        )
      }, 3000)
    }
  }

  const sectionPadding = {
    horizontal: `${size !== 'large' ? 'medium' : '0'}`,
    vertical: 'small'
  }

  const commentPrivacyOptions = content.private ? (
    <Icon.Lock style={{ color: '#02CBC5', height: '20px' }} />
  ) : (
    <Tooltip
      id="comment-privacy"
      text={t('memory.comments.privacy_tooltip')}
      showOnRender={!user.onboardedToContent}>
      <Button type="button" onClick={openCommentPrivacyModal}>
        {commentIsPrivate ? (
          <Icon.Lock style={{ color: '#02CBC5', height: '20px' }} />
        ) : (
          <Icon.Group style={{ height: '20px' }} />
        )}
      </Button>
    </Tooltip>
  )

  return (
    <Screen column header={header}>
      <Section columnItem>
        <SectionContentMemory
          padding={isMobile ? '0 0 30px 0' : sectionPadding}>
          <MemoryContentItem
            key={content.id}
            content={content}
            memoryRoomUserId={memoryRoomUser.id}
            memoryRoom={memoryRoom}
            displayMenu={displayContentMenu}
            commentsIsLink={false}
            onOpenLightBox={currentImageUrl => onOpenLightBox(currentImageUrl)}
          />

          {sortedComments.map(comment => {
            const isCommentOwner =
              comment.memoryRoomUserId === memoryRoomUser.id

            const menuIsDisplayed =
              isMemoryRoomOwner ||
              isMemoryOwner ||
              isContentOwner ||
              isCommentOwner

            return (
              <MemoryContentComment
                key={comment.id}
                comment={comment}
                contentId={memoryContentId}
                memoryId={memoryId}
                memoryRoomUserId={memoryRoomUser.id}
                menuIsDisplayed={menuIsDisplayed}
              />
            )
          })}

          {!isAppSunsettingMode && (
            <>
              {!commentIsActive && (
                <Button
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0 13px'
                  }}
                  onClick={openCommentSection}>
                  <UserIcon user={memoryRoomUser.user} />
                  <Paragraph darkBlueGrey>
                    {t('memory.comments.add_comment_instruction')}
                  </Paragraph>
                </Button>
              )}

              {commentIsActive && (
                <Formik
                  initialValues={initialValues}
                  validate={values => {
                    const errors = {},
                      commentLimit = 2_000

                    if (!values.comment.trim()) {
                      errors.comment = t('general.required_field')
                    } else if (values.comment.trim().length > commentLimit) {
                      errors.comment = t('general.errors.text_too_long', {
                        limit: commentLimit,
                        length: values.comment.trim().length
                      })
                    }

                    return errors
                  }}
                  onSubmit={(values, { resetForm }) => {
                    resetForm(initialValues)
                    setCommentIsActive(false)
                    handleSubmit(values)
                  }}>
                  {() => (
                    <Col width="100%">
                      <Input.StyledForm>
                        <Row pad={{ horizontal: '13px', top: '6px' }}>
                          <UserIcon user={memoryRoomUser.user} />
                          <Col width="100%">
                            <Input.StyledTextArea
                              autoFocus
                              type="text"
                              name="comment"
                              testid="input-comment"
                              width="100%"
                              style={{ width: '100%' }}
                            />
                            <Input.StyledErrorMessage
                              name="comment"
                              component="div"
                            />
                          </Col>
                        </Row>
                        <Row
                          justify="between"
                          pad={{ horizontal: '13px' }}
                          style={{ alignItems: 'center' }}>
                          {commentPrivacyOptions}
                          <Button
                            type="submit"
                            disabled={submitting}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                            <Icon.Message />
                            <TextSmall
                              color={viaTheme.global.colors.blackFont}
                              fontWeight="600"
                              style={{ padding: '0 1rem 0 .375rem' }}>
                              {t('general.save')}
                            </TextSmall>
                          </Button>
                        </Row>
                      </Input.StyledForm>
                    </Col>
                  )}
                </Formik>
              )}
            </>
          )}
        </SectionContentMemory>
      </Section>
      {showLightBox && (
        <MemoryLightBox
          onClose={() => setShowLightBox(false)}
          content={memory.memoryContent}
          currentImageUrl={currentImageUrl}
          displayOnlyCurrentImage
        />
      )}
    </Screen>
  )
}

export default MemoryContent
