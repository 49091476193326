import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ResponsiveContext } from 'grommet'

const { getAllowedFiles } = require('../../../../utils/generic')
import {
  openModal,
  selectTemporaryData
} from '../../../../redux/slices/uiSlice'

import { CONTENT_TYPES } from '../../../../constants/contentTypes'
const { PRIVACY_SETTINGS } = require('../../../../constants/privacySettings')
const {
  SUPPORTED_AUDIO_ACCEPT_PROP,
  SUPPORTED_IMAGE_ACCEPT_PROP,
  SUPPORTED_VIDEO_ACCEPT_PROP,
  SUPPORTED_DOCUMENT_ACCEPT_PROP
} = require('../../../../constants/supportedMediaTypes')

import { Row, Col } from '../../generic/View'
import { ButtonWithIconAndCount } from '../../generic/Elements'
import * as Button from '../../generic/Button'
import { Paragraph } from '../../generic/Text'
import { Tooltip } from '../../generic/Tooltip'

import { ReactComponent as IconGallery } from '../../../../assets/icons/icon_gallery.svg'
import { ReactComponent as IconText } from '../../../../assets/icons/icon_text.svg'
import { ReactComponent as IconCamera } from '../../../../assets/icons/icon_camera.svg'
import { ReactComponent as IconLightbulb } from '../../../../assets/icons/icon_lightbulb.svg'
import { ReactComponent as IconMicrophone } from '../../../../assets/icons/icon_microphone.svg'
import { ReactComponent as IconDocument } from '../../../../assets/icons/icon_document.svg'
import { ReactComponent as IconInfo } from '../../../../assets/icons/icon_info.svg'

function SelectContentForm(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [fileList, setFileList] = useState(false)
  const [uploadType, setUploadType] = useState(false)

  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const tempData = useSelector(selectTemporaryData)
  const privacySetting = tempData?.privacy || PRIVACY_SETTINGS.PUBLIC

  // file submit function
  const updateContent = (type, files) => {
    // open privacy setting modal when choose individually was selected previously
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      setUploadType(type)
      setFileList(files)
      dispatch(
        openModal({
          type: 'memoryPrivacySetting',
          withoutCloseButton: true
        })
      )
    } else {
      // submit if privacy setting is set
      submitData(type, files)
    }
  }

  // submitData should be triggered after choose individually privacy modal is shown
  useEffect(() => {
    if (privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY) {
      if (tempData.key && tempData.contentPrivacy && fileList) {
        submitData(uploadType, fileList)
        setUploadType(undefined)
        setFileList(undefined)
      }
    }
  }, [tempData?.key])

  const submitData = (type, files) => {
    if (files && files.length > 0) {
      let allowedFiles = getAllowedFiles(files, type)

      if (allowedFiles.length > 0) {
        const contentPrivacy =
          privacySetting === PRIVACY_SETTINGS.CHOOSE_INDIVIDUALLY
            ? tempData.contentPrivacy
            : privacySetting

        let contentIsPrivate = contentPrivacy === PRIVACY_SETTINGS.PRIVATE

        props.setContentCountFunc({
          ...props.contentCountObject,
          [type]: props.contentCountObject[type] + allowedFiles.length
        })

        props.setArrayFunc(arr => {
          return [
            ...arr,
            {
              type: type,
              private: contentIsPrivate,
              assets: allowedFiles
            }
          ]
        })
      }
    }
  }

  return (
    <Col>
      <Paragraph textAlign="left">
        {t('memory.content.heading')}
        <Tooltip
          id="content-info"
          text={t('memory.tooltip.content_information')}
          style={{ margin: '0 10px' }}>
          <IconInfo />
        </Tooltip>
      </Paragraph>

      <Row wrap justify="center">
        <ButtonWithIconAndCount
          testid="img-icon"
          label={t('memory.content.gallery')}
          icon={<IconGallery />}
          type="file"
          multiple
          name="button-gallery"
          accept={`${SUPPORTED_IMAGE_ACCEPT_PROP},${SUPPORTED_VIDEO_ACCEPT_PROP},${SUPPORTED_AUDIO_ACCEPT_PROP}`}
          count={props.contentCountObject.gallery}
          onChange={event =>
            updateContent(CONTENT_TYPES.GALLERY, event.currentTarget.files)
          }
        />
        <ButtonWithIconAndCount
          testid="text-icon"
          label={t('memory.content.text.heading')}
          icon={<IconText />}
          type="button"
          name="button-text"
          onClick={props.setTextStep}
          count={props.contentCountObject.text}
        />
        {!isDesktop && (
          <ButtonWithIconAndCount
            label={t('memory.content.camera')}
            icon={<IconCamera />}
            type="file"
            name="button-camera"
            accept={`${SUPPORTED_IMAGE_ACCEPT_PROP},${SUPPORTED_VIDEO_ACCEPT_PROP}`}
            capture
            count={props.contentCountObject.camera}
            onChange={event =>
              updateContent(CONTENT_TYPES.CAMERA, event.currentTarget.files)
            }
          />
        )}
        <ButtonWithIconAndCount
          label={t('memory.content.inspire_me')}
          icon={<IconLightbulb />}
          type="button"
          name="button-inspire-me"
          onClick={props.setInspireMeStep}
          count={props.contentCountObject.inspire_me}
        />
        <ButtonWithIconAndCount
          label={t('memory.content.audio')}
          icon={<IconMicrophone />}
          type="button"
          name="button-audio"
          onClick={props.setAudioStep}
          count={props.contentCountObject.audio}
        />
        <ButtonWithIconAndCount
          label={t('memory.content.document')}
          icon={<IconDocument />}
          type="file"
          multiple
          name="button-document"
          accept={`${SUPPORTED_DOCUMENT_ACCEPT_PROP}`}
          count={props.contentCountObject.document}
          onChange={event =>
            updateContent(CONTENT_TYPES.DOCUMENT, event.currentTarget.files)
          }
        />
      </Row>
      <Button.Primary
        type="button"
        flex={false}
        data-testid="button-content"
        label={t(props.buttonLabelKey)}
        onClick={props.buttonAction}
        disabled={props.submitting}
        spinnerOnDisabled={true}
      />
    </Col>
  )
}

export default SelectContentForm
