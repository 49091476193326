import React from 'react'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const {
  REACT_APP_MATOMO_TRACKING_ACTIVE,
  REACT_APP_MATOMO_URL,
  REACT_APP_MATOMO_SITE_ID
} = process.env

const MatomoWrapper = ({ children }) => {
  const matomoTrackingIsActive =
    REACT_APP_MATOMO_TRACKING_ACTIVE === 'TRUE' &&
    REACT_APP_MATOMO_URL &&
    REACT_APP_MATOMO_SITE_ID

  if (matomoTrackingIsActive) {
    const matomoInstance = createInstance({
      disabled: !matomoTrackingIsActive,
      urlBase: REACT_APP_MATOMO_URL,
      siteId: REACT_APP_MATOMO_SITE_ID
    })

    return <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>
  }

  return children
}

export default MatomoWrapper
