import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { closeModal, selectModal } from '../../../redux/slices/uiSlice'

import * as Button from './Button'
import { ModalContainer, Layer, Overlay } from './View'

// memory room modals
import memoryRoomSettingsOwner from '../memoryRoom/modal/memoryRoomSettingsOwner'
import memoryRoomSettingsContributor from '../memoryRoom/modal/memoryRoomSettingsContributor'
import memoryRoomUsers from '../memoryRoom/modal/memoryRoomUsers'
import memoryRoomUserDetailed from '../memoryRoom/modal/memoryRoomUserDetailed'
import memoryRoomRename from '../memoryRoom/modal/memoryRoomRename'
import memoryRoomChangeDescription from '../memoryRoom/modal/memoryRoomChangeDescription'
import memoryRoomChangeImage from '../memoryRoom/modal/memoryRoomChangeImage'
import memoryRoomUserUnfollow from '../memoryRoom/modal/memoryRoomUserUnfollow'
import memoryRoomUserRemove from '../memoryRoom/modal/memoryRoomUserRemove'
import memoryRoomLeave from '../memoryRoom/modal/memoryRoomLeave'
import memoryRoomChooseAdmin from '../memoryRoom/modal/memoryRoomChooseAdmin'
import memoryRoomReinvite from '../memoryRoom/modal/memoryRoomReinvite'

// memory modals
import memorySettings from '../memory/modal/memorySettings'
import memoryDeleteAll from '../memory/modal/memoryDeleteAll'
import memoryPrivacySetting from '../memory/modal/memoryPrivacySetting'
import memoryChangePrivacySetting from '../memory/modal/memoryChangePrivacySetting'

//memory content modals
import memoryContentSettings from '../memory/content/modal/memoryContentSettings'
import memoryContentDelete from '../memory/content/modal/memoryContentDelete'
import commentPrivacySetting from '../memory/content/modal/commentPrivacySetting'
import contentPrivacySetting from '../memory/content/modal/contentPrivacySetting'
import commentSettings from '../memory/content/modal/commentSettings'
import commentDelete from '../memory/content/modal/commentDelete'

//profile modals
import profileChangeName from '../profile/modal/profileChangeName'
import profileChangeLanguage from '../profile/modal/profileChangeLanguage'

//terms modal
import termsAndConditions from '../modal/termsAndConditions'

//feedback modal
import Feedback from '../feedback/modal/Feedback'

// go to online counseling
import openOnlineCounseling from '../modal/openOnlineCounseling'

const Modal = () => {
  const modal = useSelector(selectModal)
  const dispatch = useDispatch()
  let modalOpened = false

  const onCloseModal = () => {
    dispatch(closeModal()) // set modal to inactive
    modalOpened = false
  }

  // If URL is changed, opened modal should be closed.
  const { pathname } = useLocation()
  useEffect(() => {
    if (modalOpened) {
      onCloseModal()
    }
  }, [pathname])

  if (modal.active !== true) return null

  let ModalContent = null

  // take modal type from redux state and render component accordingly
  switch (modal.type) {
    //memorySettings and related modals
    case 'memorySettings':
      ModalContent = memorySettings
      break
    case 'memoryDeleteAll':
      ModalContent = memoryDeleteAll
      break
    case 'memoryPrivacySetting':
      ModalContent = memoryPrivacySetting
      break
    case 'memoryChangePrivacySetting':
      ModalContent = memoryChangePrivacySetting
      break
    //memoryContentSettings and related modals
    case 'memoryContentSettings':
      ModalContent = memoryContentSettings
      break
    case 'memoryContentDelete':
      ModalContent = memoryContentDelete
      break
    case 'commentPrivacySetting':
      ModalContent = commentPrivacySetting
      break
    case 'contentPrivacySetting':
      ModalContent = contentPrivacySetting
      break
    case 'commentSettings':
      ModalContent = commentSettings
      break
    case 'commentDelete':
      ModalContent = commentDelete
      break
    //memoryRoomSettings and related modals
    case 'memoryRoomSettingsOwner':
      ModalContent = memoryRoomSettingsOwner
      break
    case 'memoryRoomSettingsContributor':
      ModalContent = memoryRoomSettingsContributor
      break
    case 'memoryRoomUsers':
      ModalContent = memoryRoomUsers
      break
    case 'memoryRoomUserDetailed':
      ModalContent = memoryRoomUserDetailed
      break
    case 'memoryRoomRename':
      ModalContent = memoryRoomRename
      break
    case 'memoryRoomChangeDescription':
      ModalContent = memoryRoomChangeDescription
      break
    case 'memoryRoomChangeImage':
      ModalContent = memoryRoomChangeImage
      break
    case 'memoryRoomUserUnfollow':
      ModalContent = memoryRoomUserUnfollow
      break
    case 'memoryRoomUserRemove':
      ModalContent = memoryRoomUserRemove
      break
    case 'memoryRoomLeave':
      ModalContent = memoryRoomLeave
      break
    case 'memoryRoomChooseAdmin':
      ModalContent = memoryRoomChooseAdmin
      break
    case 'memoryRoomReinvite':
      ModalContent = memoryRoomReinvite
      break
    //profile modals
    case 'profileChangeName':
      ModalContent = profileChangeName
      break
    case 'profileChangeLanguage':
      ModalContent = profileChangeLanguage
      break
    //terms and conditions
    case 'termsAndConditions':
      ModalContent = termsAndConditions
      break
    //feedback
    case 'feedback':
      ModalContent = Feedback
      break
    //online counseling
    case 'openOnlineCounseling':
      ModalContent = openOnlineCounseling
      break
    default:
      console.error(`invalid modal.type: ${modal.type}`)
      onCloseModal()
      break
  }

  if (ModalContent) {
    modalOpened = true
  }

  if (!ModalContent) {
    return null
  }

  return (
    <ModalContainer align="center" justify="center" style={{ zIndex: 3 }} fill>
      <Layer
        background="white"
        width={{ max: '100%' }}
        narrow={!modal.fullWidth}
        padNone={ModalContent === Feedback}>
        {!modal.withoutCloseButton && (
          <Button.Close
            onClick={onCloseModal}
            alignSelf="end"
            style={{ position: 'absolute', right: '26px', top: '26px' }}
          />
        )}
        <ModalContent
          modalProps={modal.props}
          style={{ minHeight: 'fit-content' }}
        />
      </Layer>
      <Overlay align="center" background="overlay-background" />
    </ModalContainer>
  )
}

export default Modal
