import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextAlignCenterGradient } from '../components/generic/View'
import { Heading1, ParagraphLarge } from '../components/generic/Text'

function OfflineInfo() {
  const { t } = useTranslation()

  return (
    <TextAlignCenterGradient heightFull>
      <Heading1>{t('landing.heading')}</Heading1>
      <ParagraphLarge color="black">
        {t('general.errors.device_offline')}
      </ParagraphLarge>
    </TextAlignCenterGradient>
  )
}

export default OfflineInfo
