import React from 'react'
import { useTranslation } from 'react-i18next'

import { ajaxDownloadFile } from '../../../../utils/ajax'

import { Col, Row } from '../../generic/View'
import {
  FileNameParagraph,
  ParagraphSmall,
  StyledLink2
} from '../../generic/Text'

import { ReactComponent as IconPDF } from '../../../../assets/icons/icon_pdf.svg'
import { ReactComponent as IconDOC } from '../../../../assets/icons/icon_doc.svg'
import { ReactComponent as IconFile } from '../../../../assets/icons/icon_file.svg'

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH

function MemoryContentDocument({ content, asset, memoryRoomUserId }) {
  const { t } = useTranslation()

  const fileName = asset.name
  const fileType = fileName.split('.').pop() // Get the last part after the last dot

  let icon
  switch (fileType.toLowerCase()) {
    case 'pdf':
      icon = <IconPDF />
      break
    case 'doc':
    case 'docx':
      icon = <IconDOC />
      break
    default:
      icon = <IconFile />
  }

  const onFileDownload = async () => {
    await ajaxDownloadFile(
      API_BASE_PATH +
        `/memory-room-users/${memoryRoomUserId}/memories/${content.memoryId}/content/${content.id}`
    )
  }

  return (
    <Col pad="16px">
      <Row border={{ color: '#CAD1D8', size: '1px' }} pad="16px" align="center">
        {icon}
        <FileNameParagraph>{fileName}</FileNameParagraph>
        <ParagraphSmall darkBlueGrey style={{ margin: '0' }}>
          <StyledLink2
            to="#"
            underline="true"
            nopad="true"
            whiteSpace="nowrap"
            onClick={() => onFileDownload()}>
            {t('general.download')}
          </StyledLink2>
        </ParagraphSmall>
      </Row>
    </Col>
  )
}

export default MemoryContentDocument
