import React from 'react'
import i18n from '../../../i18n'

import { ROOM_ONBOARDING_MODES } from './enum/RoomOnboardingModes'

export const onboardingSteps = (mode, memoryRoom) => {
  let steps = []

  // Room related steps
  if (
    mode === ROOM_ONBOARDING_MODES.ROOM ||
    mode === ROOM_ONBOARDING_MODES.FULL
  ) {
    steps.push(
      {
        target: '.onboarding__room-info',
        content: i18n.t('memory_room.onboarding.room_info'),
        disableBeacon: true
      },
      {
        target: '.onboarding__room-menu',
        content: i18n.t('memory_room.onboarding.room_menu'),
        disableBeacon: true
      },
      {
        target: '.onboarding__add-memory',
        content: i18n.t('memory_room.onboarding.add_memory'),
        disableBeacon: true
      },
      {
        target: '.onboarding__invite-member',
        content: i18n.t('memory_room.onboarding.invite_member'),
        disableBeacon: true
      }
    )
  }

  // Memory related steps
  if (
    mode === ROOM_ONBOARDING_MODES.MEMORY ||
    mode === ROOM_ONBOARDING_MODES.FULL
  ) {
    steps.push(
      {
        target: '.onboarding__first-memory',
        content: i18n.t('memory_room.onboarding.first_memory'),
        disableBeacon: true
      },
      {
        target: '.onboarding__first-memory-content-count',
        content: i18n.t('memory_room.onboarding.first_memory_content_count'),
        disableBeacon: true
      },
      {
        target: '.onboarding__first-memory-privacy-setting',
        content: i18n.t('memory_room.onboarding.first_memory_privacy_setting'),
        disableBeacon: true
      },
      {
        target: '.onboarding__floating-room-menu',
        content: i18n.t('memory_room.onboarding.floating_room_menu'),
        disableBeacon: true
      }
    )
  }

  // Room related last and first steps
  if (mode === ROOM_ONBOARDING_MODES.ROOM) {
    steps.unshift({
      content: (
        <h3>
          {i18n.t('memory_room.onboarding.room_onboarding_start', {
            name: memoryRoom.belovedName
          })}
        </h3>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true
    })
  }

  return steps
}
