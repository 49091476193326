// return a new object without the key
export function without(obj, key) {
  const res = {}
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && p !== key) {
      res[p] = obj[p]
    }
  }
  return res
}

// find the first item passing the test in an object
// { k: v } -> (v, k -> bool) -> v || undefined
export function first(obj, test) {
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && test(obj[p], p)) {
      return obj[p]
    }
  }
}

// filter object into an array of values passing the test
// { k: v } -> (v, k -> bool) -> [v]
export function filter(obj, test) {
  const res = []
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p) && test(obj[p], p)) {
      res.push(obj[p])
    }
  }
  return res
}

// { k: v } -> (k -> v -> B) -> [B]
export function mapObjectToArray(obj, f) {
  const res = []
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      res.push(f(p, obj[p]))
    }
  }
  return res
}
