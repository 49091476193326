import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { ResponsiveContext } from 'grommet'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CONTENT_TYPES } from '../../../constants/contentTypes'

import { Heading4 } from '../generic/Text'

import videoImg from '../../../assets/img/video_img.svg'
import soundImg from '../../../assets/img/sound_img.svg'
import textImg from '../../../assets/img/text_img.svg'
import navArrowLeft from '../../../assets/icons/nav_arrow_left.svg'
import navArrowRight from '../../../assets/icons/nav_arrow_right.svg'

const SimilarMemories = ({ similarMemories, memoryRoomId, memoryId }) => {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  if (!similarMemories?.length) return null

  return (
    <StyledWrapper
      className="onboarding__more-like-this"
      style={{ width: '100%', marginBottom: '24px', position: 'relative' }}>
      <Heading4>{t('memory.more_memories_like_this_heading')}</Heading4>
      <Arrow className="swiper-button-prev-unique" left />
      <Arrow className="swiper-button-next-unique" right />
      <Swiper
        key={memoryId}
        navigation={
          isDesktop
            ? {
                nextEl: '.swiper-button-next-unique',
                prevEl: '.swiper-button-prev-unique'
              }
            : false
        }
        spaceBetween={12}
        slidesPerView="auto"
        style={{ width: '100%' }}
        preventClicks={true}>
        {similarMemories.map(memory => {
          return (
            <SwiperSlide
              key={memory.id}
              style={{ width: isDesktop ? '120px' : '92px' }}>
              <SimilarMemoryItem memory={memory} memoryRoomId={memoryRoomId} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
  }
`

const Arrow = styled.div`
  display: none;
  ${props => (props.right ? 'right: -56px;' : '')}
  ${props => (props.left ? 'left: -56px;' : '')}
  transition: opacity 100ms, visibility 100ms;
  cursor: pointer;
  position: absolute;
  padding: 12px;
  width: 32px;
  height: 32px;
  background-image: url(${props =>
    props.right ? navArrowRight : navArrowLeft});
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: content-box;
  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    display: block;
  }
`

const SimilarMemoryItem = ({ memory, memoryRoomId }) => {
  let { memoryId } = useParams()

  let imageUrl, type
  if (memory.memoryContent && memory.memoryContent.length) {
    type = memory.memoryContent[0].type
  }
  switch (type) {
    case CONTENT_TYPES.IMAGE:
      imageUrl = memory.memoryContent[0].assets[0].fileUrl
      break
    case CONTENT_TYPES.VIDEO:
      imageUrl = videoImg
      break
    case CONTENT_TYPES.AUDIO:
      imageUrl = soundImg
      break
    default:
      imageUrl = textImg
      break
  }

  return (
    <Link
      to={{
        pathname: `/rooms/${memoryRoomId}/memory/${memory.id}`,
        state: { prevMemoryId: memoryId }
      }}
      style={{
        textDecoration: 'none'
      }}>
      <SimilarMemoryImage imageSrc={imageUrl} type={type} />
      <SimilarMemoryHeading>{memory.name}</SimilarMemoryHeading>
    </Link>
  )
}

const SimilarMemoryImage = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${props => props.theme.global.colors.lightBlueGrey};
  background-image: url(${props => props.imageSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${props =>
    props.type === CONTENT_TYPES.IMAGE ? 'cover' : '35%'};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    height: 83px;
  }
`

const SimilarMemoryHeading = styled.h5`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1.3;
  font-weight: 500;
  margin: 4px 0 0;
  letter-spacing: 0.0015625rem;
  color: ${props => props.theme.global.colors.blackFont};
  @media (min-width: ${props =>
      props.theme.global.breakpoints.medium.value + 1}px) {
    font-size: 0.875rem;
    font-weight: 400;
  }
`

export default SimilarMemories
