import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import memoryReducer from './slices/memorySlice'
import memoryRoomReducer from './slices/memoryRoomSlice'
import userReducer from './slices/userSlice'
import uiReducer from './slices/uiSlice'

const appReducer = combineReducers({
  memory: memoryReducer,
  memoryRoom: memoryRoomReducer,
  user: userReducer,
  ui: uiReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    storage.removeItem('persist:root')
    state = undefined
    localStorage.removeItem('userJWT')
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default configureStore({
  reducer: persistedReducer
})
