import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import ajax, { ajaxWithFiles } from '../../utils/ajax'
import { createQueryString } from '../../utils/generic'

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH

// tool to normalize data {ids, entities}
const memoryAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt) // sort date
})
// create initial state (ids and entities created by default)
const initialState = memoryAdapter.getInitialState({
  ids: [],
  submitting: false,
  error: null
})

// Async Actions
export const create = createAsyncThunk(
  'memory/create',
  async ({ memoryRoomUserId, formData }, thunkAPI) => {
    try {
      const result = await ajaxWithFiles({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories`,
        method: 'POST',
        data: formData
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const update = createAsyncThunk(
  'memory/update',
  async ({ memoryId, memoryRoomUserId, data }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}`,
        method: 'PUT',
        data
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const destroy = createAsyncThunk(
  'memory/destroy',
  async ({ memoryId, memoryRoomUserId }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}`,
        method: 'DELETE'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const fetchMemoriesForMemoryRoomUser = createAsyncThunk(
  'memory/fetchMemoriesForMemoryRoomUser',
  async ({ memoryRoomUserId, searchFilter }, thunkAPI) => {
    const queryString = createQueryString({
      q: searchFilter
    })

    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories${queryString}`,
        method: 'GET'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const fetchMemoryForMemoryRoomUser = createAsyncThunk(
  'memory/fetchMemoryForMemoryRoomUser',
  async (
    { memoryRoomUserId, memoryId, similarMemoriesSize, similarMemoryToSkip },
    thunkAPI
  ) => {
    try {
      const queryString = createQueryString({
        similarMemoriesSize,
        similarMemoryToSkip
      })

      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}${queryString}`,
        method: 'GET'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const createContent = createAsyncThunk(
  'memoryContent/create',
  async ({ memoryId, memoryRoomUserId, formData }, thunkAPI) => {
    try {
      const result = await ajaxWithFiles({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/content`,
        method: 'POST',
        data: formData
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const destroyContent = createAsyncThunk(
  'memoryContent/destroy',
  async ({ memoryContentId, memoryId, memoryRoomUserId }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/memory-content/${memoryContentId}`,
        method: 'DELETE'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const addContentComment = createAsyncThunk(
  'memoryContent/addComment',
  async ({ memoryId, memoryRoomUserId, memoryContentId, data }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/content/${memoryContentId}/comment`,
        method: 'POST',
        data
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const updateContentComment = createAsyncThunk(
  'memoryContent/updateComment',
  async ({ memoryRoomUserId, memoryId, commentId, data }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/comment/${commentId}`,
        method: 'PUT',
        data
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const updateContent = createAsyncThunk(
  'memoryContent/updateContent',
  async ({ memoryRoomUserId, memoryId, contentId, data }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/content/${contentId}`,
        method: 'PUT',
        data
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const destroyContentComment = createAsyncThunk(
  'memoryContent/addComment',
  async ({ memoryRoomUserId, memoryId, contentId, commentId }, thunkAPI) => {
    try {
      const result = await ajax({
        url: `${API_BASE_PATH}/memory-room-users/${memoryRoomUserId}/memories/${memoryId}/content/${contentId}/comment/${commentId}`,
        method: 'DELETE'
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const memorySlice = createSlice({
  name: 'memory',
  initialState,
  reducers: {},
  extraReducers: {
    [create.pending]: state => {
      state.submitting = true
    },
    [create.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [create.rejected]: state => {
      state.submitting = false
    },
    [update.pending]: state => {
      state.submitting = true
    },
    [update.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [update.rejected]: state => {
      state.submitting = false
    },
    [createContent.pending]: state => {
      state.submitting = true
    },
    [createContent.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [createContent.rejected]: state => {
      state.submitting = false
    },
    [destroyContent.pending]: state => {
      state.submitting = true
    },
    [destroyContent.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [destroyContent.rejected]: state => {
      state.submitting = false
    },
    [addContentComment.pending]: state => {
      state.submitting = true
    },
    [addContentComment.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [addContentComment.rejected]: state => {
      state.submitting = false
    },
    [destroyContentComment.pending]: state => {
      state.submitting = true
    },
    [destroyContentComment.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [destroyContentComment.rejected]: state => {
      state.submitting = false
    },
    [fetchMemoriesForMemoryRoomUser.pending]: state => {
      state.submitting = true
    },
    [fetchMemoriesForMemoryRoomUser.fulfilled]: (state, action) => {
      memoryAdapter.setAll(state, action.payload)
      state.submitting = false
    },
    [fetchMemoriesForMemoryRoomUser.rejected]: state => {
      state.submitting = false
    },
    [fetchMemoryForMemoryRoomUser.pending]: state => {
      state.submitting = true
    },
    [fetchMemoryForMemoryRoomUser.fulfilled]: (state, action) => {
      memoryAdapter.upsertOne(state, action.payload)
      state.submitting = false
    },
    [fetchMemoryForMemoryRoomUser.rejected]: state => {
      state.submitting = false
    }
  }
})

// Selectors
export const {
  selectAll: selectAllMemories,
  selectById: selectMemoryById,
  selectIds: selectMemoryIds
} = memoryAdapter.getSelectors(state => state.memory)

export default memorySlice.reducer
