import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, ResponsiveContext, Button } from 'grommet'

import { openModal } from '../../redux/slices/uiSlice'

import viaTheme from '../../styles/via-theme.json'
import {
  TextAlignCenterGradient,
  Screen,
  Col
} from '../components/generic/View'
import { Heading1, Heading2, ParagraphSmall } from '../components/generic/Text'

import { ReactComponent as ImgMemoryRoom } from '../../assets/illustrations/photo_man_dog.svg'
import { ReactComponent as ImgLearningRoom } from '../../assets/illustrations/person_lightbulb.svg'
import { ReactComponent as ImgOnlineCounseling } from '../../assets/illustrations/person_in_computer.svg'

const SectionBlock = ({
  title,
  description,
  img,
  isImgPlacedBottom,
  isReversed
}) => {
  const size = useContext(ResponsiveContext)

  // default values for small and tablet size
  let direction = isReversed ? 'row-reverse' : 'row'
  let justify = isReversed ? 'between' : 'start'
  let blockAlign = 'start'
  let textAlign = 'left'
  let textPadding = { horizontal: '12px' }
  let imageJustify = isImgPlacedBottom ? 'end' : 'center'
  let height, imageWidth

  // values for large size
  if (size === 'large') {
    direction = 'column'
    justify = 'start'
    height = '340px'
    blockAlign = 'center'
    textAlign = 'center'
    imageWidth = '200px'
    imageJustify = 'center'
  }

  if (size === 'small') {
    height = '144px'
    imageWidth = '128px'
  }
  if (size === 'medium') {
    height = '200px'
    imageWidth = '180px'
    textPadding = isReversed
      ? { left: '60px', right: '12px' }
      : { horizontal: '12px' }
  }

  return (
    <Box
      direction={direction}
      background={viaTheme.global.colors.lightGrey}
      height={height}
      width="100%"
      align="center"
      justify={justify}
      pad={size === 'large' ? { vertical: '20px' } : undefined}
      style={{
        borderRadius: '8px',
        boxShadow:
          '0px 2px 6px -2px rgba(0, 26, 25, 0.05), 0px 10px 15px -3px rgba(0, 26, 25, 0.1)'
      }}>
      <Box
        height={size !== 'large' ? '100%' : '200px'}
        width={imageWidth}
        align="center"
        justify={imageJustify}>
        {img}
      </Box>
      <Col align={blockAlign} pad={textPadding}>
        <Heading2
          lineHeight="140%"
          style={{
            textAlign,
            marginBottom: size !== 'small' ? '4px' : 0
          }}>
          {title}
        </Heading2>
        <ParagraphSmall
          lineHeight="130%"
          textAlign={textAlign}
          style={{ margin: 0 }}>
          {description}
        </ParagraphSmall>
      </Col>
    </Box>
  )
}

const Landing = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)

  const isDesktop = size === 'large'

  let blockStyle, headingMargin, blockWidth
  switch (size) {
    case 'small':
      blockStyle = { margin: '16px 0' }
      headingMargin = { marginBottom: '40px' }
      break
    case 'medium':
      blockStyle = { margin: '32px 0' }
      headingMargin = { marginBottom: '95px' }
      break
    case 'large':
      blockStyle = { margin: '0 24px' }
      headingMargin = { marginBottom: '78px' }
      blockWidth = 'calc( 100% - 48px / 3)'
      break
    default:
      blockWidth = '100%'
      break
  }

  let imgStyle1 = {
    small: {
      height: 'auto',
      width: '100%',
      maxWidth: '93px'
    },
    medium: {
      height: '140px',
      width: 'auto'
    },
    large: {
      height: '160px',
      width: 'auto'
    }
  }

  let imgStyle2 = {
    small: {
      height: 'auto',
      width: '100%',
      maxWidth: '115px'
    },
    medium: {
      height: '177px',
      width: 'auto'
    },
    large: {
      height: '150px',
      width: 'auto'
    }
  }

  let imgStyle3 = {
    small: {
      height: 'auto',
      width: '100%',
      maxWidth: '102px'
    },
    medium: {
      height: '100px',
      width: 'auto'
    },
    large: {
      height: '140px',
      width: 'auto'
    }
  }

  const openCommentPrivacyModal = () => {
    dispatch(
      openModal({
        type: 'openOnlineCounseling'
      })
    )
  }

  return (
    <Screen>
      <TextAlignCenterGradient>
        <div style={{ width: '100%', maxWidth: '1100px' }}>
          <div style={{ ...headingMargin }}>
            <Heading1
              white
              fontWeight="500"
              style={{
                lineHeight: '150%',
                fontSize: isDesktop ? '40px' : '32px',
                marginTop: isDesktop ? 'initial' : '0'
              }}>
              {t('landing.heading')}
            </Heading1>
            <Heading2
              white
              style={{
                fontSize: isDesktop ? '22px' : '18px',
                lineHeight: '130%',
                fontWeight: '500',
                marginBottom: '40px',
                maxWidth: '100%'
              }}>
              {t('landing.subheading')}
            </Heading2>
          </div>
          <Box direction={size === 'large' ? 'row' : 'column'} width="100%">
            <Link to={`/memory`} style={{ width: blockWidth }}>
              <SectionBlock
                title={t('memory_room.heading')}
                description={t('memory_room.subheading')}
                img={<ImgMemoryRoom style={imgStyle1[size]} />}></SectionBlock>
            </Link>
            <Link to={`/learning`} style={{ ...blockStyle, width: blockWidth }}>
              <SectionBlock
                title={t('learning_room.heading')}
                description={t('learning_room.subheading')}
                img={<ImgLearningRoom style={imgStyle2[size]} />}
                isImgPlacedBottom
                isReversed={true}></SectionBlock>
            </Link>
            <Button
              type="button"
              onClick={openCommentPrivacyModal}
              style={{ width: blockWidth }}>
              <SectionBlock
                title={t('grief_counselling.heading')}
                description={t('grief_counselling.subheading')}
                img={
                  <ImgOnlineCounseling style={imgStyle3[size]} />
                }></SectionBlock>
            </Button>
          </Box>
        </div>
      </TextAlignCenterGradient>
    </Screen>
  )
}

export default Landing
