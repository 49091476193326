const PRIVACY_SETTINGS = {
  CHOOSE_INDIVIDUALLY: 'choose_individually',
  PRIVATE: 'private',
  PUBLIC: 'public'
}

const PRIVACY_SETTINGS_VALUES = []
for (const k in PRIVACY_SETTINGS) {
  PRIVACY_SETTINGS_VALUES.push(PRIVACY_SETTINGS[k])
}

module.exports = {
  PRIVACY_SETTINGS,
  PRIVACY_SETTINGS_VALUES
}
