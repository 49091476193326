import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import { useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectUser } from '../../redux/slices/userSlice'

import { useQuery } from '../../utils/generic'

import PreparedHelmet from '../components/seo/PreparedHelmet'
import * as Button from '../components/generic/Button'
import { Tooltip } from '../components/generic/Tooltip'

import { ReactComponent as IconInfo } from '../../assets/icons/icon_info.svg'

import {
  TextAlignCenterGradient,
  Illustration,
  Screen
} from '../components/generic/View'
import {
  Heading1,
  Heading2,
  ParagraphLarge,
  ParagraphSmall
} from '../components/generic/Text'

import loginIcon from '../../assets/illustrations/man_dog_woman.svg'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const Landing = () => {
  const { t } = useTranslation()

  const user = useSelector(selectUser)
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const query = useQuery()
  const inviteToken = query.get('invite')

  if (user) {
    return <Navigate to="/home" replace />
  }

  const getParams = inviteToken ? `?invite=${inviteToken}` : ''

  return (
    <>
      <PreparedHelmet
        title={t('memory_room.heading')}
        description={t('memory_room.landing_text')}
        titleHasAppName={true}
      />

      <Screen>
        <TextAlignCenterGradient>
          <div style={{ maxWidth: '745px' }}>
            <Heading1
              white
              fontWeight="500"
              style={{
                lineHeight: '150%',
                fontSize: isDesktop ? '40px' : '32px',
                marginTop: isDesktop ? 'initial' : '0'
              }}>
              {t('landing.heading')}
            </Heading1>
            <Heading2
              white
              style={{
                fontSize: isDesktop ? '22px' : '18px',
                lineHeight: '130%',
                fontWeight: '500',
                marginBottom: '40px'
              }}>
              {t('landing.subheading')}
            </Heading2>
            <Illustration src={loginIcon} maxwidth="466px" />
            <ParagraphLarge color="white">
              {t('memory_room.landing_text')}
            </ParagraphLarge>

            {!isAppSunsettingMode && (
              <Link to={`/create-account${getParams}`}>
                <Button.Primary
                  type="button"
                  data-testid="register-button"
                  label={t('landing.register')}
                />
              </Link>
            )}

            {isAppSunsettingMode && (
              <Tooltip
                id={`registration-tooltip`}
                text={t('landing.not_accepting_registrations')}>
                <Button.Primary
                  disabled
                  type="button"
                  data-testid="register-button"
                  style={{
                    background: '#C5CFD6',
                    opacity: '1',
                    color: '#7C92A2'
                  }}
                  label={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                      {t('landing.register')}
                      <IconInfo />
                    </span>
                  }
                />
              </Tooltip>
            )}

            <ParagraphSmall white="true">
              {t('landing.is_registered')}
            </ParagraphSmall>

            <Button.Link
              to={`/login${getParams}`}
              color="white"
              data-testid="login-button">
              {t('landing.link')}
            </Button.Link>
          </div>
        </TextAlignCenterGradient>
      </Screen>
    </>
  )
}

export default Landing
