import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, ResponsiveContext } from 'grommet'
import { STATUS, LIFECYCLE } from 'react-joyride'

import { setToast } from '../../../utils/status'
import { scrollWindow } from '../../../utils/browser'
import { onboardingSteps } from './onboardingSteps'
const { ROLES } = require('../../../constants/memoryRoomUserRoles')
import { ROOM_ONBOARDING_MODES } from './enum/RoomOnboardingModes'
import {
  selectUser,
  update as updateUser
} from '../../../redux/slices/userSlice'
import { updateMemoryRoomUserNotificationState } from '../../../redux/slices/memoryRoomSlice'
import {
  openModal,
  closeModal,
  selectTemporaryData
} from '../../../redux/slices/uiSlice'

import {
  Screen,
  Section,
  SectionContent,
  Row
} from '../../components/generic/View'
import { ThematicBreak } from '../../components/generic/Elements'
import { Heading1, Paragraph, StyledLink } from '../../components/generic/Text'
import * as Button from '../../components/generic/Button'
import * as Icon from '../../components/generic/Icon'
import OnboardingTour from '../../components/OnboardingTour'
import MemoryListItem from '../../components/MemoryListItem'
import { ShowMore } from '../../components/generic/ShowMore'
import {
  MemoryImg,
  MemoryCardWrap,
  MemoryCardBox
} from '../../components/memoryRoom/memoryRoomElements'
import MemoryRoomMenu from '../../components/memoryRoom/MemoryRoomMenu'
import { Tooltip } from '../generic/Tooltip'
import { ReactComponent as IconInfo } from '../../../assets/icons/icon_info.svg'

import placeholder from '../../../assets/img/placeholder_img.svg'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const MemoryRoom = ({
  memoryRoom,
  memoryRoomUser,
  memories,
  pageSteps,
  setCurrentStepWithScroll,
  memoriesExist
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelector(selectUser)
  const tempData = useSelector(selectTemporaryData)

  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const isMemoryRoomOwner = memoryRoomUser.role === ROLES.OWNER
  const searchFilterIsApplied = tempData?.roomViewSettings?.memoryFilter?.search

  const header = {
    type: 'menu',
    withBackground: true
  }

  let roomImage,
    roomImageMargin = 0
  if (memoryRoom?.assets?.length > 0) {
    roomImage = memoryRoom.assets[0].fileUrl
    if (size === 'large') roomImageMargin = 56
    if (size === 'medium') roomImageMargin = 28
  }

  // ONBOARDING TOUR
  const [tourRun, setTourRun] = useState(true)
  const [showFullTour, setShowFullTour] = useState(false)

  let tourMode,
    tourSteps,
    allowCloseTour = false
  // If user was not onboarded at all and there are NO memories, show room onboarding
  if (
    !user.onboardedToRoom &&
    !user.onboardedToRoomWithMemories &&
    !memoriesExist
  ) {
    tourMode = ROOM_ONBOARDING_MODES.ROOM
  }
  // If user was not onboarded at all and there are memories, show full onboarding
  else if (
    !user.onboardedToRoom &&
    !user.onboardedToRoomWithMemories &&
    memoriesExist
  ) {
    tourMode = ROOM_ONBOARDING_MODES.FULL
  }
  // If user was onboarded only to room and there are memories, show memories onboarding
  else if (
    user.onboardedToRoom &&
    !user.onboardedToRoomWithMemories &&
    memoriesExist
  ) {
    tourMode = ROOM_ONBOARDING_MODES.MEMORY
  }
  // If user was onboarded, but wants to watch onboarding again
  else if (showFullTour) {
    tourMode = ROOM_ONBOARDING_MODES.FULL
    allowCloseTour = true
  }

  if (tourMode) {
    // If user requested full tour, but there are no memories,
    // we still should show tour for room only
    const curMode =
      tourMode === ROOM_ONBOARDING_MODES.FULL && !memoriesExist
        ? ROOM_ONBOARDING_MODES.ROOM
        : tourMode
    tourSteps = onboardingSteps(curMode, memoryRoom)
  }

  const handleOnboardingCallback = data => {
    const { status, lifecycle } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      // Update user onboarding status when onboarding was watched
      if (lifecycle === LIFECYCLE.COMPLETE && !showFullTour) {
        let updateProp = {}
        if (tourMode === ROOM_ONBOARDING_MODES.FULL) {
          updateProp[ROOM_ONBOARDING_MODES.ROOM] = true
          updateProp[ROOM_ONBOARDING_MODES.MEMORY] = true
        } else {
          updateProp[tourMode] = true
        }

        dispatch(
          updateUser({
            userId: user.id,
            formData: updateProp
          })
        )
      }
      setTourRun(false)
      setShowFullTour(false)
      scrollWindow(true, false)
    }
  }

  // ACTIONS
  const handleUserNotifications = async getNotifications => {
    if (getNotifications) {
      dispatch(
        openModal({
          type: 'memoryRoomUserUnfollow',
          props: { memoryRoomUser }
        })
      )
    } else {
      const result = await dispatch(
        updateMemoryRoomUserNotificationState({
          memoryRoomUserId: memoryRoomUser.id,
          getNotifications: !getNotifications
        })
      )

      if (result.meta?.requestStatus === 'rejected') {
        if (result.payload !== null) {
          setToast(result.payload.title, 'error')
        }
      }
      if (result.meta?.requestStatus === 'fulfilled') {
        setToast(t('memory_room.user_started_following'), 'success')
      }
    }
  }

  const onOpenMemoryRoomSettings = () => {
    const modalName = isMemoryRoomOwner
      ? 'memoryRoomSettingsOwner'
      : 'memoryRoomSettingsContributor'

    dispatch(
      openModal({
        type: modalName,
        props: {
          user,
          memoryRoom,
          memoryRoomUser,
          onboardingAction: () => {
            dispatch(closeModal())
            setShowFullTour(true)
            setTourRun(true)
          }
        }
      })
    )
  }

  const onOpenMemoryPrivacyModal = () => {
    dispatch(
      openModal({
        type: 'memoryPrivacySetting',
        props: { memoryRoomId: memoryRoom.id }
      })
    )
  }

  let roomName, roomDescription
  if (memoryRoom.creatorIsDeactivated) {
    roomName = t('memory_room.deleted_room_name_placeholder')
    roomDescription = t('memory_room.deleted_room_description_placeholder')
    roomImage = undefined
  } else {
    roomName = memoryRoom.belovedName
    roomDescription = memoryRoom.belovedDescription
  }

  return (
    <Screen header={header}>
      <Section
        columnItem
        textAlign="left"
        justify="start"
        style={{ marginTop: '7px' }}>
        {tourMode && (
          <OnboardingTour
            callback={handleOnboardingCallback}
            run={tourRun}
            steps={tourSteps}
            allowClose={allowCloseTour}
          />
        )}
        {(memoriesExist || searchFilterIsApplied) && (
          <MemoryRoomMenu
            isMemoryRoomOwner={isMemoryRoomOwner}
            memoryRoom={memoryRoom}
            pageSteps={pageSteps}
            setCurrentStepWithScroll={setCurrentStepWithScroll}
          />
        )}
        <div className="onboarding__room-info">
          <Box
            width={isDesktop ? '984px' : { max: '872px' }}
            height={{ max: ' 543px' }}
            pad={{ horizontal: isDesktop ? 'large' : '0' }}
            style={{ position: 'relative' }}>
            <img
              src={roomImage || placeholder}
              alt="room image"
              style={{
                height: '100%',
                maxHeight: '543px',
                width: '100%',
                objectFit: 'contain',
                zIndex: 1
              }}
            />
            {roomImage && (
              <div
                style={{
                  width: `calc(100% - ${roomImageMargin * 2}px)`,
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  margin: `0 ${roomImageMargin}px`,
                  overflow: 'hidden'
                }}>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundImage: roomImage
                      ? `url(${roomImage})`
                      : undefined,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    filter: roomImage ? 'blur(20px)' : undefined
                  }}></div>
              </div>
            )}
          </Box>
          <SectionContent onecol style={{ paddingBottom: 0 }}>
            <Row width="100%" align="center">
              <Heading1
                id="memory_room_name"
                data-testid="beloved-name-heading"
                style={{
                  flex: '1'
                }}>
                {roomName}
              </Heading1>
              <Icon.ThreeDotsInCircle
                className="onboarding__room-menu"
                style={{ cursor: 'pointer' }}
                onClick={() => onOpenMemoryRoomSettings()}
              />
            </Row>

            <Button.White
              grey={!memoryRoomUser.getNotifications}
              small
              alignSelf="start"
              style={{ margin: '20px 0' }}
              label={
                memoryRoomUser.getNotifications
                  ? t('memory_room.user_follows')
                  : t('memory_room.start_follow')
              }
              onClick={() =>
                handleUserNotifications(memoryRoomUser.getNotifications)
              }
            />

            <Paragraph width="100%" testid="room-description" margin="0">
              <ShowMore str={roomDescription} />
            </Paragraph>
          </SectionContent>
        </div>
        <SectionContent onecol style={{ paddingTop: 0 }}>
          <ThematicBreak />

          <Box
            align="center"
            margin={{
              bottom: isDesktop ? '80px' : '40px',
              top: isDesktop ? '40px' : '10px'
            }}>
            {!isAppSunsettingMode && (
              <Button.Primary
                className="onboarding__add-memory"
                data-testid="add-memory"
                label={t('memory_room.add_memory')}
                margin="0"
                style={{ width: '100%' }}
                onClick={() => onOpenMemoryPrivacyModal()}
              />
            )}

            {isAppSunsettingMode && (
              <Tooltip
                id={`content-button-tooltip`}
                text={t('memory_room.new_content_disabled')}>
                <Button.Primary
                  disabled
                  type="button"
                  className="onboarding__add-memory"
                  data-testid="add-memory"
                  margin="0"
                  style={{
                    width: '100%',
                    background: '#C5CFD6',
                    opacity: '1',
                    color: '#7C92A2'
                  }}
                  label={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                      {t('memory_room.add_memory')}
                      <IconInfo />
                    </span>
                  }
                />
              </Tooltip>
            )}

            {isMemoryRoomOwner && !isAppSunsettingMode && (
              <StyledLink
                to={`/rooms/${memoryRoom.id}/invite-member`}
                style={{ width: '100%' }}>
                <Button.White
                  className="onboarding__invite-member"
                  label={t('memory_room.invite_member')}
                  style={{ width: '100%' }}
                />
              </StyledLink>
            )}

            {isAppSunsettingMode && (
              <div style={{ width: '100%', height: '20px' }}></div>
            )}

            {isMemoryRoomOwner && isAppSunsettingMode && (
              <Tooltip
                id={`invite-button-tooltip`}
                text={t('memory_room.invitation_disabled')}>
                <Button.White
                  disabled
                  type="button"
                  className="onboarding__invite-member"
                  style={{
                    width: '100%',
                    background: '#ffffff',
                    opacity: '1',
                    color: '#7C92A2'
                  }}
                  label={
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}>
                      {t('memory_room.invite_member')}
                      <IconInfo />
                    </span>
                  }
                />
              </Tooltip>
            )}
          </Box>

          {memories.map((memory, ind) => {
            return (
              <MemoryListItem
                key={memory.id}
                isFirstItem={ind === 0}
                memory={memory}
                memoryRoomId={memoryRoom.id}
              />
            )
          })}

          {!isAppSunsettingMode && memoriesExist && (
            <MemoryCardWrap
              onClick={() => onOpenMemoryPrivacyModal()}
              style={{ cursor: 'pointer' }}>
              <MemoryImg src="" width="100px" add />
              <MemoryCardBox noSidePad>
                <Box pad="20px 0">
                  <Button.Primary
                    label={t('memory_room.add_memory')}
                    margin="0"
                    pad="1rem"
                  />
                </Box>
              </MemoryCardBox>
            </MemoryCardWrap>
          )}
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default MemoryRoom
