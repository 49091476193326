import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'

import { Row } from '../../generic/View'

const imageWidth = {
  one_quarter: '25%',
  half: '50%',
  three_quarters: '75%',
  full: '100%'
}

const FullScreenImage = ({ component }) => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const src = component?.image?.data?.attributes?.url
  if (!src) return null

  const description = component?.shortDescription
  const desktopWidth = imageWidth[component?.widthOnDesktop] || '100%'

  const componentWidth = isDesktop ? desktopWidth : '100%'

  return (
    <Row justify="center">
      <img
        alt={description}
        src={src}
        style={{ width: componentWidth, height: 'auto' }}
      />
    </Row>
  )
}

export default FullScreenImage
