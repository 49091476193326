import { Box, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import viaTheme from '../../../../styles/via-theme.json'

function MemoryContentVideo({ asset }) {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)

  const isDesktop = size === 'large'

  return (
    <Box style={{ background: viaTheme.global.colors.darkBlueGrey }}>
      <video
        style={{
          maxWidth: '100%',
          margin: '0 auto',
          height: 'auto',
          maxHeight: isDesktop ? '545px' : '400px'
        }}
        controls>
        <source src={asset.fileUrl} type={asset.contentType} />
        {t('media.not_supported', { type: 'video' })}
      </video>
    </Box>
  )
}

export default MemoryContentVideo
