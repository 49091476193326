import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { openModal } from '../../../redux/slices/uiSlice'
import { selectUser } from '../../../redux/slices/userSlice'

import { Row } from '../generic/View'
import * as Button from '../generic/Button'
import * as Icon from '../generic/Icon'
import { StyledLink, ParagraphSmall } from '../generic/Text'
import { RoomListItemWrap } from '../memoryRoom/memoryRoomElements'

import placeholder from '../../../assets/img/default_avatar.svg'
import viaTheme from '../../../styles/via-theme.json'

const MemoryRoomListItem = ({ roomData }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelector(selectUser)
  const memoryRoomUser = roomData.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )

  const onOpenMemoryRoomSettings = event => {
    event.stopPropagation()
    event.preventDefault()
    dispatch(
      openModal({
        type: 'memoryRoomSettingsContributor',
        props: { memoryRoomUser, memoryRoom: roomData }
      })
    )
  }

  let roomText, roomImage
  if (roomData.creatorIsDeactivated) {
    roomText = t('memory_room.deleted_room_name_placeholder')
    roomImage = placeholder
  } else {
    roomText = roomData.belovedName
    roomImage =
      roomData.assets && roomData.assets.length > 0
        ? roomData.assets[0].fileUrl
        : placeholder
  }

  return (
    <RoomListItemWrap>
      {' '}
      <StyledLink to={`/rooms/${roomData.id}`}>
        <Button.MemoryRoom
          type="button"
          data-testid="room-list-item-button-room-create"
          label={
            <Row align="center" justify="between" width="100%">
              <Row align="center">
                <img
                  src={roomImage}
                  alt="room image"
                  height={38}
                  width={38}
                  style={{
                    background: viaTheme.global.colors.lightBlueGrey,
                    borderRadius: '8px',
                    marginRight: '15px',
                    objectFit: 'cover'
                  }}
                />

                <ParagraphSmall textAlign="left" semiBold>
                  {roomText}
                </ParagraphSmall>
              </Row>
              <div
                onClick={onOpenMemoryRoomSettings}
                style={{
                  display: 'flex',
                  padding: '.3rem 1rem'
                }}>
                <Icon.ThreeDotsVertical />
              </div>
            </Row>
          }
        />
      </StyledLink>
    </RoomListItemWrap>
  )
}

export default MemoryRoomListItem
