import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../../redux/slices/uiSlice'

import { Col } from '../../../generic/View'
import { ModalMenuItem } from '../../../generic/Elements'

const memoryContentSettings = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userIsMemoryContentOwner =
    props.modalProps.memoryRoomUserId ===
    props.modalProps.content.memoryRoomUserId
  const onOpenModal = (modalName, fullWidth = false) => {
    dispatch(openModal({ type: modalName, props: props.modalProps, fullWidth }))
  }

  return (
    <Col style={{ ...props.style }}>
      {userIsMemoryContentOwner && (
        <ModalMenuItem
          title={t('general.edit_privacy')}
          onClick={() => onOpenModal('contentPrivacySetting')}
        />
      )}
      <ModalMenuItem
        title={t('general.delete')}
        onClick={() => onOpenModal('memoryContentDelete')}
      />
    </Col>
  )
}

export default memoryContentSettings
