import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Screen, Section, SectionContent } from '../components/generic/View'
import IllustrationBlock from '../components/IllustrationBlock'
import PasswordResetForm from '../components/forms/PasswordResetForm'
import womanWithKey from '../../assets/illustrations/woman_with_key.svg'

import { setToast } from '../../utils/status'
import { handleBackButton } from '../../utils/generic'
import { selectUser, changePassword } from '../../redux/slices/userSlice'
import { selectHeader } from '../../redux/slices/uiSlice'

function ProfilePasswordChange() {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    const result = await dispatch(
      changePassword({
        userId: user.id,
        data: data
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      navigate('/profile')
      setToast(t('profile.password_successfully_reset'), 'success')
    }
  }

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () => navigate('/profile'))
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('profile.reset_password'),
    withBackground: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <IllustrationBlock img={womanWithKey} />
      <Section>
        <SectionContent>
          <PasswordResetForm
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default ProfilePasswordChange
