import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'

import { Heading2, ParagraphLarge } from '../../components/generic/Text'

const HeroImage = styled.img`
  height: auto;
  margin-bottom: ${props => {
    if (props.size === 'small') return '24px'
    if (props.size === 'medium') return '32px'
    if (props.size === 'large') return '40px'
  }};
  object-fit: cover;
  width: 100%;
`

const Hero = ({ heading, description, imgSrc, imgAlt }) => {
  const size = useContext(ResponsiveContext)

  imgAlt = imgAlt || 'Hero image'
  return (
    <>
      {heading && (
        <Heading2 style={{ marginBottom: '12px' }}>{heading}</Heading2>
      )}
      {description && (
        <ParagraphLarge margin="0 0 32px 0">{description}</ParagraphLarge>
      )}
      {imgSrc && <HeroImage alt={imgAlt} size={size} src={imgSrc} />}
    </>
  )
}

export default Hero
