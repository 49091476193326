import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, ResponsiveContext } from 'grommet'

const ROLES = require('../../../../constants/memoryRoomUserRoles').ROLES

import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import { Col } from '../../generic/View'
import { MemoryRoomUser } from './memoryRoomUser'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const memoryRoomUsers = props => {
  const { t } = useTranslation()

  const { memoryRoom, memoryRoomUser, adminSelection } = props.modalProps

  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const modalText = adminSelection
    ? 'memory_room.choose_new_admin'
    : 'memory_room.members'

  return (
    <Col style={{ ...props.style }}>
      <Paragraph>{t(modalText)}</Paragraph>

      <Box
        direction={isDesktop ? 'row' : 'column'}
        wrap={isDesktop}
        margin={{ bottom: 'medium' }}>
        {memoryRoom.memoryRoomUsers.map((roomUser, index) => {
          if (adminSelection && memoryRoomUser.id === roomUser.id) {
            return null
          }

          if (roomUser.user.deactivatedAt) return null

          const userIsCreator =
            memoryRoom.creatorId === roomUser.userId &&
            roomUser.role === ROLES.OWNER
          const memoryRoomUserIsOwner =
            memoryRoomUser.role === ROLES.OWNER && !userIsCreator

          let displayMenu =
            memoryRoomUserIsOwner && memoryRoomUser.id !== roomUser.id

          let margin = { bottom: 'small' }
          if (isDesktop && (index + 1) % 3 === 2) {
            margin['horizontal'] = '15px'
          }
          return (
            <MemoryRoomUser
              key={roomUser.id}
              adminSelection={adminSelection}
              basis={isDesktop ? 'calc(33.3% - 10px)' : 'full'}
              margin={margin}
              memoryRoomUser={memoryRoomUser}
              memoryRoom={memoryRoom}
              creatorId={memoryRoom.creatorId}
              displayMenu={displayMenu}
              roomOwnerId={memoryRoomUser.id}
              roomUser={roomUser}
              user={props.modalProps.user}
            />
          )
        })}
      </Box>

      {!isAppSunsettingMode && !adminSelection && (
        <Link
          to={`/rooms/${memoryRoom.id}/invite-member`}
          style={{ textAlign: 'center' }}>
          <Button.White type="button" label={t('memory_room.invite_member')} />
        </Link>
      )}
    </Col>
  )
}

export default memoryRoomUsers
