import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { openModal } from '../../../redux/slices/uiSlice'

const memoryRoomUserConstants = require('../../../constants/memoryRoomUserRoles')
const { OWNER } = memoryRoomUserConstants.ROLES

import { Row, Col } from '../generic/View'
import * as Icon from '../generic/Icon'
import { InfoBlock, AuthorInfo } from '../generic/Elements'
import { TextSmall } from '../generic/Text'
import { Tooltip } from '../generic/Tooltip'
import PreparedMemoryContent from './content/PreparedMemoryContent'

import viaTheme from '../../../styles/via-theme.json'
import { Box, ResponsiveContext } from 'grommet'

function MemoryContentItem({
  content,
  memoryRoomUserId,
  memoryRoom,
  displayMenu,
  isFirstItem,
  commentsIsLink,
  onOpenLightBox
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const size = useContext(ResponsiveContext)

  const onOpenMemoryContentSettings = () => {
    dispatch(
      openModal({
        type: 'memoryContentSettings',
        props: {
          memoryRoomId: memoryRoom.id,
          memoryId: content.memoryId,
          memoryContentId: content.id,
          memoryRoomUserId,
          content
        }
      })
    )
  }

  let viewCommentsKey
  if (commentsIsLink) {
    viewCommentsKey = content?.comments?.length
      ? 'memory.comments.view_comments'
      : 'memory.comments.no_comments'
  } else {
    viewCommentsKey = 'memory.comments.comments_count'
  }

  const privacy = {
    text: content.private
      ? t('memory.privacy.memory_is_private')
      : t('memory.privacy.memory_is_public'),
    icon: content.private ? (
      <Icon.Lock style={{ color: '#02CBC5', height: '20px' }} />
    ) : (
      <Icon.Group style={{ color: '#475662', height: '20px' }} />
    )
  }

  // used for onboarding-tour
  let privacySettingClass, menuClassName
  if (isFirstItem) {
    privacySettingClass = 'onboarding__first-content-privacy'
    menuClassName = 'onboarding__first-content-menu'
  }

  const commentBlock = (
    <Row
      justify="between"
      align="center"
      className="onboarding__first-content-comments"
      height="56px"
      pad={{
        horizontal:
          size === 'small' ? '14px' : size === 'medium' ? '28px' : '25px'
      }}>
      {commentsIsLink && (
        <>
          <Box style={{ flex: '0 0 25px' }}>
            <Icon.Message />
          </Box>
          <TextSmall
            color={viaTheme.global.colors.blackFont}
            fontWeight="600"
            style={{
              padding: '0 4% 0 2%',
              flexShrink: 0
            }}>
            {t('memory.comments.add_comment')}
          </TextSmall>
        </>
      )}
      <TextSmall
        color="#475662"
        style={{
          paddingRight: '2%',
          flexGrow: '1'
        }}>
        {t(viewCommentsKey, {
          count: content?.comments?.length || 0
        })}
      </TextSmall>
      <Tooltip
        id={`memory-info-${content.id}`}
        text={privacy.text}
        className={privacySettingClass}>
        {privacy.icon}
      </Tooltip>
    </Row>
  )

  const commentSection = commentsIsLink ? (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/rooms/${memoryRoom.id}/memory/${content.memoryId}/content/${content.id}`}>
      {commentBlock}
    </Link>
  ) : (
    commentBlock
  )

  const contentUser = content.creatorIsDeactivated
    ? undefined
    : content[OWNER]?.user

  return (
    <InfoBlock key={content.id}>
      <AuthorInfo
        user={contentUser}
        date={content.createdAt}
        displayMenu={displayMenu}
        menuClassName={menuClassName}
        onMenuClick={() => onOpenMemoryContentSettings()}
      />
      <Col style={{ minHeight: 'fit-content', wordBreak: 'break-word' }}>
        <PreparedMemoryContent
          memoryRoomUserId={memoryRoomUserId}
          content={content}
          memoryRoom={memoryRoom}
          onOpenLightBox={onOpenLightBox}
        />
        {commentSection}
      </Col>
    </InfoBlock>
  )
}

export default MemoryContentItem
