import React, { useContext } from 'react'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

const VideoWrapper = styled.div`
  height: ${props => {
    if (props.size === 'small') return '195px'
    if (props.size === 'medium') return '400px'
    if (props.size === 'large') return '490px'
  }};
  margin-bottom: ${props => {
    if (props.size === 'large') {
      return '20px'
    } else {
      return '10px'
    }
  }};
  width: 100%;
`

const Video = ({ component }) => {
  const size = useContext(ResponsiveContext)

  let videoUrl
  if (component?.url) {
    videoUrl = component?.url
  } else if (component?.file?.data?.attributes?.url) {
    videoUrl = component?.file?.data?.attributes?.url
  }

  if (!videoUrl) return null

  return (
    <>
      <VideoWrapper size={size}>
        <ReactPlayer
          url={videoUrl}
          height="100%"
          width="100%"
          controls={true}
          config={{
            youtube: {
              embedOptions: { rel: 0 }
            },
            file: {
              forceVideo: true
            }
          }}
        />
      </VideoWrapper>
    </>
  )
}

export default Video
