import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectHeader } from '../../redux/slices/uiSlice'
import { selectMemoryRoomById } from '../../redux/slices/memoryRoomSlice'
import { selectUser } from '../../redux/slices/userSlice'

import { handleBackButton } from '../../utils/generic'
const { OWNER } = require('../../constants/memoryRoomUserRoles').ROLES

import { Screen, Section, SectionContent } from '../components/generic/View'
import { ThematicBreak } from '../components/generic/Elements'
import IllustrationBlock from '../components/IllustrationBlock'
import inviteMember from '../../assets/illustrations/invite_a_member.svg'
import InviteMemberForm from '../components/forms/InviteMemberForm'
import OpenInviteMemberForm from '../components/forms/OpenInviteMemberForm'

function InviteMember() {
  const navigate = useNavigate()

  const { roomId } = useParams()
  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )

  if (!memoryRoom) {
    navigate('/home')
  }

  const user = useSelector(selectUser)
  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )

  if (!memoryRoomUser || memoryRoomUser.role !== OWNER) {
    navigate('/home')
  }

  const { t } = useTranslation()
  const { pathname } = useLocation()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () =>
      navigate(`/rooms/${memoryRoomId}`)
    )
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('memory_room.invitation_form.invite_member'),
    multiColor: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <IllustrationBlock
        background
        paragraph={t('memory_room.invitation_form.instruction')}
        paragraphBlackOnMobile={true}
        img={inviteMember}
        textlast="true"
      />
      <Section>
        <SectionContent>
          <InviteMemberForm
            memoryRoom={memoryRoom}
            memoryRoomUser={memoryRoomUser}
          />
          <ThematicBreak />
          <OpenInviteMemberForm memoryRoomUser={memoryRoomUser} />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default InviteMember
