import { mapObjectToArray } from './object'
import { setToast } from '../utils/status'
import i18n from '../i18n'

function throwErrorCantParseJson() {
  const err = new Error('Failed to parse json')
  err.errors = { general: 'Failed to parse json' }
  throw err
}

function urlParams(obj) {
  return '?' + mapObjectToArray(obj, (k, v) => k + '=' + v).join('&')
}

async function fetchProcess(url, query) {
  // make request
  const response = await fetch(url, query)
  const { status } = response
  let body

  // parse json body
  // handle error and success
  try {
    body = await response.json()
  } catch (err) {
    console.log('STATUS ERR: ' + status)
    if (status === 401) {
      console.log('\n\n ERROR: Not Authorized')
    }
    throwErrorCantParseJson()
  }

  // if server error, throw
  if (status === 500) {
    console.log('\n\n ERROR: ' + body.title + JSON.stringify(body.detail))
    throw body || 'The service is temporarily down, please try again shortly'
  }

  // if client error, throw
  if (status < 200 || status >= 400) {
    console.log('\n\n ERROR: ' + body.title + JSON.stringify(body.detail))
    // handle token expired
    if (body.name === 'TokenExpiredError') {
      const message = i18n.t('general.token_expired')
      setToast(message, 'info')
    }
    throw body || i18n.t('general.something_wrong')
  }
  // if success, return body
  return body
}

// always sends and expects json
export default async function ajax({ url, method, data, isPublicRequest }) {
  method = method || 'GET'

  const headers = {
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=UTF-8'
  }

  if (!isPublicRequest) {
    // look for jwt in session storage
    const userJWT = localStorage.getItem('userJWT')
    if (userJWT) {
      headers.Authorization = 'Bearer ' + userJWT
    }
  }

  const query = {
    method,
    headers
  }

  // attach data to url or body
  if (data) {
    if (method === 'GET') {
      url += urlParams(data)
    } else {
      query.body = JSON.stringify(data)
    }
  }

  const response = await fetchProcess(url, query)
  return response
}

// sends multipart/form-data and expects json
export async function ajaxWithFiles({ url, data }) {
  let headers = {
    // It is multipart/form-data, but if it is provided boundary needs to be calculated.
    // If it is omitted headers are set automatically
    // 'Content-Type': 'multipart/form-data'
  }

  const userJWT = localStorage.getItem('userJWT')
  if (userJWT) {
    headers.Authorization = 'Bearer ' + userJWT
  }

  const response = await fetchProcess(url, {
    method: 'POST',
    headers: headers,
    body: data
  })
  return response
}

export async function ajaxDownloadFile(url) {
  let headers = {}
  const userJWT = localStorage.getItem('userJWT')
  if (userJWT) {
    headers.Authorization = 'Bearer ' + userJWT
  }

  // handle error and success
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers
    })

    if (response.ok === false) {
      throw new Error('Error on file download')
    }

    const blob = await response.blob()

    // Create a blob URL and trigger download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)

    const contentDispositionHeader = response.headers.get('content-disposition')
    const filenameMatch = contentDispositionHeader.match(/filename="(.+)"/)
    const filename = filenameMatch ? filenameMatch[1] : 'downloaded-file'

    link.download = filename
    link.click()
  } catch (err) {
    console.log('Error', err)
  }
}
