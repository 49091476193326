import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik } from 'formik'

import { update } from '../../../../redux/slices/userSlice'
import { closeModal } from '../../../../redux/slices/uiSlice'

import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import * as Button from '../../generic/Button'
import * as Input from '../../generic/Input'

import { Col } from '../../generic/View'

import {
  APP_LANGUAGES,
  APP_LANGUAGES_OPTIONS
} from '../../../../constants/appLanguages'

const profileChangeLanguage = props => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [submitting, setSubmitting] = useState(false)

  const user = props.modalProps.user

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(
      update({
        userId: user.id,
        formData: formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      await i18n.changeLanguage(result.payload.language)
      setToast(t('profile.language_successfully_changed'), 'success')
    }
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph>{t('general.language')}</Paragraph>

      <Formik
        initialValues={{ language: user?.language || APP_LANGUAGES.de.value }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm>
            <Input.StyledSelect
              name="language"
              options={APP_LANGUAGES_OPTIONS}
            />
            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.save')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
    </Col>
  )
}

export default profileChangeLanguage
