export const QUESTIONS = {
  0: {
    id: 0,
    text: 'inspire_me.their_character'
  },
  1: {
    id: 1,
    text: 'inspire_me.favorite_memory'
  },
  2: {
    id: 2,
    text: 'inspire_me.memory_routine'
  },
  3: {
    id: 3,
    text: 'inspire_me.funny_story'
  },
  4: {
    id: 4,
    text: 'inspire_me.earliest_memory'
  },
  5: {
    id: 5,
    text: 'inspire_me.their_influence'
  },
  6: {
    id: 6,
    text: 'inspire_me.what_you_learned'
  },
  7: {
    id: 7,
    text: 'inspire_me.what_youd_say'
  },
  8: {
    id: 8,
    text: 'inspire_me.what_you_admire'
  }
}

export const QUESTION_IDS = Object.keys(QUESTIONS)
