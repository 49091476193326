import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Navigate } from 'react-router-dom'

import { scrollWindow } from '../../utils/browser'
import { selectTemporaryData } from '../../redux/slices/uiSlice'
import { selectUser } from '../../redux/slices/userSlice'
import {
  selectMemoryRoomById,
  fetchRoom
} from '../../redux/slices/memoryRoomSlice'
import {
  fetchMemoriesForMemoryRoomUser,
  selectAllMemories
} from '../../redux/slices/memorySlice'

import RoomPage from '../components/memoryRoom/memoryRoom'
import SortingSettings from '../components/memoryRoom/SortingSettings'
import FilteringSettings from '../components/memoryRoom/FilteringSettings'

const PAGE_STEPS = {
  FILTERING: 'FILTERING',
  MEMORY_ROOM: 'MEMORY_ROOM',
  SORTING: 'SORTING'
}

const Room = () => {
  const { roomId } = useParams()
  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )
  if (!memoryRoom) {
    return <Navigate to="/home" replace />
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(selectUser)
  const allMemories = useSelector(selectAllMemories)
  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )

  if (!memoryRoomUser) {
    return <Navigate to={'/home'} replace />
  }

  // sorting settings
  const initialSorting = {
    sortBy: 'memoryDate',
    order: 'asc'
  }

  // filtering settings
  const initialFiltering = {
    search: '',
    dateFrom: undefined,
    dateTo: undefined,
    hasAudio: false,
    hasImage: false,
    hasText: false,
    hasVideo: false,
    privacy: 'all'
  }

  const tempData = useSelector(selectTemporaryData)
  let roomFilter
  let roomSoring
  if (tempData?.roomViewSettings?.roomId === memoryRoomId) {
    roomFilter = tempData?.roomViewSettings?.memoryFilter
    roomSoring = tempData?.roomViewSettings?.memorySorting
  }

  const [memoryFilter, setMemoryFilter] = useState(
    roomFilter || initialFiltering
  )
  const [memorySorting, setMemorySorting] = useState(
    roomSoring || initialSorting
  )
  const [currentStep, setCurrentStep] = useState(PAGE_STEPS.MEMORY_ROOM)

  useEffect(() => {
    dispatch(
      fetchMemoriesForMemoryRoomUser({
        memoryRoomUserId: memoryRoomUser.id,
        searchFilter: memoryFilter.search
      })
    )
  }, [memoryFilter.search])

  useEffect(async () => {
    const result = await dispatch(fetchRoom(memoryRoomUser.id))
    if (result.meta?.requestStatus === 'rejected') {
      navigate(`/home`)
    }
  }, [])

  const prepareMemories = memories => {
    let processedMemories = memories.filter(memory => {
      // privacy check
      if (memoryFilter.privacy === 'public' && memory.private) return false
      if (memoryFilter.privacy === 'private' && !memory.private) return false

      // check dates
      if (memoryFilter.dateFrom && memoryFilter.dateFrom > memory.memoryDate)
        return false
      if (memoryFilter.dateTo && memoryFilter.dateTo < memory.memoryDate)
        return false

      // content check
      const hasSelectedNothing =
        !memoryFilter.hasAudio &&
        !memoryFilter.hasImage &&
        !memoryFilter.hasText &&
        !memoryFilter.hasVideo

      const hasSomething =
        (memoryFilter.hasAudio && memory.hasAudio) ||
        (memoryFilter.hasImage && memory.hasImage) ||
        (memoryFilter.hasText && memory.hasText) ||
        (memoryFilter.hasVideo && memory.hasVideo)

      if (hasSelectedNothing || hasSomething) {
        return true
      }

      return false
    })
    processedMemories = processedMemories.sort((a, b) => {
      const dateA = a[memorySorting.sortBy]
      const dateB = b[memorySorting.sortBy]

      const compare =
        memorySorting.order === 'asc' ? dateA < dateB : dateA > dateB
      return compare ? 1 : -1
    })

    return processedMemories
  }

  let processedMemories = useMemo(
    () => prepareMemories(allMemories),
    [allMemories, memoryFilter, memorySorting]
  )

  const setCurrentStepWithScroll = step => {
    scrollWindow()
    setCurrentStep(step)
  }

  return (
    <>
      {currentStep === PAGE_STEPS.MEMORY_ROOM && (
        <RoomPage
          memoryRoom={memoryRoom}
          memoryRoomUser={memoryRoomUser}
          memories={processedMemories}
          pageSteps={PAGE_STEPS}
          setCurrentStepWithScroll={setCurrentStepWithScroll}
          memoriesExist={allMemories?.length > 0}
        />
      )}

      {currentStep === PAGE_STEPS.FILTERING && (
        <FilteringSettings
          memoryRoomId={memoryRoomId}
          initData={initialFiltering}
          data={memoryFilter}
          setData={setMemoryFilter}
          setMemoryStep={() => setCurrentStepWithScroll(PAGE_STEPS.MEMORY_ROOM)}
        />
      )}

      {currentStep === PAGE_STEPS.SORTING && (
        <SortingSettings
          memoryRoomId={memoryRoomId}
          initData={initialSorting}
          data={memorySorting}
          setData={setMemorySorting}
          setMemoryStep={() => setCurrentStepWithScroll(PAGE_STEPS.MEMORY_ROOM)}
        />
      )}
    </>
  )
}

export default Room
