import React from 'react'

import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Heading2, ParagraphSmall } from '../generic/Text'
import * as Input from '../../components/generic/Input'
import { Col } from '../../components/generic/View'
import * as Button from '../../components/generic/Button'

import { isPasswordValid } from '../../../utils/generic'

function PasswordResetForm({ handleSubmit, submitting }) {
  const { t } = useTranslation()

  return (
    <Col>
      <Heading2>{t('profile.reset_password')}</Heading2>
      <Formik
        initialValues={{ password1: '', password2: '' }}
        validate={values => {
          const errors = {},
            passwordLimit = 50

          if (!values.password1) {
            errors.password1 = t('general.required_field')
          } else if (values.password1.length > passwordLimit) {
            errors.password1 = t('general.errors.text_too_long', {
              limit: passwordLimit,
              length: values.password1.length
            })
          } else if (!isPasswordValid(values.password1)) {
            errors.password1 = t('profile.password_rules_error')
          }

          if (!values.password2) {
            errors.password2 = t('general.required_field')
          }

          if (
            values.password1 &&
            values.password2 &&
            values.password1 !== values.password2
          ) {
            errors.password2 = t('profile.password_must_match_error')
          }
          return errors
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm narrow="true">
            <Input.StyledField
              type="password"
              name="password1"
              testid="input-password1"
              placeholder={t('profile.new_password')}
              label={t('profile.new_password')}
              labelType={2}
            />
            <Input.StyledErrorMessage name="password1" component="div" />

            <Input.StyledField
              type="password"
              name="password2"
              testid="input-password2"
              placeholder={t('profile.repeat_password')}
              label={t('profile.repeat_password')}
              labelType={2}
            />
            <Input.StyledErrorMessage name="password2" component="div" />

            <ParagraphSmall textAlign="left">
              {t('register.password_rules')}
            </ParagraphSmall>

            <Button.Primary
              type="submit"
              flex={false}
              data-testid="input-submit"
              label={t('general.save')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
    </Col>
  )
}

export default PasswordResetForm
