import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Heading2 } from '../generic/Text'
import * as Button from '../generic/Button'
import * as Input from '../generic/Input'

import { forgotPassword } from '../../../redux/slices/userSlice'
import { setToast } from '../../../utils/status'

function PasswordForgotForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(forgotPassword(formData.email))

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        if (result.payload?.status === 400) {
          setToast(t('profile.reset_password_error_user_not_found'), 'error')
          setSubmitting(false)
        }
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      navigate(`/login`)
      setToast(t('profile.reset_password_email_was_sent'), 'success')
    }
  }

  return (
    <>
      <Heading2>{t('profile.reset_password')}</Heading2>
      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {}
          if (!values.email.trim()) {
            errors.email = t('general.required_field')
          }
          return errors
        }}
        onSubmit={handleSubmit}>
        {() => (
          <Input.StyledForm narrow="true">
            <Input.StyledField
              type="email"
              name="email"
              testid="input-email"
              placeholder="Email"
              label="Email"
            />
            <Input.StyledErrorMessage name="email" component="div" />
            <Button.Primary
              type="submit"
              data-testid="input-submit"
              label={t('profile.reset_password_button')}
              disabled={submitting}
              spinnerOnDisabled={true}
            />
          </Input.StyledForm>
        )}
      </Formik>
      <Button.Link to="/login" data-testid="login">
        {t('login.button')}
      </Button.Link>
    </>
  )
}

export default PasswordForgotForm
