import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ResponsiveContext } from 'grommet'
import styled from 'styled-components'

import viaTheme from '../../../styles/via-theme.json'

import Hero from '../../components/learningRoom/Hero'
import {
  Screen,
  Section,
  InformationBox,
  Col,
  Row
} from '../../components/generic/View'
import { Heading4, TextSmall } from '../../components/generic/Text'

import { ReactComponent as IconArrow } from '../../../assets/icons/icon_arrow_long.svg'

const LinkBlockParent = styled.div`
  flex: ${props => (props.size === 'large' ? 'calc(50% - 20px)' : '100%')};
  margin: ${props => {
    if (props.size === 'large') {
      if (props.index % 2 === 0) {
        return '0 10px 20px 0'
      } else {
        return '0 0 20px 10px'
      }
    } else {
      return '0 0 10px 0'
    }
  }};
  max-width: ${props =>
    props.size === 'large' ? 'calc(50% - 10px);' : '100%'};
`
const LinkBlockContent = styled.div`
  background: #f6f6f6;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 26, 25, 0.05),
    0px 10px 15px -3px rgba(0, 26, 25, 0.1);
  display: flex;
  flex-direction: row;
  height: ${props => (props.size === 'large' ? '180px' : '144px')};
  overflow: hidden;
`

const LinkImageParent = styled.div`
  height: 100%;
  max-width: ${props => {
    if (props.isLinkImageSmall) {
      return props.size === 'large' ? '104px' : '80px'
    }
    return props.size === 'large' ? '150px' : '120px'
  }};
  padding: ${props => (props.isLinkImageSmall ? '20px 16px' : '0')};
  width: 100%;
`

const LinkImage = styled.img`
  height: ${props => {
    if (props.isLinkImageSmall) {
      return props.size === 'large' ? '72px' : '48px'
    }
    return '100%;'
  }};
  object-fit: cover;
  width: ${props => {
    if (props.isLinkImageSmall) {
      return props.size === 'large' ? '72px' : '48px'
    }
    return '100%;'
  }};
`

function PageWithLinks({
  header,
  hero,
  baseLink,
  linkItems,
  linkButtonText,
  isLinkImageSmall
}) {
  const size = useContext(ResponsiveContext)

  let textBlockPadding
  if (isLinkImageSmall) {
    textBlockPadding =
      size === 'large' ? '20px 16px 20px 0' : '20px 16px 20px 0'
  } else {
    textBlockPadding = size === 'large' ? '24px 16px' : '15px 16px'
  }

  return (
    <Screen header={header}>
      <Section columnItem textAlign="left" justify="start">
        <InformationBox maxWidth="928">
          <Hero
            imgAlt={hero.imgAlt}
            imgSrc={hero.imgSrc}
            heading={hero.heading}
            description={hero.description}></Hero>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {linkItems?.map((item, i) => (
              <LinkBlockParent key={item.id} size={size} index={i}>
                <Link to={`/${baseLink}/${item.id}`} style={{ width: '100%' }}>
                  <LinkBlockContent key={item.id} size={size} index={i}>
                    <LinkImageParent
                      isLinkImageSmall={isLinkImageSmall}
                      size={size}>
                      <LinkImage
                        isLinkImageSmall={isLinkImageSmall}
                        size={size}
                        src={
                          item.attributes?.previewImage?.data?.attributes?.url
                        }
                      />
                    </LinkImageParent>
                    <Col justify="between" pad={textBlockPadding} width="100%">
                      <Col style={{ overflow: 'hidden' }}>
                        <Heading4
                          color="#000"
                          isDesktop={size === 'large'}
                          textTransform="initial"
                          style={{ margin: '0 0 8px 0' }}>
                          {item.attributes.title}
                        </Heading4>
                        <TextSmall
                          fontSize={size === 'large' ? '0.875rem' : undefined}
                          style={{ margin: 0 }}>
                          {item.attributes.shortDescription}
                        </TextSmall>
                      </Col>
                      <Row align="center">
                        <TextSmall
                          color={viaTheme.global.colors.turqoise}
                          fontSize={size === 'large' ? '0.875rem' : undefined}
                          style={{ margin: '0 5px 0 0' }}>
                          {linkButtonText}{' '}
                        </TextSmall>
                        <IconArrow
                          width={size !== 'large' ? '18px' : undefined}
                        />
                      </Row>
                    </Col>
                  </LinkBlockContent>
                </Link>
              </LinkBlockParent>
            ))}
          </div>
        </InformationBox>
      </Section>
    </Screen>
  )
}

export default PageWithLinks
