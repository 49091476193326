import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const { APP_LANGUAGES } = require('./constants/appLanguages')

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: APP_LANGUAGES.de.value,
    fallbackLng: APP_LANGUAGES.de.value,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
