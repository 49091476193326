import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'
import { useSelector } from 'react-redux'

import { selectHeader } from '../../../../redux/slices/uiSlice'

import {
  Col,
  Illustration,
  Row,
  Screen,
  Section,
  SectionContent
} from '../../generic/View'
import ContentInspireMeForm from './ContentInspireMeForm'

import coupleWithDogAndLamp from '../../../../assets/illustrations/couple_with_dog_and_lamp.svg'
import lamp from '../../../../assets/illustrations/lamp.svg'
import manWithDog from '../../../../assets/illustrations/man_with_dog.svg'
import womanWalking from '../../../../assets/illustrations/woman_walking.svg'

function ContentInspireMePage(props) {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    if (headerRedux.backCustomActionTriggered) {
      props.setContentStep()
    }
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('memory.create_new_memory_header'),
    multiColor: false,
    withBackground: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <Section columnItem width="100%">
        <SectionContent width={{ max: '100%', min: '100%' }}>
          <Row align="center" width="100%">
            {isDesktop && (
              <Col basis="33%" width={{ min: '120px' }} align="center">
                <Illustration src={womanWalking} maxwidth="300px" />
              </Col>
            )}
            <Col align="center" basis={isDesktop ? '33%' : '100%'}>
              <Illustration
                src={isDesktop ? lamp : coupleWithDogAndLamp}
                maxwidth={isDesktop ? '94px' : '458px'}
              />
              <ContentInspireMeForm {...props} />
            </Col>
            {isDesktop && (
              <Col
                basis="33%"
                width={{ min: '120px' }}
                pad={{ left: 'medium' }}
                align="center">
                <Illustration src={manWithDog} maxwidth="244px" />
              </Col>
            )}
          </Row>
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default ContentInspireMePage
