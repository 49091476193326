import React, { useEffect } from 'react'
import { Formik, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectTemporaryData } from '../../../redux/slices/uiSlice'

const { PRIVACY_SETTINGS } = require('../../../constants/privacySettings')

import * as Input from '../generic/Input'
import * as Button from '../generic/Button'
import { Paragraph } from '../generic/Text'
import { Row, Col } from '../generic/View'
import { Tooltip } from '../generic/Tooltip'
import { getMemoryDate } from '../../../utils/generic'

import { ReactComponent as IconInfo } from '../../../assets/icons/icon_info.svg'

const FormObserver = ({ updateInitObject }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    updateInitObject(values)
  }, [values])
  return null
}

function NewMemoryForm(props) {
  const { t } = useTranslation()

  let name = props?.initObject?.name || '',
    description = props?.initObject?.description || '',
    memoryYear = props?.initObject?.memoryYear || '',
    memoryMonth = props?.initObject?.memoryMonth || '',
    memoryDay = props?.initObject?.memoryDay || ''

  if (props?.initObject?.creatorIsDeactivated) {
    name = ''
    description = ''
  }

  const tempData = useSelector(selectTemporaryData)
  const privacySetting = tempData?.privacy || PRIVACY_SETTINGS.PUBLIC
  const memoryIsPrivate = privacySetting === PRIVACY_SETTINGS.PRIVATE

  const currentYear = new Date().getFullYear()

  return (
    <Formik
      initialValues={{
        name,
        description,
        memoryYear,
        memoryMonth,
        memoryDay,
        private: memoryIsPrivate
      }}
      validate={values => {
        const today = new Date(),
          errors = {},
          nameLimit = 50,
          descriptionLimit = 2000

        // name validation
        if (!values.name.trim()) {
          errors.name = t('general.required_field')
        } else if (values.name && values.name.trim().length > nameLimit) {
          errors.name = t('general.errors.text_too_long', {
            limit: nameLimit,
            length: values.name.trim().length
          })
        }

        // description validation
        if (
          values.description &&
          values.description.trim().length > descriptionLimit
        ) {
          errors.description = t('general.errors.text_too_long', {
            limit: descriptionLimit,
            length: values.description.trim().length
          })
        }

        // dates validation
        if (!values.memoryYear) {
          errors.memoryYear = t('general.required_field')
        } else {
          if (values.memoryDay && !values.memoryMonth) {
            errors.memoryYear = t('general.errors.invalid_date')
          } else {
            const memoryDate = getMemoryDate(values)
            if (isNaN(memoryDate)) {
              errors.memoryYear = t('general.errors.invalid_date')
            } else if (memoryDate > today) {
              errors.memoryYear = t('general.errors.date_before_today')
            }
          }
        }

        return errors
      }}
      onSubmit={props.submitAction}>
      {() => (
        <Input.StyledForm>
          {props.updateInitObject && (
            <FormObserver updateInitObject={props.updateInitObject} />
          )}

          <Input.StyledField
            type="text"
            name="name"
            testid="input-name"
            label={t('memory.label.name')}
            tooltipId="memory-name"
            tooltipText={t('memory.tooltip.input_information')}
            labelType={3}
          />
          <Input.StyledErrorMessage name="name" component="div" />
          <Col margin={{ vertical: '25px' }}>
            <Paragraph textAlign="left">
              {t('general.set_date')}
              <Tooltip
                id="memory-date"
                text={t('memory.tooltip.date_information')}
                style={{ margin: '0 10px' }}>
                <IconInfo />
              </Tooltip>
            </Paragraph>
            <Row>
              <Input.StyledField
                type="number"
                min="1850"
                max={currentYear}
                placeholder="YYYY"
                name="memoryYear"
                testid="input-memoryYear"
              />
              <Input.StyledField
                type="number"
                min="1"
                max="12"
                placeholder="MM"
                name="memoryMonth"
                testid="input-memoryMonth"
                parentStyle={{ margin: '0.1875rem 16px 0 16px' }}
              />
              <Input.StyledField
                type="number"
                min="1"
                max="31"
                placeholder="DD"
                name="memoryDay"
              />
            </Row>
            <Input.StyledErrorMessage name="memoryYear" component="div" />
          </Col>
          <Input.StyledTextArea
            type="text"
            name="description"
            testid="input-description"
            label={t('memory.label.description')}
            labelType={3}
          />
          <Input.StyledErrorMessage name="description" component="div" />
          <Button.Primary
            type="submit"
            flex={false}
            data-testid="input-submit"
            label={t(props.isEdit ? 'general.save' : 'general.add')}
            disabled={props.submitting}
            spinnerOnDisabled={true}
          />
        </Input.StyledForm>
      )}
    </Formik>
  )
}

export default NewMemoryForm
