import { Box, ResponsiveContext } from 'grommet'
import React, { useContext } from 'react'
import viaTheme from '../../../../styles/via-theme.json'

const MemoryContentImage = ({ asset, onClick }) => {
  const size = useContext(ResponsiveContext)

  const isDesktop = size === 'large'

  return (
    <Box style={{ background: viaTheme.global.colors.darkBlueGrey }}>
      <img
        onClick={onClick}
        src={asset.fileUrl}
        alt="memory content"
        style={{
          cursor: 'pointer',
          maxWidth: '100%',
          margin: '0 auto',
          height: 'auto',
          maxHeight: isDesktop ? '545px' : '400px'
        }}
      />
    </Box>
  )
}

export default MemoryContentImage
