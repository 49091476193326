import React, { useContext, useState } from 'react'
import { Box, ResponsiveContext } from 'grommet'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'

import { scrollWindow } from '../../utils/browser'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import * as Button from '../components/generic/Button'
import { ParagraphSmall, StyledLink2 } from '../components/generic/Text'
import { Screen } from '../components/generic/View'
import gradientFullscreen from '../../assets/backgrounds/gradient_fullscreen.svg'

import { APP_LANGUAGES } from '../../constants/appLanguages'

// de
/*
import slide1 from '../../assets/img/tour/mobile/de/1.svg'
import slide2 from '../../assets/img/tour/mobile/de/2.svg'
import slide3 from '../../assets/img/tour/mobile/de/3.svg'
import slide4 from '../../assets/img/tour/mobile/de/4.svg'
import slide5 from '../../assets/img/tour/mobile/de/5.svg'
import slide6 from '../../assets/img/tour/mobile/de/6.svg'
import slide1desk from '../../assets/img/tour/desktop/de/1.svg'
import slide2desk from '../../assets/img/tour/desktop/de/2.svg'
import slide3desk from '../../assets/img/tour/desktop/de/3.svg'
import slide4desk from '../../assets/img/tour/desktop/de/4.svg'
import slide5desk from '../../assets/img/tour/desktop/de/5.svg'
import slide6desk from '../../assets/img/tour/desktop/de/6.svg'

// en
import slide1_EN from '../../assets/img/tour/mobile/en/1.svg'
import slide2_EN from '../../assets/img/tour/mobile/en/2.svg'
import slide3_EN from '../../assets/img/tour/mobile/en/3.svg'
import slide4_EN from '../../assets/img/tour/mobile/en/4.svg'
import slide5_EN from '../../assets/img/tour/mobile/en/5.svg'
import slide6_EN from '../../assets/img/tour/mobile/en/6.svg'
import slide1desk_EN from '../../assets/img/tour/desktop/en/1.svg'
import slide2desk_EN from '../../assets/img/tour/desktop/en/2.svg'
import slide3desk_EN from '../../assets/img/tour/desktop/en/3.svg'
import slide4desk_EN from '../../assets/img/tour/desktop/en/4.svg'
import slide5desk_EN from '../../assets/img/tour/desktop/en/5.svg'
import slide6desk_EN from '../../assets/img/tour/desktop/en/6.svg'
*/
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const StyledSwiper = styled(Swiper)`
  background: no-repeat url(${gradientFullscreen});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  .swiper-pagination {
    position: fixed;
  }
  .swiper-pagination-bullet {
    background: white;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    margin: 0 50px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 2rem;
    position: fixed;
    top: 50vh;
  }
  .swiper-scrollbar {
    display: none;
  }
`

const WrapSkip = styled(Box)`
  position: fixed;
  right: 20px;
  bottom: 25px;
  z-index: 99;
  @media (min-width: 1367px) {
    right: calc(50% - 663px);
  }
`

const WrapButton = styled(Box)`
  position: fixed;
  width: 100%;
  align-items: center;
  bottom: 20px;
  padding: 5px 7vw;
  z-index: 99;
`
const SlideImg = styled.img`
  width: 100%;
  height: auto;
`

const GuidedTour = () => {
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  const { t, i18n } = useTranslation()

  const [isLastSlide, setIsLastSlide] = useState(false)

  let mobileImgs, desktopImgs
  // DS 18/10/2022
  // English is disabled until copy is checked
  // if (i18n.language === APP_LANGUAGES.en.value) {
  //   mobileImgs = [
  //     slide1_EN,
  //     slide2_EN,
  //     slide3_EN,
  //     slide4_EN,
  //     slide5_EN,
  //     slide6_EN
  //   ]

  //   desktopImgs = [
  //     slide1desk_EN,
  //     slide2desk_EN,
  //     slide3desk_EN,
  //     slide4desk_EN,
  //     slide5desk_EN,
  //     slide6desk_EN
  //   ]
  // } else {
  // mobileImgs = [slide1, slide2, slide3, slide4, slide5, slide6]

  // desktopImgs = [
  //   slide1desk,
  //   slide2desk,
  //   slide3desk,
  //   slide4desk,
  //   slide5desk,
  //   slide6desk
  // ]
  // }

  const imgArray = isDesktop ? desktopImgs : mobileImgs

  return (
    <Screen column header="">
      <StyledSwiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        autoHeight
        updateOnWindowResize={true}
        navigation={isDesktop}
        pagination
        scrollbar={{ draggable: true }}
        onSlideChange={() => {
          scrollWindow()
        }}
        onReachEnd={() => setIsLastSlide(true)}>
        {imgArray.map(img => (
          <SwiperSlide key={img}>
            <SlideImg src={img} />
          </SwiperSlide>
        ))}

        {isLastSlide || (
          <WrapSkip>
            <StyledLink2 to={'/'} nopad="true">
              <ParagraphSmall white>{t('general.skip')}</ParagraphSmall>
            </StyledLink2>
          </WrapSkip>
        )}
        {isLastSlide && (
          <WrapButton>
            <StyledLink2 to={'/'}>
              <Button.Primary type="button" label={t('general.next')} />
            </StyledLink2>
          </WrapButton>
        )}
      </StyledSwiper>
    </Screen>
  )
}

export default GuidedTour
