import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import PreparedHelmet from '../components/seo/PreparedHelmet'
import {
  Col,
  Screen,
  Section,
  SectionContent
} from '../components/generic/View'
import { Paragraph } from '../components/generic/Text'
import * as Button from '../components/generic/Button'
import IllustrationBlock from '../components/IllustrationBlock'
import PasswordResetForm from '../components/forms/PasswordResetForm'
import womanWithKey from '../../assets/illustrations/woman_with_key.svg'

import { setToast } from '../../utils/status'
import {
  checkResetPasswordToken,
  resetPassword
} from '../../redux/slices/userSlice'
import { selectUser } from '../../redux/slices/userSlice'
import { useQuery } from '../../utils/generic'

function PasswordReset() {
  const user = useSelector(selectUser)
  const [submitting, setSubmitting] = useState(false)

  if (user) {
    return <Navigate to="/home" replace />
  }

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const query = useQuery()

  const navigate = useNavigate()
  const [resetCompleted, toggleResetCompleted] = useState(false)

  const tokenValue = query.get('token')

  useEffect(() => {
    const checkTokenValidity = async () => {
      const result = await dispatch(checkResetPasswordToken(tokenValue))
      const tokenIsValid =
        result.meta?.requestStatus === 'fulfilled' &&
        result.payload?.message === 'profile.verification_successful'

      if (!tokenIsValid) {
        const toastMessage =
          result.payload?.message ||
          'profile.reset_password_recovery_link_not_valid'
        setToast(t(toastMessage), 'error')
        navigate('/login', { replace: true })
      }
    }

    if (!tokenValue) {
      navigate('/login', { replace: true })
      setToast(t('profile.reset_password_recovery_link_not_valid'), 'error')
    }

    checkTokenValidity()
  }, [])

  const handleSubmit = async formData => {
    setSubmitting(true)
    const result = await dispatch(
      resetPassword({
        token: tokenValue,
        ...formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        const msg = result.payload?.detail?.general || result.payload.title
        setToast(t(msg), 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      setToast(t('profile.password_successfully_reset'), 'success')
      toggleResetCompleted(true)
      setSubmitting(false)
    }
  }

  return (
    <>
      <PreparedHelmet
        title={t('profile.reset_password.imprint')}
        description={t('memory_room.landing_text')}
        titleHasAppName={true}
      />

      <Screen>
        <IllustrationBlock img={womanWithKey} />
        <Section>
          <SectionContent>
            {!resetCompleted && (
              <Col>
                <PasswordResetForm
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                />
                <Button.Link to="/login" data-testid="login">
                  {t('login.button')}
                </Button.Link>
              </Col>
            )}

            {resetCompleted && (
              <Col>
                <Paragraph>
                  {t('profile.password_successfully_reset')}
                </Paragraph>
                <Button.Link to="/login" data-testid="login">
                  {t('login.button')}
                </Button.Link>
              </Col>
            )}
          </SectionContent>
        </Section>
      </Screen>
    </>
  )
}

export default PasswordReset
