import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ResponsiveContext } from 'grommet'

import { destroyContentComment } from '../../../../../redux/slices/memorySlice'
import { closeModal } from '../../../../../redux/slices/uiSlice'

import { setToast } from '../../../../../utils/status'

import { Paragraph } from '../../../generic/Text'
import * as Button from '../../../generic/Button'
import { Col, Row } from '../../../generic/View'

const commentDelete = props => {
  const { comment, memoryRoomUserId, contentId, memoryId } = props.modalProps

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const size = useContext(ResponsiveContext)

  const handleDelete = async () => {
    setSubmitting(true)
    const result = await dispatch(
      destroyContentComment({
        memoryRoomUserId,
        memoryId,
        contentId,
        commentId: comment.id
      })
    )
    // Display error if rejected
    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        const errorMsg =
          result.payload.title ||
          result.payload?.errors?.general ||
          t('general.something_wrong')
        setToast(errorMsg, 'error')
        setSubmitting(false)
      }
    }

    if (result.meta?.requestStatus === 'fulfilled') {
      dispatch(closeModal())
      setToast(t('memory.comments.comment_was_deleted'), 'success')
    }
  }

  const onCloseModal = () => {
    dispatch(closeModal())
  }

  return (
    <Col style={{ ...props.style }}>
      <Paragraph textAlign="left" darkBlueGrey>
        {t('general.question_delete_this')}
      </Paragraph>

      <Row justify={size === 'large' ? 'between' : 'evenly'}>
        <Button.Primary
          type="button"
          margin="0"
          data-testid="button-no"
          label={t('general.no')}
          onClick={() => onCloseModal()}
          disabled={submitting}
          spinnerOnDisabled={true}
          adjustToSize={size}
        />

        <Button.White
          type="button"
          data-testid="button-yes"
          label={t('general.yes')}
          onClick={() => handleDelete()}
          disabled={submitting}
          adjustToSize={size}
        />
      </Row>
    </Col>
  )
}

export default commentDelete
