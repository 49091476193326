import React from 'react'
import { useTranslation } from 'react-i18next'

import { CONTENT_TYPES } from '../../../../constants/contentTypes'

import { Col } from '../../generic/View'
import { Text } from '../../generic/Text'
import MemoryContentText from './MemoryContentText'
import MemoryContentAudio from './MemoryContentAudio'
import MemoryContentImage from './MemoryContentImage'
import MemoryContentVideo from './MemoryContentVideo'
import MemoryContentDocument from './MemoryContentDocument'

import { ReactComponent as VideoImg } from '../../../../assets/img/video_img.svg'
import { ReactComponent as SoundImg } from '../../../../assets/img/sound_img.svg'
import { ReactComponent as GalleryImg } from '../../../../assets/icons/icon_gallery.svg'
import { ReactComponent as TextImg } from '../../../../assets/icons/icon_text.svg'

function DeletedContentPlaceholder({ content }) {
  const { t } = useTranslation()

  let placeholderText, placeholderImage

  if ([CONTENT_TYPES.TEXT, CONTENT_TYPES.INSPIRE_ME].includes(content.type)) {
    placeholderText = t('memory.memory_content.deleted_text_placeholder')
    placeholderImage = <TextImg style={{ color: 'white' }} />
  }

  if (
    [CONTENT_TYPES.IMAGE, CONTENT_TYPES.CAMERA, CONTENT_TYPES.GALLERY].includes(
      content.type
    )
  ) {
    placeholderText = t('memory.memory_content.deleted_image_placeholder')
    placeholderImage = <GalleryImg style={{ color: 'white' }} />
  }

  if (content.type === CONTENT_TYPES.VIDEO) {
    placeholderText = t('memory.memory_content.deleted_video_placeholder')
    placeholderImage = <VideoImg />
  }

  if (content.type === CONTENT_TYPES.AUDIO) {
    placeholderText = t('memory.memory_content.deleted_audio_placeholder')
    placeholderImage = <SoundImg />
  }

  return (
    <Col
      pad={{ horizontal: '13px', vertical: '27px' }}
      background="#e4eff8"
      align="center">
      {placeholderImage}
      <Text style={{ margin: '0px', paddingTop: '20px', textAlign: 'center' }}>
        {placeholderText}
      </Text>
    </Col>
  )
}

export default function PreparedMemoryContent({
  memoryRoomUserId,
  content,
  memoryRoom,
  onOpenLightBox
}) {
  if (content.creatorIsDeactivated)
    return <DeletedContentPlaceholder content={content} />

  // prepare content
  let contentDescription
  let contentAsset

  if (content.description) {
    contentDescription = (
      <MemoryContentText memoryRoom={memoryRoom} content={content} />
    )
  }
  if (content?.assets?.length > 0) {
    const asset = content.assets[0]
    if (content.type === CONTENT_TYPES.IMAGE) {
      contentAsset = (
        <MemoryContentImage
          onClick={() => onOpenLightBox(asset.fileUrl)}
          asset={asset}
        />
      )
    } else if (content.type === CONTENT_TYPES.VIDEO) {
      contentAsset = <MemoryContentVideo asset={asset} />
    } else if (content.type === CONTENT_TYPES.AUDIO) {
      contentAsset = <MemoryContentAudio asset={asset} />
    } else if (content.type === CONTENT_TYPES.DOCUMENT) {
      contentAsset = (
        <MemoryContentDocument
          memoryRoomUserId={memoryRoomUserId}
          content={content}
          asset={asset}
        />
      )
    }
  }

  return (
    <>
      {contentDescription}
      {contentAsset}
    </>
  )
}
