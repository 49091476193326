import React from 'react'
import { Button } from 'grommet'
import styled from 'styled-components'

const RecordButton = props => {
  const recordingIcon = props.isRecording ? (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="122" height="122" rx="61" fill="#F6F6F6" />
      <rect x="41" y="41" width="40" height="40" rx="2" fill="#EE5858" />
    </svg>
  ) : (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="122" height="122" rx="61" fill="#F6F6F6" />
      <circle cx="61" cy="61" r="25" fill="#EE5858" />
    </svg>
  )

  return <Button {...props}>{recordingIcon}</Button>
}

const StyledRecordButton = styled(RecordButton)`
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: none;
  }
`

export default StyledRecordButton
