import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit'
import i18n from '../../i18n'
import ajax, { ajaxWithFiles } from '../../utils/ajax'
import { getBrowserLocale, getBrowserData } from '../../utils/browser'

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH

// tool to normalize data {ids, entities}
const userAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt) // sort date
})
// create initial state (ids and entities created by default)
const initialState = userAdapter.getInitialState({
  local: null,
  jwt: null,
  submitting: false,
  error: null
})

// Async Actions
export const create = createAsyncThunk(
  'user/create',
  async ({ formData, invite }, thunkAPI) => {
    try {
      const response = await ajax({
        url: `${API_BASE_PATH}/users${invite ? `?inviteValue=${invite}` : ''}`,
        method: 'POST',
        data: formData
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const { user, jwt } = await ajax({
        url: API_BASE_PATH + '/users/auth',
        method: 'POST',
        data: {
          email: email,
          password: password
        }
      })
      // DS 18/10/2022
      // English is disabled until copy is checked
      // i18n.changeLanguage(user.language)
      return { user, jwt }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const verification = createAsyncThunk(
  'user/verification',
  async (tokenValue, thunkAPI) => {
    try {
      const response = await ajax({
        url: API_BASE_PATH + '/users/verification',
        method: 'PUT',
        data: {
          tokenValue: tokenValue
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const acceptInvite = createAsyncThunk(
  'user/acceptInvite',
  async (tokenValue, thunkAPI) => {
    try {
      const response = await ajax({
        url: API_BASE_PATH + '/users/invites/accept',
        method: 'PUT',
        data: {
          tokenValue: tokenValue
        }
      })
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const get = createAsyncThunk('user/get', async () => {
  const payload = await ajax({
    url: API_BASE_PATH + '/users',
    method: 'GET'
  })
  return payload
})

export const update = createAsyncThunk(
  'user/update',
  async ({ userId, formData }, thunkAPI) => {
    try {
      const payload = await ajax({
        url: `${API_BASE_PATH}/users/${userId}`,
        method: 'PUT',
        data: formData
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (email, thunkAPI) => {
    try {
      const payload = await ajax({
        url: API_BASE_PATH + '/users/forgot-password',
        method: 'POST',
        data: {
          email: email
        }
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (data, thunkAPI) => {
    try {
      const payload = await ajax({
        url: API_BASE_PATH + '/users/reset-password',
        method: 'PUT',
        data: data
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const checkResetPasswordToken = createAsyncThunk(
  'user/checkResetPasswordToken',
  async (token, thunkAPI) => {
    try {
      const payload = await ajax({
        url: API_BASE_PATH + '/users/reset-password/check-token',
        method: 'GET',
        data: { token }
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ userId, data }, thunkAPI) => {
    try {
      const payload = await ajax({
        url: API_BASE_PATH + `/users/${userId}/change-password`,
        method: 'PUT',
        data: data
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const setProfilePhoto = createAsyncThunk(
  'user/setProfilePhoto',
  async ({ userId, formData }, thunkAPI) => {
    try {
      const result = await ajaxWithFiles({
        url: `${API_BASE_PATH}/users/${userId}/profile-photo`,
        method: 'POST',
        data: formData
      })
      return result
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const sendMessageToAdmin = createAsyncThunk(
  'user/general_feedback',
  async ({ userId, formData, feedbackFeature }, thunkAPI) => {
    try {
      const browserData = getBrowserData()

      formData.append('navInfo', JSON.stringify(browserData.navInfo))
      formData.append('screenInfo', JSON.stringify(browserData.screenInfo))
      formData.append('language', i18n.language)

      let url
      if (feedbackFeature) {
        url = `${API_BASE_PATH}/users/feedback-admin${
          userId ? `?userId=${userId}` : ''
        }`
      } else {
        url = `${API_BASE_PATH}/users/${userId}/message-admin`
      }

      const payload = await ajaxWithFiles({
        url,
        method: 'POST',
        data: formData
      })
      return payload
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data || err)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.local = null
      state.jwt = null
      state.submitting = false
      // DS 18/10/2022
      // English is disabled until copy is checked
      // i18n.changeLanguage(getBrowserLocale())
    }
  },
  extraReducers: {
    [get.fulfilled]: (state, action) => {
      userAdapter.upsertMany(state, action.payload) // update or insert
    },
    [create.pending]: state => {
      state.submitting = true
    },
    [create.fulfilled]: state => {
      state.submitting = false
    },
    [create.rejected]: state => {
      state.submitting = false
    },
    [login.pending]: state => {
      state.submitting = true
    },
    [login.fulfilled]: (state, action) => {
      state.local = action.payload.user
      state.jwt = action.payload.jwt
      state.submitting = false
      localStorage.setItem('userJWT', action.payload.jwt)
    },
    [login.rejected]: state => {
      state.submitting = false
    },
    [update.pending]: state => {
      state.submitting = true
    },
    [update.fulfilled]: (state, action) => {
      state.submitting = false
      state.local = action.payload
    },
    [update.rejected]: state => {
      state.submitting = false
    },
    [forgotPassword.pending]: state => {
      state.submitting = true
    },
    [forgotPassword.fulfilled]: state => {
      state.submitting = false
    },
    [forgotPassword.rejected]: state => {
      state.submitting = false
    },
    [resetPassword.pending]: state => {
      state.submitting = true
    },
    [resetPassword.fulfilled]: state => {
      state.submitting = false
    },
    [resetPassword.rejected]: state => {
      state.submitting = false
    },
    [changePassword.pending]: state => {
      state.submitting = true
    },
    [changePassword.fulfilled]: state => {
      state.submitting = false
    },
    [changePassword.rejected]: state => {
      state.submitting = false
    },
    [setProfilePhoto.pending]: state => {
      state.submitting = true
    },
    [setProfilePhoto.fulfilled]: (state, action) => {
      state.submitting = false
      state.local = action.payload
    },
    [setProfilePhoto.rejected]: state => {
      state.submitting = false
    },
    [sendMessageToAdmin.pending]: state => {
      state.submitting = true
    },
    [sendMessageToAdmin.fulfilled]: state => {
      state.submitting = false
    },
    [sendMessageToAdmin.rejected]: state => {
      state.submitting = false
    }
  }
})

// actions
export const { logout } = userSlice.actions

// Selectors
export const selectUser = state => state.user.local

export default userSlice.reducer
