import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import i18n from './i18n'

import { selectUser } from './redux/slices/userSlice'
import { logout } from './redux/slices/userSlice'

import { scrollWindow, parseJWT } from './utils/browser'
import { setToast } from './utils/status'

import Home from './views/screens/Home'
import Landing from './views/screens/Landing'
import MemoryLanding from './views/screens/MemoryLanding'
import Login from './views/screens/Login'
import CreateAccount from './views/screens/CreateAccount'
import NewMemoryRoom from './views/screens/NewMemoryRoom'
import Profile from './views/screens/Profile'
import ProfilePasswordChange from './views/screens/ProfilePasswordChange'
import MemoryRoom from './views/screens/MemoryRoom'
import Memory from './views/screens/Memory'
import MemoryNew from './views/screens/MemoryNew'
import MemoryEdit from './views/screens/MemoryEdit'
import MemoryContent from './views/screens/MemoryContent'
import NotFound from './views/screens/NotFound'
import Verification from './views/screens/Verification'
import AcceptInvite from './views/screens/AcceptInvite'
import PasswordForgot from './views/screens/PasswordForgot'
import PasswordReset from './views/screens/PasswordReset'
import InviteMember from './views/screens/InviteMember'
import ContentNew from './views/screens/ContentNew'
import Imprint from './views/screens/Imprint'
import DataPrivacy from './views/screens/DataPrivacy'
import GuidedTour from './views/screens/GuidedTour'
import ContactUs from './views/screens/ContactUs'

import LearningRoomHome from './views/screens/LearningRoom/Home'
import LearningRoomTopic from './views/screens/LearningRoom/Topic'
import LearningRoomExercise from './views/screens/LearningRoom/Exercise'

const DISPLAY_LEARNING_ROOM_CONTENT =
  process.env.REACT_APP_DISPLAY_LEARNING_ROOM_CONTENT === 'TRUE'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

const RequiredAuth = ({ children }) => {
  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const userJWT = localStorage.getItem('userJWT')

  let tokenExpired = false
  if (userJWT) {
    const userData = parseJWT(userJWT)
    if (userData.exp * 1000 < Date.now()) {
      setToast(i18n.t('general.token_expired'), 'info')
      tokenExpired = true
    }
  }

  const isAuthenticated = userJWT && user?.id && !tokenExpired

  if (!isAuthenticated) {
    dispatch(logout())
    return <Navigate to="/login" replace />
  }

  return children
}

const routes = () => {
  // Scroll to top on every route change
  // Due to the current layout, we need to scroll not on whole window, but on grommet parent component
  const { pathname, search } = useLocation()
  const { trackPageView } = useMatomo()
  const grommetDiv = document.querySelector('#root>div')

  useEffect(() => {
    trackPageView({
      documentTitle: pathname
    })
  }, [pathname])

  useEffect(() => {
    if (grommetDiv) {
      scrollWindow()
    }
  }, [pathname, search])

  return (
    <Routes>
      {DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route path="/" element={<Landing />} />
      )}
      {DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route path="/memory" element={<MemoryLanding />} />
      )}
      {!DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route path="/" element={<MemoryLanding />} />
      )}
      <Route path="/login" element={<Login />} />
      {!isAppSunsettingMode && (
        <Route path="/create-account" element={<CreateAccount />} />
      )}
      {!isAppSunsettingMode && (
        <Route path="/verification" element={<Verification />} />
      )}
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/privacy" element={<DataPrivacy />} />
      {!isAppSunsettingMode && (
        <Route path="/accept-invite" element={<AcceptInvite />} />
      )}
      <Route path="/forgot-password" element={<PasswordForgot />} />
      <Route path="/reset-password" element={<PasswordReset />} />

      {/* Learning Room Routes */}
      {DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route
          path="/learning/:topicId/exercise/:exerciseId"
          element={<LearningRoomExercise />}
        />
      )}
      {DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route path="/learning/:topicId" element={<LearningRoomTopic />} />
      )}
      {DISPLAY_LEARNING_ROOM_CONTENT && (
        <Route path="/learning" element={<LearningRoomHome />} />
      )}

      {/* Authenticated Routes */}
      <Route
        path="/profile/change-password"
        element={
          <RequiredAuth>
            <ProfilePasswordChange />
          </RequiredAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequiredAuth>
            <Profile />
          </RequiredAuth>
        }
      />
      {/* Guided Tour should be recovered when video tour will be implemented */}
      {/* <PrivateRoute path="/profile/guided-tour">
        <GuidedTour />
      </PrivateRoute> */}
      <Route
        path="/profile/contact-us"
        element={
          <RequiredAuth>
            <ContactUs />
          </RequiredAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequiredAuth>
            <Home />
          </RequiredAuth>
        }
      />
      {!isAppSunsettingMode && (
        <Route
          path="/rooms/new"
          element={
            <RequiredAuth>
              <NewMemoryRoom />
            </RequiredAuth>
          }
        />
      )}
      {!isAppSunsettingMode && (
        <Route
          path="/rooms/:roomId/new-memory"
          element={
            <RequiredAuth>
              <MemoryNew />
            </RequiredAuth>
          }
        />
      )}
      {!isAppSunsettingMode && (
        <Route
          path="/rooms/:roomId/memory/:memoryId/new-content"
          element={
            <RequiredAuth>
              <ContentNew />
            </RequiredAuth>
          }
        />
      )}
      {!isAppSunsettingMode && (
        <Route
          path="/rooms/:roomId/memory/:memoryId/edit"
          element={
            <RequiredAuth>
              <MemoryEdit />
            </RequiredAuth>
          }
        />
      )}
      <Route
        path="/rooms/:roomId/memory/:memoryId/content/:memoryContentId"
        element={
          <RequiredAuth>
            <MemoryContent />
          </RequiredAuth>
        }
      />
      <Route
        path="/rooms/:roomId/memory/:memoryId"
        element={
          <RequiredAuth>
            <Memory />
          </RequiredAuth>
        }
      />
      {!isAppSunsettingMode && (
        <Route
          path="/rooms/:roomId/invite-member"
          element={
            <RequiredAuth>
              <InviteMember />
            </RequiredAuth>
          }
        />
      )}
      <Route
        path="/rooms/:roomId"
        element={
          <RequiredAuth>
            <MemoryRoom />
          </RequiredAuth>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default routes
