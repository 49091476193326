const FEEDBACK_EMOTIONS = {
  ANGRY: 'angry',
  CONFUSED: 'confused',
  CONTENT: 'content',
  FRUSTRATED: 'frustrated',
  HAPPY: 'happy',
  SHOCKED: 'shocked'
}

const FEEDBACK_EMOTIONS_VALUES = []
for (const k in FEEDBACK_EMOTIONS) {
  FEEDBACK_EMOTIONS_VALUES.push(FEEDBACK_EMOTIONS[k])
}

module.exports = {
  FEEDBACK_EMOTIONS,
  FEEDBACK_EMOTIONS_VALUES
}
