import React from 'react'
import { useTranslation } from 'react-i18next'

import { Col } from '../../generic/View'

function MemoryContentAudio({ asset }) {
  const { t } = useTranslation()

  return (
    <Col background="lightGrey" pad={{ vertical: '33px' }}>
      <audio controls style={{ width: '100%' }}>
        <source src={asset.fileUrl} type={asset.contentType} />
        {t('media.not_supported', { type: 'audio' })}
      </audio>
    </Col>
  )
}

export default MemoryContentAudio
