import React from 'react'
import { Button } from 'grommet'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { prepareDateTimeAgo } from '../../../utils/generic'

import { openModal } from '../../../redux/slices/uiSlice'
const memoryRoomUserConstants = require('../../../constants/memoryRoomUserRoles')
const { OWNER } = memoryRoomUserConstants.ROLES

import { Row, Col } from '../generic/View'
import * as Icon from '../generic/Icon'
import { UserIcon } from '../generic/Elements'
import { ParagraphSmall, TextSmall } from '../generic/Text'
import { Tooltip } from '../generic/Tooltip'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function MemoryContentComment({
  memoryRoomUserId,
  memoryId,
  contentId,
  comment,
  menuIsDisplayed
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const timeAgo = prepareDateTimeAgo(comment.createdAt)

  const openCommentSettingsModal = () => {
    dispatch(
      openModal({
        type: 'commentSettings',
        props: {
          comment: comment,
          contentId,
          memoryRoomUserId,
          memoryId
        }
      })
    )
  }

  let commentText, commentUser
  if (comment.creatorIsDeactivated) {
    commentText = t('memory.comments.deleted_comment_placeholder')
  } else {
    commentUser = comment[OWNER]?.user
    commentText = comment.comment
  }

  return (
    <Col width="100%" pad={{ horizontal: '13px' }} margin={{ bottom: '16px' }}>
      <Row align="center" justify="between">
        <Row align="center" height={{ min: '65px' }}>
          <UserIcon user={commentUser} height="40px" />
          <ParagraphSmall style={{ margin: '0' }} lineHeight="1.5rem" bold>
            {commentUser?.displayName || t('memory.deleted_user')}
          </ParagraphSmall>
        </Row>
        {comment.private && (
          <Tooltip
            id={`comment-${comment.id}`}
            text={t('memory.privacy.comment_is_private')}>
            <Icon.Lock style={{ color: '#02CBC5', height: '20px' }} />
          </Tooltip>
        )}
      </Row>
      <ParagraphSmall
        whiteSpace="pre-line"
        style={{
          backgroundColor: '#F6F6F6',
          borderRadius: '8px',
          fontStyle: comment.creatorIsDeactivated ? 'italic' : 'normal',
          margin: '0 0 5px 0',
          padding: menuIsDisplayed ? '16px 12px 26px 12px' : '16px 12px',
          position: 'relative'
        }}>
        {commentText}
        {!isAppSunsettingMode && menuIsDisplayed && (
          <Button
            onClick={openCommentSettingsModal}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: '12px',
              bottom: '10px'
            }}>
            <Icon.ThreeDotsHorizontal />
          </Button>
        )}
      </ParagraphSmall>
      <TextSmall style={{ margin: 0 }}>{timeAgo}</TextSmall>
    </Col>
  )
}

export default MemoryContentComment
