import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Col } from '../../generic/View'
import { ParagraphSmall } from '../../generic/Text'

import { CONTENT_TYPES } from '../../../../constants/contentTypes'
import {
  QUESTIONS,
  QUESTION_IDS
} from '../../../../constants/inspireMeQuestions'
import { ResponsiveContext } from 'grommet'

function MemoryContentText({ content, memoryRoom }) {
  const { t } = useTranslation()

  const size = useContext(ResponsiveContext)

  let inspireMeQuestion
  if (
    content.type === CONTENT_TYPES.INSPIRE_ME &&
    (content.questionId || content.questionId === 0) &&
    QUESTION_IDS.includes(content.questionId.toString())
  ) {
    inspireMeQuestion = (
      <ParagraphSmall white bold style={{ margin: '0 0 0.5rem 0' }}>
        {t(QUESTIONS[content.questionId].text, {
          belovedName: memoryRoom.belovedName
        })}
      </ParagraphSmall>
    )
  }

  return (
    <Col
      background="#475662"
      pad={{
        horizontal:
          size === 'small' ? '14px' : size === 'medium' ? '28px' : '25px',
        vertical: '24px'
      }}>
      {inspireMeQuestion}
      <ParagraphSmall
        white
        whiteSpace="pre-line"
        semiBold
        style={{ margin: 0 }}>
        {content.description}
      </ParagraphSmall>
    </Col>
  )
}

export default MemoryContentText
