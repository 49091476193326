import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { selectUser } from '../../redux/slices/userSlice'

import { Section, Screen, SectionContent } from '../components/generic/View'
import LoginDesktop from '../components/LoginDesktop'
import PasswordForgotForm from '../components/forms/PasswordForgotForm'
import PreparedHelmet from '../components/seo/PreparedHelmet'

function PasswordForgot() {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  if (user) {
    return <Navigate to="/home" replace />
  }

  return (
    <>
      <PreparedHelmet
        title={t('profile.reset_password')}
        description={t('memory_room.landing_text')}
        titleHasAppName={true}
      />

      <Screen>
        <LoginDesktop />
        <Section>
          <SectionContent>
            <PasswordForgotForm />
          </SectionContent>
        </Section>
      </Screen>
    </>
  )
}

export default PasswordForgot
