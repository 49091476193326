import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { openModal } from '../../../../redux/slices/uiSlice'

import viaTheme from '../../../../styles/via-theme.json'
import { Col, Row } from '../../generic/View'
import { ParagraphSmall, Text } from '../../generic/Text'
import * as Icon from '../../generic/Icon'
import defaultAvatar from '../../../../assets/img/default_avatar.svg'

export const MemoryRoomUser = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  let userAvatar
  let userAvatarStyle = {}
  if (
    props?.roomUser?.user?.assets &&
    props?.roomUser?.user?.assets?.length > 0
  ) {
    userAvatar = props.roomUser.user.assets[0].fileUrl
  } else {
    userAvatar = defaultAvatar
    userAvatarStyle['filter'] = 'invert(1)'
  }

  const openUserDetailedModal = () => {
    dispatch(
      openModal({
        type: 'memoryRoomUserDetailed',
        props: {
          activeUser: props.roomUser,
          adminSelection: props.adminSelection,
          memoryRoomUser: props.memoryRoomUser,
          memoryRoom: props.memoryRoom,
          roomOwnerId: props.roomOwnerId,
          userAvatar,
          userAvatarStyle,
          creatorId: props.creatorId,
          user: props.user
        }
      })
    )
  }

  return (
    <Row
      background="lightGrey"
      align="center"
      pad={{ horizontal: '14.5px', vertical: '10px' }}
      margin={props.margin}
      basis={props.basis}
      style={{
        borderRadius: '8px',
        position: 'relative',
        minHeight: 'fit-content'
      }}>
      <img
        src={userAvatar}
        alt="user avatar"
        height={52}
        width={52}
        style={{
          borderRadius: '100%',
          objectFit: 'cover',
          marginRight: '15px',
          ...userAvatarStyle
        }}
      />
      <Col>
        <ParagraphSmall
          style={{
            margin: '2px 0',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
          {props.roomUser.user?.displayName}
        </ParagraphSmall>
        <Text
          uppercase
          semiBold
          color={viaTheme.global.colors.darkBlueGrey}
          style={{ margin: '2px 0' }}>
          {t(`roles.${props.roomUser.role}`)}
        </Text>
      </Col>
      {props.displayMenu && (
        <Col
          align="center"
          justify="center"
          height="23px"
          width="40px"
          onClick={() => openUserDetailedModal()}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            bottom: 0,
            right: 0
          }}>
          <Icon.ThreeDotsHorizontal />
        </Col>
      )}
    </Row>
  )
}

export default MemoryRoomUser
