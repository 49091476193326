import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Screen, Section, SectionContent } from '../generic/View'
import { ParagraphLarge } from '../generic/Text'
import * as Button from '../generic/Button'
import IllustrationBlock from '../IllustrationBlock'
import familyWithACat from '../../../assets/illustrations/family_with_a_cat.svg'
import { Tooltip } from '../generic/Tooltip'
import { ReactComponent as IconInfo } from '../../../assets/icons/icon_info.svg'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function HomeWithoutMemoryRooms() {
  const { t } = useTranslation()

  const header = {
    type: 'menu',
    withBackground: true
  }

  return (
    <Screen header={header}>
      <IllustrationBlock img={familyWithACat} />
      <Section>
        <SectionContent>
          <ParagraphLarge>
            {t('memory_room.no_rooms_instruction')}
          </ParagraphLarge>
          {!isAppSunsettingMode && (
            <Link to="/rooms/new">
              <Button.Primary
                type="button"
                data-testid="home-with-memory-screen-button-room-create"
                label={t('memory_room.create_new_room')}
              />
            </Link>
          )}

          {isAppSunsettingMode && (
            <Tooltip
              id={`registration-tooltip`}
              text={t('memory_room.create_new_room_disabled')}>
              <Button.Primary
                disabled
                type="button"
                data-testid="home-with-memory-screen-button-room-create"
                style={{
                  background: '#C5CFD6',
                  opacity: '1',
                  color: '#7C92A2'
                }}
                label={
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px'
                    }}>
                    {t('memory_room.create_new_room')}
                    <IconInfo />
                  </span>
                }
              />
            </Tooltip>
          )}
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default HomeWithoutMemoryRooms
