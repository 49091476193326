import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'

import {
  Illustration,
  SectionContent,
  Section,
  HideOnMobile
} from '../components/generic/View'
import { Heading1, Heading2, ParagraphLarge } from '../components/generic/Text'
import loginIcon from '../../assets/illustrations/man_dog_woman.svg'

import viaTheme from '../../styles/via-theme.json'

const LoginDesktop = () => {
  const { t } = useTranslation()
  const size = useContext(ResponsiveContext)
  const isDesktop = size === 'large'

  return (
    <Section backgroundType="gradientDesktop">
      <SectionContent>
        <Heading1
          fontWeight="500"
          style={{
            lineHeight: '150%',
            color: isDesktop
              ? viaTheme.global.colors.white
              : viaTheme.global.colors.turqoise,
            fontSize: isDesktop ? '40px' : '32px',
            marginTop: isDesktop ? 'initial' : '47px'
          }}>
          {t('landing.heading')}
        </Heading1>
        <Heading2
          style={{
            fontSize: isDesktop ? '22px' : '18px',
            color: isDesktop
              ? viaTheme.global.colors.white
              : viaTheme.global.colors.turqoise,
            lineHeight: '130%',
            fontWeight: '500',
            marginBottom: '40px'
          }}>
          {t('landing.subheading')}
        </Heading2>
        <HideOnMobile>
          <ParagraphLarge>{t('memory_room.landing_text')}</ParagraphLarge>
        </HideOnMobile>
        <Illustration src={loginIcon} />
      </SectionContent>
    </Section>
  )
}

export default LoginDesktop
