import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { selectHeader } from '../../redux/slices/uiSlice'
import { handleBackButton } from '../../utils/generic'

import { Screen, Section, SectionContent } from '../components/generic/View'
import IllustrationBlock from '../components/IllustrationBlock'
import inviteMember from '../../assets/illustrations/invite_a_member.svg'
import ContactUsForm from '../components/forms/ContactUsForm'

const ContactUs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () => navigate('/profile'))
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('contact_us.title'),
    multiColor: true,
    backCustomActionTriggered: false
  }

  return (
    <Screen header={header}>
      <IllustrationBlock
        background
        paragraph={t('contact_us.text')}
        paragraphBlackOnMobile={true}
        img={inviteMember}
        textlast="true"
      />
      <Section>
        <SectionContent>
          <ContactUsForm />
        </SectionContent>
      </Section>
    </Screen>
  )
}

export default ContactUs
