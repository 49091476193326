import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modal: {
    active: false,
    type: undefined,
    props: {},
    fullWidth: false,
    withoutCloseButton: false
  },
  header: {
    id: undefined,
    type: undefined,
    title: undefined,
    multiColor: false,
    withBackground: false,
    withoutMenu: false,
    logoLinkLeadsToIndex: false
  },
  inspireMe: {
    questionId: 0 // tells inspire me form which question to ask next
  },
  temp: undefined // can be used to store temporary data that can't be passed as prop
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closeModal(state) {
      state.modal.active = false
      state.modal.type = undefined
      state.modal.props = {}
      state.modal.fullWidth = false
      state.modal.withoutCloseButton = false
    },
    openModal(state, action) {
      state.modal.active = true
      state.modal.type = action.payload?.type
      state.modal.props = action.payload?.props || {}
      state.modal.fullWidth = action.payload?.fullWidth || false
      state.modal.withoutCloseButton =
        action.payload?.withoutCloseButton || false
    },
    setHeader(state, action) {
      state.header = action.payload
    },
    setQuestionId(state, action) {
      state.inspireMe.questionId = action.payload
    },
    setTemporaryData(state, action) {
      state.temp = action.payload
    }
  }
})

// actions
export const {
  closeModal,
  openModal,
  setHeader,
  setQuestionId,
  setTemporaryData
} = uiSlice.actions

// selectors
export const selectModal = state => state.ui.modal
export const selectHeader = state => state.ui.header
export const selectQuestionId = state => state.ui.inspireMe.questionId
export const selectTemporaryData = state => state.ui.temp

export default uiSlice.reducer
