import { Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { closeModal } from '../../../../redux/slices/uiSlice'
import {
  selectUser,
  sendMessageToAdmin
} from '../../../../redux/slices/userSlice'
import { setToast } from '../../../../utils/status'
import { Paragraph } from '../../generic/Text'
import { Col } from '../../generic/View'
import EmojiModal from './EmojiModal'
import TextModal from './TextModal'
import FileModal from './FileModal'
import { FEEDBACK_TYPES } from '../../../../constants/feedbackType'

const Feedback = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectUser)

  const location = useLocation()

  const PAGE_STEPS = {
    FEELING: 'FEELING',
    TEXT: 'TEXT',
    FILE_UPLOAD: 'FILE_UPLOAD'
  }
  const [currentStep, setCurrentStep] = useState(PAGE_STEPS.FEELING)

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async fd => {
    setSubmitting(true)

    const formData = new FormData()
    formData.append('type', FEEDBACK_TYPES.FEEDBACK_FEATURE)
    formData.append('message', fd.message)
    formData.append('emotion', fd.feeling)
    formData.append('path', location.pathname)

    if (fd.file && fd.file.length > 0) {
      formData.append('file', fd.file[0])
    }

    const result = await dispatch(
      sendMessageToAdmin({
        userId: user?.id || '',
        feedbackFeature: true,
        formData
      })
    )

    if (result.meta?.requestStatus === 'rejected') {
      if (result.payload !== null) {
        setToast(result.payload.title, 'error')
        setSubmitting(false)
      }
    }
    if (result.meta?.requestStatus === 'fulfilled') {
      setToast(t('contact_us.successfully_sent'), 'success')
      dispatch(closeModal())
    }
  }

  return (
    <Col style={{ ...props.style }}>
      <Formik
        initialValues={{ feeling: '', message: '', file: '' }}
        validate={values => {
          const errors = {},
            messageLimit = 60_000
          if (!values.message.trim()) {
            errors.message = t('general.required_field')
          } else if (values.message.trim().length > messageLimit) {
            errors.message = t('general.errors.text_too_long', {
              limit: messageLimit,
              length: values.message.trim().length
            })
          }

          return errors
        }}
        onSubmit={handleSubmit}>
        {form => (
          <Paragraph textAlign="center" darkBlueGrey>
            {currentStep === PAGE_STEPS.FEELING && (
              <EmojiModal
                setCurrentStep={setCurrentStep}
                pageSteps={PAGE_STEPS}
                form={form}
              />
            )}
            {currentStep === PAGE_STEPS.TEXT && (
              <TextModal
                form={form}
                setCurrentStep={setCurrentStep}
                pageSteps={PAGE_STEPS}
              />
            )}
            {currentStep === PAGE_STEPS.FILE_UPLOAD && (
              <FileModal
                submitting={submitting}
                form={form}
                setCurrentStep={setCurrentStep}
                pageSteps={PAGE_STEPS}
              />
            )}
          </Paragraph>
        )}
      </Formik>
    </Col>
  )
}

export default Feedback
