import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AddContentPage from '../components/memory/add-content/AddContentPage'

import { setToast } from '../../utils/status'

import { createContent, selectMemoryById } from '../../redux/slices/memorySlice'
import { selectMemoryRoomById } from '../../redux/slices/memoryRoomSlice'
import { selectUser } from '../../redux/slices/userSlice'
import { setTemporaryData } from '../../redux/slices/uiSlice'

import {
  CONTENT_TYPES_VALUES_MEDIA,
  FORM_INIT_CONTENT_TYPE
} from '../../constants/contentTypes'

function ContentNew() {
  let { roomId, memoryId } = useParams()
  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )
  if (!memoryRoom) {
    return <Navigate to="/home" replace />
  }

  memoryId = parseInt(memoryId, 10)
  const memory = useSelector(state => selectMemoryById(state, memoryId))

  const roomLink = `/rooms/${memoryRoomId}`
  if (!memory) {
    return <Navigate to={roomLink} replace />
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const user = useSelector(selectUser)

  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )

  const [submitting, setSubmitting] = useState(false)
  const [contentArray, setContentArray] = useState([])
  const [resetContentCount, setResetContentCount] = useState(1)
  const [contentCount, setContentCount] = useState(FORM_INIT_CONTENT_TYPE)

  useEffect(() => {
    setContentCount(FORM_INIT_CONTENT_TYPE)
  }, [resetContentCount])

  const addMemoryContent = async () => {
    setSubmitting(true)
    const formData = new FormData()
    const copiedContentArray = contentArray.slice()
    let filesSize = 0

    // Preparing assets and adding to formData
    if (copiedContentArray.length > 0) {
      for (const content of copiedContentArray) {
        if (CONTENT_TYPES_VALUES_MEDIA.includes(content.type)) {
          if (content.assets && content.assets.length > 0) {
            const assetsProcessed = []
            for (const asset of content.assets) {
              formData.append('files', asset)
              assetsProcessed.push({
                fileName: asset.name,
                fileSize: asset.size,
                mimetype: asset.type
              })
              filesSize += asset.size
            }
            content.assets = assetsProcessed
          }
        }
      }

      // Adding content to formData
      formData.append('content', JSON.stringify(copiedContentArray))

      // if fileSize bigger than 20mb show toast
      if (filesSize > 20971520) {
        setToast(t('media.upload_takes_time'), 'info')
      }

      const result = await dispatch(
        createContent({
          memoryRoomUserId: memoryRoomUser.id,
          memoryId: memoryId,
          formData
        })
      )

      // Display error if rejected
      if (result.meta?.requestStatus === 'rejected') {
        if (result.payload !== null) {
          const errorMsg =
            result.payload.title ||
            result.payload.message ||
            result.payload?.errors?.general ||
            t('general.something_wrong')
          setToast(errorMsg, 'error')
          setSubmitting(false)
          // if server couldn't proccess files. we need to force user to upload them again.
          setContentArray([])
          setResetContentCount(Date.now())
        }
      }

      // Clear form on success and display success message
      if (result.meta?.requestStatus === 'fulfilled') {
        navigate(`/rooms/${memoryRoomId}/memory/${memory.id}`)
        dispatch(setTemporaryData(undefined))
        setToast(t('memory.new_content_added'), 'success')
      }
    } else {
      navigate(`/rooms/${memoryRoomId}/memory/${memory.id}`)
    }
  }

  return (
    <AddContentPage
      buttonLabelKey="general.add"
      buttonAction={() => addMemoryContent()}
      backButtonAction={() =>
        navigate(`/rooms/${memoryRoomId}/memory/${memory.id}`)
      }
      setArrayFunc={setContentArray}
      submitting={submitting}
      memoryRoom={memoryRoom}
      contentCount={contentCount}
      setContentCount={setContentCount}
    />
  )
}

export default ContentNew
