import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ResponsiveContext } from 'grommet'
import { STATUS, LIFECYCLE } from 'react-joyride'

import {
  selectMemoryById,
  fetchMemoryForMemoryRoomUser
} from '../../redux/slices/memorySlice'
import { selectMemoryRoomById } from '../../redux/slices/memoryRoomSlice'
import { selectUser, update as updateUser } from '../../redux/slices/userSlice'
import {
  openModal,
  closeModal,
  selectTemporaryData,
  setTemporaryData,
  selectHeader
} from '../../redux/slices/uiSlice'

import { prepareDate, handleBackButton } from '../../utils/generic'
const memoryRoomUserConstants = require('../../constants/memoryRoomUserRoles')
const { OWNER } = memoryRoomUserConstants.ROLES
const { PRIVACY_SETTINGS } = require('../../constants/privacySettings')
import { MEMORY_ONBOARDING_MODES } from '../components/memory/enum/MemoryOnboardingModes'
import { onboardingSteps } from '../components/memory/onboardingSteps'

import viaTheme from '../../styles/via-theme.json'

import { Screen, Section, Col, Row } from '../components/generic/View'
import { ShowMore } from '../components/generic/ShowMore'
import { Tooltip } from '../components/generic/Tooltip'
import OnboardingTour from '../components/OnboardingTour'
import {
  SectionContentMemory,
  ThematicBreak
} from '../components/generic/Elements'
import { Paragraph, Heading2 } from '../components/generic/Text'
import * as Button from '../components/generic/Button'
import MemoryContentItem from '../components/memory/MemoryContentItem'
import MemoryLightBox from '../components/memory/MemoryLightBox'
import * as Icon from '../components/generic/Icon'
import SimilarMemories from '../components/memory/SimilarMemoriesRow'

import { ReactComponent as IconInfo } from '../../assets/icons/icon_info.svg'

const isAppSunsettingMode = process.env.REACT_APP_IS_SUNSETTING_MODE === '1'

function Memory() {
  let { roomId, memoryId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const prevMemoryId = location.state?.prevMemoryId
  const pathname = location.pathname
  const memoryRoomId = parseInt(roomId, 10)
  const memoryRoom = useSelector(state =>
    selectMemoryRoomById(state, memoryRoomId)
  )

  if (!memoryRoom) {
    return <Navigate to="/home" replace />
  }

  memoryId = parseInt(memoryId, 10)
  const memory = useSelector(state => selectMemoryById(state, memoryId))

  const roomLink = `/rooms/${memoryRoomId}`
  if (!memory) {
    return <Navigate to={roomLink} replace />
  }

  const user = useSelector(selectUser)
  const memoryRoomUser = memoryRoom.memoryRoomUsers.find(
    memoryRoomUser => memoryRoomUser.userId === user.id
  )
  const isMemoryRoomOwner = memoryRoomUser.role === OWNER
  const isMemoryOwner = memory.memoryRoomUserId === memoryRoomUser.id

  const tempData = useSelector(selectTemporaryData)

  useEffect(() => {
    const result = dispatch(
      fetchMemoryForMemoryRoomUser({
        memoryRoomUserId: memoryRoomUser.id,
        memoryId,
        similarMemoriesSize:
          process.env.REACT_APP_MAX_SIZE_SIMILAR_MEMORIES_TO_DISPLAY || 5,
        similarMemoryToSkip: prevMemoryId
      })
    )
    if (result.meta?.requestStatus === 'rejected') {
      navigate(`/home`)
    }
  }, [memoryId, memoryRoomUser.id])

  // LightBox state
  const [currentImageUrl, setCurrentImageUrl] = useState()
  const [showLightBox, setShowLightBox] = useState()

  // handle action on back button
  const headerRedux = useSelector(selectHeader)
  useEffect(() => {
    handleBackButton(headerRedux, pathname, () =>
      navigate(`/rooms/${memoryRoomId}`)
    )
  }, [headerRedux.backCustomActionTriggered])

  const header = {
    type: 'actionBar',
    title: t('memory.heading'),
    withBackground: true,
    backCustomActionTriggered: false
  }

  const memoryDate = prepareDate(
    memory.memoryDate,
    !!memory.memoryMonth,
    !!memory.memoryDay
  )

  const onOpenMemorySettings = () => {
    dispatch(
      openModal({
        type: 'memorySettings',
        props: {
          memoryRoomId,
          memoryId,
          user,
          isMemoryRoomOwner,
          isMemoryOwner,
          memoryRoomUserId: memoryRoomUser.id,
          memory,
          onboardingAction: () => {
            dispatch(closeModal())
            setShowFullTour(true)
            setTourRun(true)
          }
        }
      })
    )
  }

  const onOpenLightBox = currentImageUrl => {
    setCurrentImageUrl(currentImageUrl)
    setShowLightBox(true)
  }

  const onClickAddContent = () => {
    if (memory.private) {
      dispatch(
        setTemporaryData({ ...tempData, privacy: PRIVACY_SETTINGS.PRIVATE })
      )
      navigate(`/rooms/${memoryRoomId}/memory/${memoryId}/new-content`)
    } else {
      dispatch(
        openModal({
          type: 'memoryPrivacySetting',
          props: { memoryRoomId, memoryId }
        })
      )
    }
  }

  const size = useContext(ResponsiveContext)
  const isMobile = size !== 'large'

  let sortedContent = []
  if (memory.memoryContent && memory.memoryContent.length > 0) {
    const content = [...memory.memoryContent]
    sortedContent = content.sort((a, b) => {
      return a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0
    })
  }

  const sectionPadding = {
    horizontal: `${size !== 'large' ? 'medium' : '0'}`,
    vertical: 'small'
  }

  const privacy = {
    text: memory.private
      ? t('memory.privacy.memory_is_private')
      : t('memory.privacy.memory_is_public'),
    icon: memory.private ? (
      <Icon.Lock style={{ color: '#02CBC5' }} />
    ) : (
      <Icon.Group style={{ color: '#475662' }} />
    )
  }

  const contentExist = sortedContent?.length > 0

  // ONBOARDING TOUR
  const [tourRun, setTourRun] = useState(true)
  const [showFullTour, setShowFullTour] = useState(false)

  let tourMode,
    tourSteps,
    allowCloseTour = false
  // If user was not onboarded at all and there is NO content, show memory onboarding
  if (
    !user.onboardedToMemory &&
    !user.onboardedToMemoryWithContent &&
    !contentExist
  ) {
    tourMode = MEMORY_ONBOARDING_MODES.MEMORY
  }
  // If user was not onboarded at all and there is content, show full onboarding
  else if (
    !user.onboardedToMemory &&
    !user.onboardedToMemoryWithContent &&
    contentExist
  ) {
    tourMode = MEMORY_ONBOARDING_MODES.FULL
  }
  // If user was onboarded only to memory and there is content, show content onboarding
  else if (
    user.onboardedToMemory &&
    !user.onboardedToMemoryWithContent &&
    contentExist
  ) {
    tourMode = MEMORY_ONBOARDING_MODES.CONTENT
  }
  // If user was onboarded, but wants to watch onboarding again
  else if (showFullTour) {
    tourMode = MEMORY_ONBOARDING_MODES.FULL
    allowCloseTour = true
  }

  if (tourMode) {
    // If user requested full tour, but there are no memories,
    // we still should show tour for room only
    const curMode =
      tourMode === MEMORY_ONBOARDING_MODES.FULL && !contentExist
        ? MEMORY_ONBOARDING_MODES.MEMORY
        : tourMode
    tourSteps = onboardingSteps(curMode, memory)
  }

  const handleOnboardingCallback = data => {
    const { status, lifecycle } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      // Update user onboarding status when onboarding was watched
      if (lifecycle === LIFECYCLE.COMPLETE && !showFullTour) {
        let updateProp = {}
        if (tourMode === MEMORY_ONBOARDING_MODES.FULL) {
          updateProp[MEMORY_ONBOARDING_MODES.MEMORY] = true
          updateProp[MEMORY_ONBOARDING_MODES.CONTENT] = true
        } else {
          updateProp[tourMode] = true
        }

        dispatch(
          updateUser({
            userId: user.id,
            formData: updateProp
          })
        )
      }
      setTourRun(false)
      setShowFullTour(false)
    }
  }

  let memoryName, memoryDescription
  if (memory.creatorIsDeactivated) {
    memoryName = t('memory.deleted_memory_name_placeholder')
    memoryDescription = t('memory.deleted_memory_description_placeholder')
  } else {
    memoryName = memory.name
    memoryDescription = memory.description
  }

  return (
    <Screen column header={header}>
      <Section columnItem>
        {tourMode && (
          <OnboardingTour
            callback={handleOnboardingCallback}
            run={tourRun}
            steps={tourSteps}
            allowClose={allowCloseTour}
          />
        )}
        <SectionContentMemory
          padding={sectionPadding}
          className="onboarding__memory-info">
          <Col width="100%" pad={0}>
            <Row align="start" margin={{ bottom: '1.25rem' }}>
              <Col>
                <Heading2
                  margin="0"
                  data-testid="memory-name-heading"
                  color={viaTheme.global.colors.blackFont}>
                  {memoryName}
                </Heading2>
                <Paragraph margin="0" fontWeight="400">
                  {memoryDate}
                </Paragraph>
              </Col>

              <Icon.ThreeDotsInCircle
                className="onboarding__memory-menu"
                onClick={() => onOpenMemorySettings()}
                style={{
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  height: '32px',
                  width: '32px'
                }}
              />
            </Row>

            {memoryDescription && (
              <Paragraph
                margin={isMobile ? '0 0 1rem 0' : '0 0 .25rem 0'}
                whiteSpace="pre-line">
                <ShowMore str={memoryDescription} />
              </Paragraph>
            )}
          </Col>
          <Row justify="end" width="100%">
            <Tooltip id={`memory-privacy-${memory.id}`} text={privacy.text}>
              {privacy.icon}
            </Tooltip>
          </Row>
          {!isMobile && <ThematicBreak />}
        </SectionContentMemory>
        <SectionContentMemory
          padding={
            isMobile
              ? '0'
              : {
                  horizontal: `${size !== 'large' ? 'medium' : '0'}`,
                  top: '0',
                  bottom: 'small'
                }
          }>
          <Col width="100%">
            {sortedContent.map((content, ind) => {
              const displayContentMenu =
                isMemoryRoomOwner ||
                isMemoryOwner ||
                content.memoryRoomUserId === memoryRoomUser.id

              return (
                <MemoryContentItem
                  key={content.id}
                  commentsIsLink={true}
                  content={content}
                  displayMenu={displayContentMenu}
                  isFirstItem={ind === 0}
                  memoryRoom={memoryRoom}
                  memoryRoomUserId={memoryRoomUser.id}
                  onOpenLightBox={currentImageUrl =>
                    onOpenLightBox(currentImageUrl)
                  }
                />
              )
            })}
          </Col>
        </SectionContentMemory>
        <SectionContentMemory padding={sectionPadding}>
          {!isAppSunsettingMode && (
            <Button.Primary
              onClick={() => onClickAddContent()}
              className="onboarding__add-content"
              style={{ textAlign: 'center' }}>
              {t('memory.add_content')}
            </Button.Primary>
          )}

          {isAppSunsettingMode && (
            <Tooltip
              style={{ textAlign: 'center', display: 'block', width: '100%' }}
              id={`content-tooltip`}
              text={t('memory_room.new_content_disabled')}>
              <Button.Primary
                disabled
                type="button"
                className="onboarding__add-content"
                style={{
                  background: '#C5CFD6',
                  opacity: '1',
                  color: '#7C92A2'
                }}
                label={
                  <span
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px'
                    }}>
                    {t('memory.add_content')}
                    <IconInfo />
                  </span>
                }
              />
            </Tooltip>
          )}

          <SimilarMemories
            memoryId={memoryId}
            similarMemories={memory.similarMemories}
            memoryRoomId={memoryRoomId}
          />
        </SectionContentMemory>
      </Section>
      {showLightBox && (
        <MemoryLightBox
          onClose={() => setShowLightBox(false)}
          content={memory.memoryContent}
          currentImageUrl={currentImageUrl}
        />
      )}
    </Screen>
  )
}

export default Memory
