import i18n from '../../../i18n'

import { MEMORY_ONBOARDING_MODES } from './enum/MemoryOnboardingModes'

export const onboardingSteps = (mode, memory) => {
  let steps = []

  // Memory related steps
  if (
    mode === MEMORY_ONBOARDING_MODES.MEMORY ||
    mode === MEMORY_ONBOARDING_MODES.FULL
  ) {
    steps.push(
      {
        target: '.onboarding__memory-info',
        content: i18n.t('memory.onboarding.memory_info'),
        disableBeacon: true
      },
      {
        target: '.onboarding__memory-menu',
        content: i18n.t('memory.onboarding.memory_menu'),
        disableBeacon: true
      },
      {
        target: '.onboarding__add-content',
        content: i18n.t('memory.onboarding.add_content'),
        disableBeacon: true
      }
    )

    if (memory.similarMemories?.length > 0) {
      steps.push({
        target: '.onboarding__more-like-this',
        content: i18n.t('memory.onboarding.more_like_this'),
        disableBeacon: true
      })
    }
  }

  // Content related steps
  if (
    mode === MEMORY_ONBOARDING_MODES.CONTENT ||
    mode === MEMORY_ONBOARDING_MODES.FULL
  ) {
    steps.push(
      {
        target: '.onboarding__first-content-menu',
        content: i18n.t('memory.onboarding.first_content_menu'),
        disableBeacon: true
      },
      {
        target: '.onboarding__first-content-privacy',
        content: i18n.t('memory.onboarding.first_content_privacy'),
        disableBeacon: true
      },
      {
        target: '.onboarding__first-content-comments',
        content: i18n.t('memory.onboarding.first_content_comments'),
        disableBeacon: true
      }
    )
  }

  return steps
}
